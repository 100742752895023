/*********************
FOOTER STYLES
*********************/
.footer {
    // background-color: $navy;
    position: relative;
    padding-top: 120px;

    .curved-lid {
        z-index: -1;
    }

    .behind-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--colour-white);
        z-index: -2;
    }

    .footer--top {
        position: relative;

        .uk-grid {
            position: relative;
        }

        .foot-flex {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @media screen and (max-width: $breakpoint-medium) {
                align-items: center;
            }
        }

        .footer-logo {
            height: 200px;
            img {
                width: 190px;
            }
            @media screen and (max-width: $breakpoint-medium) {
                height: 130px;
            }
        }

        a,
        li,
        footer.uk-light h5,
        a.uk-icon {
            color: var(--colour-black);
            font-family: var(--body-font-family);
            font-size: 14px;
        }
        .uk-h5 {
            font-family: var(--header-font-family);
            color: var(--colour-black);
            font-weight: 500;
        }

        .menu-item {
            //font-size: 13px;
            margin-top: 4px;
        }

        .socials {
            padding-left: 30px;
            @media screen and (max-width: $breakpoint-medium) {
                padding: 0;
            }

            a.uk-icon {
                position: relative;
                z-index: 2;
                color: var(--colour-white);
                background-color: var(--colour-blue);
                border-radius: 25px;
                padding: 12px;
                &:hover {
                    background-color: var(--colour-blue-light);
                }
            }

            &.mobile {
                display: none;
            }

            li {
                display: inline-block;
                padding: 0 10px;
            }

            @media screen and (max-width: var(--mobile)) {
                &.desktop {
                    display: none;
                }

                &.mobile {
                    display: inline-block;
                    position: relative;
                }
            }
        }

        .footer-menus {
            @media screen and (max-width: $breakpoint-small-max) {
                text-align: center;
            }
        }
    }

    .footer--bottom {
        padding: 50px 0 1rem 0;
        // font-size: rem-calc(14px);
        // opacity: 0.5;
        font-family: var(--body-font-family);

        &::before {
            content: "";
            width: 100%;
            background-color: currentColor;
            height: 0.5px;
            display: block;
            margin-bottom: 15px;
        }

        .footer--acknowledgement {
            margin: 2rem 0;
        }

        .copyright {
            padding: 1rem 0;

            @media screen and (max-width: $breakpoint-small-max) {
                text-align: center;
                padding: 0;
            }
        }

        .white-papers {
            @media screen and (max-width: $breakpoint-small-max) {
                margin-top: 5px;
            }
        }

        .copy-links {
            display: inline-block;
            a {
                color: var(--colour-black);
            }
        }

        .uk-list {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 0;

            @media screen and (max-width: $breakpoint-small-max) {
                justify-content: center;
                margin-bottom: 1rem;
            }

            li {
                margin: 0;

                &::after {
                    content: "|";
                    margin: 0 4px;
                }
            }

            :last-child::after {
                content: unset;
            }
        }

        .uk-list.uk-list-horizontal li {
            margin-top: 0;
        }
    }
}
