@charset "UTF-8";
@import url("https://p.typekit.net/p.css?s=1&k=cph4bqn&ht=tk&f=10294.10302.43311&a=84061982&app=typekit&e=css");
.image-replacement {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

.amp {
  font-family: Baskerville, "Goudy Old Style", Palatino, "Book Antiqua", serif;
  font-style: italic;
}

.image-replacement {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

.amp {
  font-family: Baskerville, "Goudy Old Style", Palatino, "Book Antiqua", serif;
  font-style: italic;
}

/* ========================================================================
   Component: Base
 ========================================================================== */
/*
 * 1. Set `font-size` to support `rem` units
 *    Not using `font` property because a leading hyphen (e.g. -apple-system) causes the font to break in IE11 and Edge
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 * 3. Style
 */
html {
  /* 1 */
  font-family: "canada-type-gibson", serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
  background: #fff;
  color: #333333;
}

/*
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}

/* Links
 ========================================================================== */
/*
 * Style
 */
a,
.uk-link {
  color: #325685;
  text-decoration: none;
  cursor: pointer;
}

a:hover,
.uk-link:hover,
.uk-link-toggle:hover .uk-link {
  color: #243e60;
  text-decoration: underline;
}

/* Text-level semantics
 ========================================================================== */
/*
 * 1. Add the correct text decoration in Edge.
 * 2. The shorthand declaration `underline dotted` is not supported in Safari.
 */
abbr[title] {
  /* 1 */
  text-decoration: underline dotted;
  /* 2 */
  -webkit-text-decoration-style: dotted;
}

/*
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/*
 * 1. Consolas has a better baseline in running text compared to `Courier`
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Style
 */
:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  /* 1 */
  font-family: Consolas, monaco, monospace;
  /* 2 */
  font-size: 0.875rem;
  /* 3 */
  color: #f0506e;
  white-space: nowrap;
}

/*
 * Emphasize
 */
em {
  color: #f0506e;
}

/*
 * Insert
 */
ins {
  background: #ffd;
  color: #325685;
  text-decoration: none;
}

/*
 * Mark
 */
mark {
  background: #ffd;
  color: #325685;
}

/*
 * Quote
 */
q {
  font-style: italic;
}

/*
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/*
 * Prevents `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
 ========================================================================== */
/*
 * Remove the gap between embedded content and the bottom of their containers.
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * 1. Add responsiveness.
 * 2. Auto-scale the height. Only needed if `height` attribute is present.
 * 3. Corrects responsive `max-width` behavior if padding and border are used.
 * 4. Exclude SVGs for IE11 because they don't preserve their aspect ratio.
 */
canvas,
img,
video {
  /* 1 */
  max-width: 100%;
  /* 2 */
  height: auto;
  /* 3 */
  box-sizing: border-box;
}

/* 4 */
@supports (display: block) {
  svg {
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
  }
}
/*
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/*
 * 1. Fix lazy loading images if parent element is set to `display: inline` and has `overflow: hidden`.
 * 2. Hide `alt` text for lazy loading images.
 * Note: Selector for background while loading img[data-src*='.jpg'][src*='data:image'] { background: grey; }
 */
img:not([src]) {
  /* 1 */
  min-width: 1px;
  /* 2 */
  visibility: hidden;
}

/*
 * Iframe
 * Remove border in all browsers
 */
iframe {
  border: 0;
}

/* Block elements
 ========================================================================== */
/*
 * Margins
 */
p,
ul,
ol,
dl,
pre,
address,
fieldset,
figure {
  margin: 0 0 20px 0;
}

/* Add margin if adjacent element */
* + p,
* + ul,
* + ol,
* + dl,
* + pre,
* + address,
* + fieldset,
* + figure {
  margin-top: 20px;
}

/* Headings
 ========================================================================== */
h1, .uk-h1,
h2, .uk-h2,
h3, .uk-h3,
h4, .uk-h4,
h5, .uk-h5,
h6, .uk-h6,
.uk-heading-small,
.uk-heading-medium,
.uk-heading-large,
.uk-heading-xlarge,
.uk-heading-2xlarge {
  margin: 0 0 20px 0;
  font-family: "canada-type-gibson", serif;
  font-weight: normal;
  color: #333;
  text-transform: none;
}

/* Add margin if adjacent element */
* + h1, * + .uk-h1,
* + h2, * + .uk-h2,
* + h3, * + .uk-h3,
* + h4, * + .uk-h4,
* + h5, * + .uk-h5,
* + h6, * + .uk-h6,
* + .uk-heading-small,
* + .uk-heading-medium,
* + .uk-heading-large,
* + .uk-heading-xlarge,
* + .uk-heading-2xlarge {
  margin-top: 40px;
}

/*
 * Sizes
 */
h1, .uk-h1 {
  font-size: 2.23125rem;
  line-height: 1.2;
}

h2, .uk-h2 {
  font-size: 1.7rem;
  line-height: 1.3;
}

h3, .uk-h3 {
  font-size: 1.5rem;
  line-height: 1.4;
}

h4, .uk-h4 {
  font-size: 1.25rem;
  line-height: 1.4;
}

h5, .uk-h5 {
  font-size: 16px;
  line-height: 1.4;
}

h6, .uk-h6 {
  font-size: 0.875rem;
  line-height: 1.4;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  h1, .uk-h1 {
    font-size: 2.625rem;
  }

  h2, .uk-h2 {
    font-size: 2rem;
  }
}
/* Lists
 ========================================================================== */
ul,
ol {
  padding-left: 30px;
}

/*
 * Reset margin for nested lists
 */
ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
  margin: 0;
}

/* Description lists
 ========================================================================== */
dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

/* Horizontal rules
 ========================================================================== */
/*
 * 1. Show the overflow in Chrome, Edge and IE.
 * 2. Add the correct text-align in Edge and IE.
 * 3. Style
 */
hr, .uk-hr {
  /* 1 */
  overflow: visible;
  /* 2 */
  text-align: inherit;
  /* 3 */
  margin: 0 0 20px 0;
  border: 0;
  border-top: 1px solid #e5e5e5;
}

/* Add margin if adjacent element */
* + hr,
* + .uk-hr {
  margin-top: 20px;
}

/* Address
 ========================================================================== */
address {
  font-style: normal;
}

/* Blockquotes
 ========================================================================== */
blockquote {
  margin: 0 0 20px 0;
  font-size: 1.25rem;
  line-height: 1.5;
  font-style: italic;
}

/* Add margin if adjacent element */
* + blockquote {
  margin-top: 20px;
}

/*
 * Content
 */
blockquote p:last-of-type {
  margin-bottom: 0;
}

blockquote footer {
  margin-top: 10px;
  font-size: 0.875rem;
  line-height: 1.5;
}

/* Preformatted text
 ========================================================================== */
/*
 * 1. Contain overflow in all browsers.
 */
pre {
  font: 0.875rem / 1.5 Consolas, monaco, monospace;
  color: #325685;
  -moz-tab-size: 4;
  tab-size: 4;
  /* 1 */
  overflow: auto;
}

pre code {
  font-family: Consolas, monaco, monospace;
}

/* Focus
 ========================================================================== */
/*
 * Safari doesn't support `:focus-visible` yet. It also doesn't set focus if clicking a button or anchor.
 * 1. Fallback: Remove `:focus` for a negative tabindexes.
 * 2. Fallback: Set `:focus` style but not for negative tabindexes.
 * 3. Fallback: Remove `:focus` style for browsers which support `:focus-visible`.
 * 4. Set `:focus-visible` style for browsers which support it.
 */
/* 1 */
:focus {
  outline: none;
}

/* 2 */
:focus:not([tabindex^="-"]) {
  outline: 2px dotted #333;
  outline-offset: 1px;
}

/* 3 */
:focus:not(:focus-visible) {
  outline: none;
}

/* 4 */
:focus-visible {
  outline: 2px dotted #333;
}

/* Selection pseudo-element
 ========================================================================== */
::selection {
  background: #325685;
  color: #ffffff;
  text-shadow: none;
}

/* HTML5 elements
 ========================================================================== */
/*
 * 1. Add the correct display in Edge, IE 10+, and Firefox.
 * 2. Add the correct display in IE.
 */
details,
main {
  /* 2 */
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Pass media breakpoints to JS
 ========================================================================== */
/*
 * Breakpoints
 */
.uk-breakpoint-s::before {
  content: "640px";
}

.uk-breakpoint-m::before {
  content: "960px";
}

.uk-breakpoint-l::before {
  content: "1200px";
}

.uk-breakpoint-xl::before {
  content: "1600px";
}

:root {
  --uk-breakpoint-s: 640px;
  --uk-breakpoint-m: 960px;
  --uk-breakpoint-l: 1200px;
  --uk-breakpoint-xl: 1600px;
}

/* ========================================================================
   Component: Link
 ========================================================================== */
/* Muted
 ========================================================================== */
a.uk-link-muted,
.uk-link-muted a,
.uk-link-toggle .uk-link-muted {
  color: #999;
}

a.uk-link-muted:hover,
.uk-link-muted a:hover,
.uk-link-toggle:hover .uk-link-muted {
  color: #325685;
}

/* Text
 ========================================================================== */
a.uk-link-text,
.uk-link-text a,
.uk-link-toggle .uk-link-text {
  color: inherit;
}

a.uk-link-text:hover,
.uk-link-text a:hover,
.uk-link-toggle:hover .uk-link-text {
  color: #999;
}

/* Heading
 ========================================================================== */
a.uk-link-heading,
.uk-link-heading a,
.uk-link-toggle .uk-link-heading {
  color: inherit;
}

a.uk-link-heading:hover,
.uk-link-heading a:hover,
.uk-link-toggle:hover .uk-link-heading {
  color: #325685;
  text-decoration: none;
}

/* Reset
 ========================================================================== */
/*
 * `!important` needed to override inverse component
 */
a.uk-link-reset,
.uk-link-reset a {
  color: inherit !important;
  text-decoration: none !important;
}

/* Toggle
 ========================================================================== */
.uk-link-toggle {
  color: inherit !important;
  text-decoration: none !important;
}

/* ========================================================================
   Component: Heading
 ========================================================================== */
.uk-heading-small {
  font-size: 2.6rem;
  line-height: 1.2;
}

.uk-heading-medium {
  font-size: 2.8875rem;
  line-height: 1.1;
}

.uk-heading-large {
  font-size: 3.4rem;
  line-height: 1.1;
}

.uk-heading-xlarge {
  font-size: 4rem;
  line-height: 1;
}

.uk-heading-2xlarge {
  font-size: 6rem;
  line-height: 1;
}

/* Tablet Landscape and bigger */
@media (min-width: 960px) {
  .uk-heading-small {
    font-size: 3.25rem;
  }

  .uk-heading-medium {
    font-size: 3.5rem;
  }

  .uk-heading-large {
    font-size: 4rem;
  }

  .uk-heading-xlarge {
    font-size: 6rem;
  }

  .uk-heading-2xlarge {
    font-size: 8rem;
  }
}
/* Laptop and bigger */
@media (min-width: 1200px) {
  .uk-heading-medium {
    font-size: 4rem;
  }

  .uk-heading-large {
    font-size: 6rem;
  }

  .uk-heading-xlarge {
    font-size: 8rem;
  }

  .uk-heading-2xlarge {
    font-size: 11rem;
  }
}
/* Primary
   Deprecated: Use `uk-heading-medium` instead
 ========================================================================== */
/* Tablet landscape and bigger */
/* Desktop and bigger */
/* Hero
   Deprecated: Use `uk-heading-xlarge` instead
 ========================================================================== */
/* Tablet landscape and bigger */
/* Desktop and bigger */
/* Divider
 ========================================================================== */
.uk-heading-divider {
  padding-bottom: calc(5px + 0.1em);
  border-bottom: calc(0.2px + 0.05em) solid #e5e5e5;
}

/* Bullet
 ========================================================================== */
.uk-heading-bullet {
  position: relative;
}

/*
 * 1. Using `inline-block` to make it work with text alignment
 * 2. Center vertically
 * 3. Style
 */
.uk-heading-bullet::before {
  content: "";
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  top: calc(-0.1 * 1em);
  vertical-align: middle;
  /* 3 */
  height: calc(4px + 0.7em);
  margin-right: calc(5px + 0.2em);
  border-left: calc(5px + 0.1em) solid #e5e5e5;
}

/* Line
 ========================================================================== */
/*
 * Clip the child element
 */
.uk-heading-line {
  overflow: hidden;
}

/*
 * Extra markup is needed to make it work with text align
 */
.uk-heading-line > * {
  display: inline-block;
  position: relative;
}

/*
 * 1. Center vertically
 * 2. Make the element as large as possible. It's clipped by the container.
 * 3. Style
 */
.uk-heading-line > ::before,
.uk-heading-line > ::after {
  content: "";
  /* 1 */
  position: absolute;
  top: calc(50% - (calc(0.2px + 0.05em) / 2));
  /* 2 */
  width: 2000px;
  /* 3 */
  border-bottom: calc(0.2px + 0.05em) solid #e5e5e5;
}

.uk-heading-line > ::before {
  right: 100%;
  margin-right: calc(5px + 0.3em);
}

.uk-heading-line > ::after {
  left: 100%;
  margin-left: calc(5px + 0.3em);
}

/* ========================================================================
   Component: Divider
 ========================================================================== */
/*
 * 1. Reset default `hr`
 * 2. Set margin if a `div` is used for semantical reason
 */
[class*=uk-divider] {
  /* 1 */
  border: none;
  /* 2 */
  margin-bottom: 20px;
}

/* Add margin if adjacent element */
* + [class*=uk-divider] {
  margin-top: 20px;
}

/* Icon
 ========================================================================== */
.uk-divider-icon {
  position: relative;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22%23e5e5e5%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.uk-divider-icon::before,
.uk-divider-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  max-width: calc(50% - (50px / 2));
  border-bottom: 1px solid #e5e5e5;
}

.uk-divider-icon::before {
  right: calc(50% + (50px / 2));
  width: 100%;
}

.uk-divider-icon::after {
  left: calc(50% + (50px / 2));
  width: 100%;
}

/* Small
 ========================================================================== */
/*
 * 1. Fix height because of `inline-block`
 * 2. Using ::after and inline-block to make `text-align` work
 */
/* 1 */
.uk-divider-small {
  line-height: 0;
}

/* 2 */
.uk-divider-small::after {
  content: "";
  display: inline-block;
  width: 100px;
  max-width: 100%;
  border-top: 1px solid #e5e5e5;
  vertical-align: top;
}

/* Vertical
 ========================================================================== */
.uk-divider-vertical {
  width: max-content;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid #e5e5e5;
}

/* ========================================================================
   Component: List
 ========================================================================== */
.uk-list {
  padding: 0;
  list-style: none;
}

/*
 * Remove margin from the last-child
 */
.uk-list > * > :last-child {
  margin-bottom: 0;
}

/*
 * Style
 */
.uk-list > :nth-child(n+2),
.uk-list > * > ul {
  margin-top: 10px;
}

/* Marker modifiers
 * Moving `::marker` inside `::before` to style it differently
 * To style the `::marker` is currently only supported in Firefox and Safari
 ========================================================================== */
.uk-list-disc > *,
.uk-list-circle > *,
.uk-list-square > *,
.uk-list-decimal > *,
.uk-list-hyphen > * {
  padding-left: 30px;
}

/*
 * Type modifiers
 */
.uk-list-decimal {
  counter-reset: decimal;
}

.uk-list-decimal > * {
  counter-increment: decimal;
}

[class*=uk-list] > ::before {
  content: "";
  position: relative;
  left: -30px;
  width: 30px;
  height: 1.5em;
  margin-bottom: -1.5em;
  display: list-item;
  list-style-position: inside;
  text-align: right;
}

.uk-list-disc > ::before {
  list-style-type: disc;
}

.uk-list-circle > ::before {
  list-style-type: circle;
}

.uk-list-square > ::before {
  list-style-type: square;
}

.uk-list-decimal > ::before {
  content: counter(decimal, decimal) " . ";
}

.uk-list-hyphen > ::before {
  content: "–  ";
}

/*
 * Color modifiers
 */
.uk-list-muted > ::before {
  color: #999 !important;
}

.uk-list-emphasis > ::before {
  color: #333 !important;
}

.uk-list-primary > ::before {
  color: #325685 !important;
}

.uk-list-secondary > ::before {
  color: #92b3d7 !important;
}

/* Image bullet modifier
 ========================================================================== */
.uk-list-bullet > * {
  padding-left: 30px;
}

.uk-list-bullet > ::before {
  content: "";
  position: relative;
  left: -30px;
  width: 30px;
  height: 1.5em;
  margin-bottom: -1.5em;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23325685%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

/* Style modifiers
 ========================================================================== */
/*
 * Divider
 */
.uk-list-divider > :nth-child(n+2) {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #e5e5e5;
}

/*
 * Striped
 */
.uk-list-striped > * {
  padding: 10px 10px;
}

.uk-list-striped > :nth-of-type(odd) {
  background: #f8f8f8;
}

.uk-list-striped > :nth-child(n+2) {
  margin-top: 0;
}

/* Size modifier
 ========================================================================== */
.uk-list-large > :nth-child(n+2),
.uk-list-large > * > ul {
  margin-top: 20px;
}

.uk-list-collapse > :nth-child(n+2),
.uk-list-collapse > * > ul {
  margin-top: 0;
}

/*
 * Divider
 */
.uk-list-large.uk-list-divider > :nth-child(n+2) {
  margin-top: 20px;
  padding-top: 20px;
}

.uk-list-collapse.uk-list-divider > :nth-child(n+2) {
  margin-top: 0;
  padding-top: 0;
}

/*
 * Striped
 */
.uk-list-large.uk-list-striped > * {
  padding: 20px 10px;
}

.uk-list-collapse.uk-list-striped > * {
  padding-top: 0;
  padding-bottom: 0;
}

.uk-list-large.uk-list-striped > :nth-child(n+2),
.uk-list-collapse.uk-list-striped > :nth-child(n+2) {
  margin-top: 0;
}

/* ========================================================================
   Component: Description list
 ========================================================================== */
/*
 * Term
 */
.uk-description-list > dt {
  color: #333;
}

.uk-description-list > dt:nth-child(n+2) {
  margin-top: 20px;
}

/*
 * Description
 */
/* Style modifier
 ========================================================================== */
/*
 * Line
 */
.uk-description-list-divider > dt:nth-child(n+2) {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
}

/* ========================================================================
   Component: Table
 ========================================================================== */
/*
 * 1. Remove most spacing between table cells.
 * 2. Behave like a block element
 * 3. Style
 */
.uk-table {
  /* 1 */
  border-collapse: collapse;
  border-spacing: 0;
  /* 2 */
  width: 100%;
  /* 3 */
  margin-bottom: 20px;
}

/* Add margin if adjacent element */
* + .uk-table {
  margin-top: 20px;
}

/* Header cell
 ========================================================================== */
/*
 * 1. Style
 */
.uk-table th {
  padding: 16px 12px;
  text-align: left;
  vertical-align: bottom;
  /* 1 */
  font-size: 16px;
  font-weight: bold;
  color: #325685;
}

/* Cell
 ========================================================================== */
.uk-table td {
  padding: 16px 12px;
  vertical-align: top;
}

/*
 * Remove margin from the last-child
 */
.uk-table td > :last-child {
  margin-bottom: 0;
}

/* Footer
 ========================================================================== */
.uk-table tfoot {
  font-size: 0.875rem;
}

/* Caption
 ========================================================================== */
.uk-table caption {
  font-size: 0.875rem;
  text-align: left;
  color: #999;
}

/* Alignment modifier
 ========================================================================== */
.uk-table-middle,
.uk-table-middle td {
  vertical-align: middle !important;
}

/* Style modifiers
 ========================================================================== */
/*
 * Divider
 */
.uk-table-divider > tr:not(:first-child),
.uk-table-divider > :not(:first-child) > tr,
.uk-table-divider > :first-child > tr:not(:first-child) {
  border-top: 1px solid #e5e5e5;
}

/*
 * Striped
 */
.uk-table-striped > tr:nth-of-type(odd),
.uk-table-striped tbody tr:nth-of-type(odd) {
  background: #f8f8f8;
}

/*
 * Hover
 */
.uk-table-hover > tr:hover,
.uk-table-hover tbody tr:hover {
  background: #ffd;
}

/* Active state
 ========================================================================== */
.uk-table > tr.uk-active,
.uk-table tbody tr.uk-active {
  background: #ffd;
}

/* Size modifier
 ========================================================================== */
.uk-table-small th,
.uk-table-small td {
  padding: 10px 12px;
}

.uk-table-large th,
.uk-table-large td {
  padding: 22px 12px;
}

/* Justify modifier
 ========================================================================== */
.uk-table-justify th:first-child,
.uk-table-justify td:first-child {
  padding-left: 0;
}

.uk-table-justify th:last-child,
.uk-table-justify td:last-child {
  padding-right: 0;
}

/* Cell size modifier
 ========================================================================== */
.uk-table-shrink {
  width: 1px;
}

.uk-table-expand {
  min-width: 150px;
}

/* Cell link modifier
 ========================================================================== */
/*
 * Does not work with `uk-table-justify` at the moment
 */
.uk-table-link {
  padding: 0 !important;
}

.uk-table-link > a {
  display: block;
  padding: 16px 12px;
}

.uk-table-small .uk-table-link > a {
  padding: 10px 12px;
}

/* Responsive table
 ========================================================================== */
/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-table-responsive,
.uk-table-responsive tbody,
.uk-table-responsive th,
.uk-table-responsive td,
.uk-table-responsive tr {
    display: block;
  }

  .uk-table-responsive thead {
    display: none;
  }

  .uk-table-responsive th,
.uk-table-responsive td {
    width: auto !important;
    max-width: none !important;
    min-width: 0 !important;
    overflow: visible !important;
    white-space: normal !important;
  }

  .uk-table-responsive th:not(:first-child):not(.uk-table-link),
.uk-table-responsive td:not(:first-child):not(.uk-table-link),
.uk-table-responsive .uk-table-link:not(:first-child) > a {
    padding-top: 5px !important;
  }

  .uk-table-responsive th:not(:last-child):not(.uk-table-link),
.uk-table-responsive td:not(:last-child):not(.uk-table-link),
.uk-table-responsive .uk-table-link:not(:last-child) > a {
    padding-bottom: 5px !important;
  }

  .uk-table-justify.uk-table-responsive th,
.uk-table-justify.uk-table-responsive td {
    padding-left: 0;
    padding-right: 0;
  }
}
/* ========================================================================
   Component: Icon
 ========================================================================== */
/*
 * Note: 1. - 7. is required for `button` elements. Needed for Close and Form Icon component.
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Remove border-radius in Chrome.
 * 4. Address `overflow` set to `hidden` in IE.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 7. Remove default `button` padding and background color
 * 8. Style
 * 9. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 10. Let the container fit the height of the icon
 */
.uk-icon {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  border-radius: 0;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: inherit;
  /* 6 */
  text-transform: none;
  /* 7. */
  padding: 0;
  background-color: transparent;
  /* 8 */
  display: inline-block;
  /* 9 */
  fill: currentcolor;
  /* 10 */
  line-height: 0;
}

/* Required for `button`. */
button.uk-icon:not(:disabled) {
  cursor: pointer;
}

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-icon::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/*
 * Set the fill and stroke color of all SVG elements to the current text color
 */
.uk-icon:not(.uk-preserve) [fill*="#"]:not(.uk-preserve) {
  fill: currentcolor;
}

.uk-icon:not(.uk-preserve) [stroke*="#"]:not(.uk-preserve) {
  stroke: currentcolor;
}

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-icon > * {
  transform: translate(0, 0);
}

/* Image modifier
 ========================================================================== */
/*
 * Display images in icon dimensions
 */
.uk-icon-image {
  width: 20px;
  height: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
}

/* Style modifiers
 ========================================================================== */
/*
 * Link
 */
.uk-icon-link {
  color: #999;
}

.uk-icon-link:hover {
  color: #325685;
}

/* OnClick + Active */
.uk-icon-link:active,
.uk-active > .uk-icon-link {
  color: #2b4a72;
}

/*
 * Button
 * 1. Center icon vertically and horizontally
 */
.uk-icon-button {
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 500px;
  background: #f8f8f8;
  color: #999;
  vertical-align: middle;
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* Hover */
.uk-icon-button:hover {
  background-color: #ebebeb;
  color: #325685;
}

/* OnClick + Active */
.uk-icon-button:active,
.uk-active > .uk-icon-button {
  background-color: #dfdfdf;
  color: #325685;
}

/* ========================================================================
   Component: Form Range
 ========================================================================== */
/*
 * 1. Normalize and defaults
 * 2. Prevent content overflow if a fixed width is used
 * 3. Take the full width
 * 4. Remove default style
 * 5. Remove white background in Chrome
 * 6. Remove padding in IE11
 */
.uk-range {
  /* 1 */
  box-sizing: border-box;
  margin: 0;
  vertical-align: middle;
  /* 2 */
  max-width: 100%;
  /* 3 */
  width: 100%;
  /* 4 */
  -webkit-appearance: none;
  /* 5 */
  background: transparent;
  /* 6 */
  padding: 0;
}

/* Focus */
.uk-range:focus {
  outline: none;
}

.uk-range::-moz-focus-outer {
  border: none;
}

/* IE11 Reset */
.uk-range::-ms-track {
  height: 15px;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/*
 * Improves consistency of cursor style for clickable elements
 */
.uk-range:not(:disabled)::-webkit-slider-thumb {
  cursor: pointer;
}

.uk-range:not(:disabled)::-moz-range-thumb {
  cursor: pointer;
}

.uk-range:not(:disabled)::-ms-thumb {
  cursor: pointer;
}

/* Thumb
 ========================================================================== */
/*
 * 1. Reset
 * 2. Style
 */
/* Webkit */
.uk-range::-webkit-slider-thumb {
  /* 1 */
  -webkit-appearance: none;
  margin-top: -7px;
  /* 2 */
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #325685;
}

/* Firefox */
.uk-range::-moz-range-thumb {
  /* 1 */
  border: none;
  /* 2 */
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #325685;
}

/* Edge */
.uk-range::-ms-thumb {
  /* 1 */
  margin-top: 0;
}

/* IE11 */
.uk-range::-ms-thumb {
  /* 1 */
  border: none;
  /* 2 */
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #325685;
}

/* Edge + IE11 */
.uk-range::-ms-tooltip {
  display: none;
}

/* Track
 ========================================================================== */
/*
 * 1. Safari doesn't have a focus state. Using active instead.
 */
/* Webkit */
.uk-range::-webkit-slider-runnable-track {
  height: 3px;
  background: #ebebeb;
}

.uk-range:focus::-webkit-slider-runnable-track,
.uk-range:active::-webkit-slider-runnable-track {
  background: #d2d2d2;
}

/* Firefox */
.uk-range::-moz-range-track {
  height: 3px;
  background: #ebebeb;
}

.uk-range:focus::-moz-range-track {
  background: #d2d2d2;
}

/* Edge */
.uk-range::-ms-fill-lower,
.uk-range::-ms-fill-upper {
  height: 3px;
  background: #ebebeb;
}

.uk-range:focus::-ms-fill-lower,
.uk-range:focus::-ms-fill-upper {
  background: #d2d2d2;
}

/* ========================================================================
   Component: Form
 ========================================================================== */
/*
 * 1. Define consistent box sizing.
 *    Default is `content-box` with following exceptions set to `border-box`
 *    `select`, `input[type="checkbox"]` and `input[type="radio"]`
 *    `input[type="search"]` in Chrome, Safari and Opera
 *    `input[type="color"]` in Firefox
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers.
 */
.uk-input,
.uk-select,
.uk-textarea,
.uk-radio,
.uk-checkbox {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
}

/*
 * Show the overflow in Edge.
 */
.uk-input {
  overflow: visible;
}

/*
 * Remove the inheritance of text transform in Firefox.
 */
.uk-select {
  text-transform: none;
}

/*
 * 1. Change font properties to `inherit` in all browsers
 * 2. Don't inherit the `font-weight` and use `bold` instead.
 * NOTE: Both declarations don't work in Chrome, Safari and Opera.
 */
.uk-select optgroup {
  /* 1 */
  font: inherit;
  /* 2 */
  font-weight: bold;
}

/*
 * Remove the default vertical scrollbar in IE 10+.
 */
.uk-textarea {
  overflow: auto;
}

/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and Safari on OS X.
 */
.uk-input[type=search]::-webkit-search-cancel-button,
.uk-input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
.uk-input[type=number]::-webkit-inner-spin-button,
.uk-input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/*
 * Removes placeholder transparency in Firefox.
 */
.uk-input::-moz-placeholder,
.uk-textarea::-moz-placeholder {
  opacity: 1;
}

/*
 * Improves consistency of cursor style for clickable elements
 */
.uk-radio:not(:disabled),
.uk-checkbox:not(:disabled) {
  cursor: pointer;
}

/*
 * Define consistent border, margin, and padding.
 */
.uk-fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

/* Input, select and textarea
 * Allowed: `text`, `password`, `datetime`, `datetime-local`, `date`,  `month`,
            `time`, `week`, `number`, `email`, `url`, `search`, `tel`, `color`
 * Disallowed: `range`, `radio`, `checkbox`, `file`, `submit`, `reset` and `image`
 ========================================================================== */
/*
 * Remove default style in iOS.
 */
.uk-input,
.uk-textarea {
  -webkit-appearance: none;
}

/*
 * 1. Prevent content overflow if a fixed width is used
 * 2. Take the full width
 * 3. Reset default
 * 4. Style
 */
.uk-input,
.uk-select,
.uk-textarea {
  /* 1 */
  max-width: 100%;
  /* 2 */
  width: 100%;
  /* 3 */
  border: 0 none;
  /* 4 */
  padding: 0 10px;
  background: #f8f8f8;
  color: #325685;
}

/*
 * Single-line
 * 1. Allow any element to look like an `input` or `select` element
 * 2. Make sure line-height is not larger than height
 *    Also needed to center the text vertically
 */
.uk-input,
.uk-select:not([multiple]):not([size]) {
  height: 40px;
  vertical-align: middle;
  /* 1 */
  display: inline-block;
}

/* 2 */
.uk-input:not(input),
.uk-select:not(select) {
  line-height: 40px;
}

/*
 * Multi-line
 */
.uk-select[multiple],
.uk-select[size],
.uk-textarea {
  padding-top: 4px;
  padding-bottom: 4px;
  vertical-align: top;
}

.uk-select[multiple],
.uk-select[size] {
  resize: vertical;
}

/* Focus */
.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
  outline: none;
  background-color: #f8f8f8;
  color: #325685;
}

/* Disabled */
.uk-input:disabled,
.uk-select:disabled,
.uk-textarea:disabled {
  background-color: #f8f8f8;
  color: #999;
}

/*
 * Placeholder
 */
.uk-input::-ms-input-placeholder {
  color: #999 !important;
}

.uk-input::placeholder {
  color: #999;
}

.uk-textarea::-ms-input-placeholder {
  color: #999 !important;
}

.uk-textarea::placeholder {
  color: #999;
}

/* Style modifier (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Small
 */
.uk-form-small {
  font-size: 0.875rem;
}

/* Single-line */
.uk-form-small:not(textarea):not([multiple]):not([size]) {
  height: 30px;
  padding-left: 8px;
  padding-right: 8px;
}

/* Multi-line */
textarea.uk-form-small,
[multiple].uk-form-small,
[size].uk-form-small {
  padding: 5px 8px;
}

.uk-form-small:not(select):not(input):not(textarea) {
  line-height: 30px;
}

/*
 * Large
 */
.uk-form-large {
  font-size: 1.25rem;
}

/* Single-line */
.uk-form-large:not(textarea):not([multiple]):not([size]) {
  height: 55px;
  padding-left: 12px;
  padding-right: 12px;
}

/* Multi-line */
textarea.uk-form-large,
[multiple].uk-form-large,
[size].uk-form-large {
  padding: 7px 12px;
}

.uk-form-large:not(select):not(input):not(textarea) {
  line-height: 55px;
}

/* Style modifier (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Error
 */
.uk-form-danger,
.uk-form-danger:focus {
  color: #f0506e;
}

/*
 * Success
 */
.uk-form-success,
.uk-form-success:focus {
  color: #32d296;
}

/*
 * Blank
 */
.uk-form-blank {
  background: none;
}

/* Width modifiers (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Fixed widths
 * Different widths for mini sized `input` and `select` elements
 */
input.uk-form-width-xsmall {
  width: 50px;
}

select.uk-form-width-xsmall {
  width: 75px;
}

.uk-form-width-small {
  width: 130px;
}

.uk-form-width-medium {
  width: 200px;
}

.uk-form-width-large {
  width: 500px;
}

/* Select
 ========================================================================== */
/*
 * 1. Remove default style. Also works in Firefox
 * 2. Style
 * 3. Remove default style in IE 10/11
 * 4. Set `color` for options in the select dropdown, because the inherited `color` might be too light.
 */
.uk-select:not([multiple]):not([size]) {
  /* 1 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 2 */
  padding-right: 20px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23325685%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23325685%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

/* 3 */
.uk-select:not([multiple]):not([size])::-ms-expand {
  display: none;
}

/* 4 */
.uk-select:not([multiple]):not([size]) option {
  color: #444;
}

/*
 * Disabled
 */
.uk-select:not([multiple]):not([size]):disabled {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

/* Datalist
 ========================================================================== */
/*
 * 1. Remove default style in Chrome
 */
.uk-input[list] {
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

.uk-input[list]:hover,
.uk-input[list]:focus {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23325685%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

/* 1 */
.uk-input[list]::-webkit-calendar-picker-indicator {
  display: none !important;
}

/* Radio and checkbox
 * Note: Does not work in IE11
 ========================================================================== */
/*
 * 1. Style
 * 2. Make box more robust so it clips the child element
 * 3. Vertical alignment
 * 4. Remove default style
 * 5. Fix black background on iOS
 * 6. Center icons
 */
.uk-radio,
.uk-checkbox {
  /* 1 */
  display: inline-block;
  height: 16px;
  width: 16px;
  /* 2 */
  overflow: hidden;
  /* 3 */
  margin-top: -4px;
  vertical-align: middle;
  /* 4 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 5 */
  background-color: #ebebeb;
  /* 6 */
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.uk-radio {
  border-radius: 50%;
}

/* Focus */
.uk-radio:focus,
.uk-checkbox:focus {
  background-color: #dfdfdf;
  outline: none;
}

/*
 * Checked
 */
.uk-radio:checked,
.uk-checkbox:checked,
.uk-checkbox:indeterminate {
  background-color: #325685;
}

/* Focus */
.uk-radio:checked:focus,
.uk-checkbox:checked:focus,
.uk-checkbox:indeterminate:focus {
  background-color: #243e60;
}

/*
 * Icons
 */
.uk-radio:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23ffffff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23ffffff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.uk-checkbox:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23ffffff%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

/*
 * Disabled
 */
.uk-radio:disabled,
.uk-checkbox:disabled {
  background-color: #f8f8f8;
}

.uk-radio:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23999%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.uk-checkbox:disabled:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23999%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

/* Legend
 ========================================================================== */
/*
 * Legend
 * 1. Behave like block element
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove padding so people aren't caught out if they zero out fieldsets.
 * 4. Style
 */
.uk-legend {
  /* 1 */
  width: 100%;
  /* 2 */
  color: inherit;
  /* 3 */
  padding: 0;
  /* 4 */
  font-size: 1.5rem;
  line-height: 1.4;
}

/* Custom controls
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Behave like most inline-block elements
 */
.uk-form-custom {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
}

/*
 * 1. Position and resize the form control to always cover its container
 * 2. Required for Firefox for positioning to the left
 * 3. Required for Webkit to make `height` work
 * 4. Hide controle and show cursor
 * 5. Needed for the cursor
 * 6. Clip height caused by 5. Needed for Webkit only
 */
.uk-form-custom select,
.uk-form-custom input[type=file] {
  /* 1 */
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  /* 2 */
  left: 0;
  /* 3 */
  -webkit-appearance: none;
  /* 4 */
  opacity: 0;
  cursor: pointer;
}

.uk-form-custom input[type=file] {
  /* 5 */
  font-size: 500px;
  /* 6 */
  overflow: hidden;
}

/* Label
 ========================================================================== */
/* Layout
 ========================================================================== */
/*
 * Stacked
 */
.uk-form-stacked .uk-form-label {
  display: block;
  margin-bottom: 10px;
}

/*
 * Horizontal
 */
/* Tablet portrait and smaller */
@media (max-width: 959px) {
  /* Behave like `uk-form-stacked` */
  .uk-form-horizontal .uk-form-label {
    display: block;
    margin-bottom: 10px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-form-horizontal .uk-form-label {
    width: 200px;
    margin-top: 7px;
    float: left;
  }

  .uk-form-horizontal .uk-form-controls {
    margin-left: 215px;
  }

  /* Better vertical alignment if controls are checkboxes and radio buttons with text */
  .uk-form-horizontal .uk-form-controls-text {
    padding-top: 7px;
  }
}
/* Icons
 ========================================================================== */
/*
 * 1. Set position
 * 2. Set width
 * 3. Center icon vertically and horizontally
 * 4. Style
 */
.uk-form-icon {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 40px;
  /* 3 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* 4 */
  color: #999;
}

/*
 * Required for `a`.
 */
.uk-form-icon:hover {
  color: #325685;
}

/*
 * Make `input` element clickable through icon, e.g. if it's a `span`
 */
.uk-form-icon:not(a):not(button):not(input) {
  pointer-events: none;
}

/*
 * Input padding
 */
.uk-form-icon:not(.uk-form-icon-flip) ~ .uk-input {
  padding-left: 40px !important;
}

/*
 * Position modifier
 */
.uk-form-icon-flip {
  right: 0;
  left: auto;
}

.uk-form-icon-flip ~ .uk-input {
  padding-right: 40px !important;
}

/* ========================================================================
   Component: Button
 ========================================================================== */
/*
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Address `overflow` set to `hidden` in IE.
 * 4. Correct `font` properties and `color` not being inherited for `button`.
 * 5. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 6. Remove default style for `input type="submit"`in iOS.
 * 7. Style
 * 8. `line-height` is used to create a height because it also centers the text vertically for `a` elements.
 *    Better would be to use height and flexbox to center the text vertically but flexbox doesn't work in Firefox on `button` elements.
 * 9. Align text if button has a width
 * 10. Required for `a`.
 */
.uk-button {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  overflow: visible;
  /* 4 */
  font: inherit;
  color: inherit;
  /* 5 */
  text-transform: none;
  /* 6 */
  -webkit-appearance: none;
  border-radius: 0;
  /* 7 */
  display: inline-block;
  box-sizing: border-box;
  padding: 0 30px;
  vertical-align: middle;
  font-size: 16px;
  /* 8 */
  line-height: 40px;
  /* 9 */
  text-align: center;
  /* 10 */
  text-decoration: none;
}

.uk-button:not(:disabled) {
  cursor: pointer;
}

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Hover */
.uk-button:hover {
  /* 9 */
  text-decoration: none;
}

/* OnClick + Active */
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-button-default {
  background-color: #f8f8f8;
  color: #333;
}

/* Hover */
.uk-button-default:hover {
  background-color: #ebebeb;
  color: #333;
}

/* OnClick + Active */
.uk-button-default:active,
.uk-button-default.uk-active {
  background-color: #dfdfdf;
  color: #333;
}

/*
 * Primary
 */
.uk-button-primary {
  background-color: #325685;
  color: #ffffff;
}

/* Hover */
.uk-button-primary:hover {
  background-color: #2b4a72;
  color: #ffffff;
}

/* OnClick + Active */
.uk-button-primary:active,
.uk-button-primary.uk-active {
  background-color: #243e60;
  color: #ffffff;
}

/*
 * Secondary
 */
.uk-button-secondary {
  background-color: #92b3d7;
  color: #ffffff;
}

/* Hover */
.uk-button-secondary:hover {
  background-color: #7fa6d0;
  color: #ffffff;
}

/* OnClick + Active */
.uk-button-secondary:active,
.uk-button-secondary.uk-active {
  background-color: #6d99c9;
  color: #ffffff;
}

/*
 * Danger
 */
.uk-button-danger {
  background-color: #f0506e;
  color: #ffffff;
}

/* Hover */
.uk-button-danger:hover {
  background-color: #ee395b;
  color: #ffffff;
}

/* OnClick + Active */
.uk-button-danger:active,
.uk-button-danger.uk-active {
  background-color: #ec2147;
  color: #ffffff;
}

/*
 * Disabled
 * The same for all style modifiers
 */
.uk-button-default:disabled,
.uk-button-primary:disabled,
.uk-button-secondary:disabled,
.uk-button-danger:disabled {
  background-color: #f8f8f8;
  color: #999;
}

/* Size modifiers
 ========================================================================== */
.uk-button-small {
  padding: 0 15px;
  line-height: 30px;
  font-size: 0.875rem;
}

.uk-button-large {
  padding: 0 40px;
  line-height: 55px;
  font-size: 1.25rem;
}

/* Text modifiers
 ========================================================================== */
/*
 * Text
 * 1. Reset
 * 2. Style
 */
.uk-button-text {
  /* 1 */
  padding: 0;
  line-height: 1.5;
  background: none;
  /* 2 */
  color: #999;
}

/* Hover */
.uk-button-text:hover {
  color: #325685;
}

/* Disabled */
.uk-button-text:disabled {
  color: #999;
}

/*
 * Link
 * 1. Reset
 * 2. Style
 */
.uk-button-link {
  /* 1 */
  padding: 0;
  line-height: 1.5;
  background: none;
  /* 2 */
  color: #325685;
}

/* Hover */
.uk-button-link:hover {
  color: #243e60;
  text-decoration: underline;
}

/* Disabled */
.uk-button-link:disabled {
  color: #999;
  text-decoration: none;
}

/* Group
 ========================================================================== */
/*
 * 1. Using `flex` instead of `inline-block` to prevent whitespace betweent child elements
 * 2. Behave like button
 * 3. Create position context
 */
.uk-button-group {
  /* 1 */
  display: inline-flex;
  /* 2 */
  vertical-align: middle;
  /* 3 */
  position: relative;
}

/* ========================================================================
   Component: Progress
 ========================================================================== */
/*
 * 1. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 * 2. Remove default style
 * 3. Behave like a block element
 * 4. Remove borders in Firefox and Edge
 * 5. Set background color for progress container in Firefox, IE11 and Edge
 * 6. Style
 */
.uk-progress {
  /* 1 */
  vertical-align: baseline;
  /* 2 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 3 */
  display: block;
  width: 100%;
  /* 4 */
  border: 0;
  /* 5 */
  background-color: #f8f8f8;
  /* 6 */
  margin-bottom: 20px;
  height: 15px;
}

/* Add margin if adjacent element */
* + .uk-progress {
  margin-top: 20px;
}

/*
 * Remove animated circles for indeterminate state in IE11 and Edge
 */
.uk-progress:indeterminate {
  color: transparent;
}

/*
 * Progress container
 * 2. Remove progress bar for indeterminate state in Firefox
 */
.uk-progress::-webkit-progress-bar {
  background-color: #f8f8f8;
}

/* 2 */
.uk-progress:indeterminate::-moz-progress-bar {
  width: 0;
}

/*
 * Progress bar
 * 1. Remove right border in IE11 and Edge
 */
.uk-progress::-webkit-progress-value {
  background-color: #325685;
  transition: width 0.6s ease;
}

.uk-progress::-moz-progress-bar {
  background-color: #325685;
}

.uk-progress::-ms-fill {
  background-color: #325685;
  transition: width 0.6s ease;
  /* 1 */
  border: 0;
}

/* ========================================================================
   Component: Section
 ========================================================================== */
/*
 * 1. Make it work with `100vh` and height in general
 */
.uk-section {
  display: flow-root;
  box-sizing: border-box;
  /* 1 */
  padding-top: 40px;
  padding-bottom: 40px;
}

/* Desktop and bigger */
@media (min-width: 960px) {
  .uk-section {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-section > :last-child {
  margin-bottom: 0;
}

/* Size modifiers
 ========================================================================== */
/*
 * XSmall
 */
.uk-section-xsmall {
  padding-top: 20px;
  padding-bottom: 20px;
}

/*
 * Small
 */
.uk-section-small {
  padding-top: 40px;
  padding-bottom: 40px;
}

/*
 * Large
 */
.uk-section-large {
  padding-top: 70px;
  padding-bottom: 70px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-section-large {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}
/*
 * XLarge
 */
.uk-section-xlarge {
  padding-top: 140px;
  padding-bottom: 140px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-section-xlarge {
    padding-top: 210px;
    padding-bottom: 210px;
  }
}
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-section-default {
  background: #fff;
}

/*
 * Muted
 */
.uk-section-muted {
  background: #f8f8f8;
}

/*
 * Primary
 */
.uk-section-primary {
  background: #325685;
}

/*
 * Secondary
 */
.uk-section-secondary {
  background: #92b3d7;
}

/* Overlap modifier
 ========================================================================== */
/*
 * Reserved modifier to make a section overlap another section with an border image
 * Implemented by the theme
 */
/* ========================================================================
   Component: Container
 ========================================================================== */
/*
 * 1. Box sizing has to be `content-box` so the max-width is always the same and
 *    unaffected by the padding on different breakpoints. It's important for the size modifiers.
 */
.uk-container {
  display: flow-root;
  /* 1 */
  box-sizing: content-box;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-container > :last-child {
  margin-bottom: 0;
}

/*
 * Remove padding from nested containers
 */
.uk-container .uk-container {
  padding-left: 0;
  padding-right: 0;
}

/* Size modifier
 ========================================================================== */
.uk-container-xsmall {
  max-width: 750px;
}

.uk-container-small {
  max-width: 900px;
}

.uk-container-large {
  max-width: 1400px;
}

.uk-container-xlarge {
  max-width: 1600px;
}

.uk-container-expand {
  max-width: none;
}

/* Expand modifier
 ========================================================================== */
/*
 * Expand one side only
 */
.uk-container-expand-left {
  margin-left: 0;
}

.uk-container-expand-right {
  margin-right: 0;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-expand-left.uk-container-xsmall,
.uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 30px);
  }

  .uk-container-expand-left.uk-container-small,
.uk-container-expand-right.uk-container-small {
    max-width: calc(50% + (900px / 2) - 30px);
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container-expand-left,
.uk-container-expand-right {
    max-width: calc(50% + (1200px / 2) - 40px);
  }

  .uk-container-expand-left.uk-container-xsmall,
.uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 40px);
  }

  .uk-container-expand-left.uk-container-small,
.uk-container-expand-right.uk-container-small {
    max-width: calc(50% + (900px / 2) - 40px);
  }

  .uk-container-expand-left.uk-container-large,
.uk-container-expand-right.uk-container-large {
    max-width: calc(50% + (1400px / 2) - 40px);
  }

  .uk-container-expand-left.uk-container-xlarge,
.uk-container-expand-right.uk-container-xlarge {
    max-width: calc(50% + (1600px / 2) - 40px);
  }
}
/* Item
 ========================================================================== */
/*
 * Utility classes to reset container padding on the left or right side
 * Note: It has to be negative margin on the item, because it's specific to the item.
 */
.uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
  width: calc(100% + 15px);
}

.uk-container-item-padding-remove-left {
  margin-left: -15px;
}

.uk-container-item-padding-remove-right {
  margin-right: -15px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
    width: calc(100% + 30px);
  }

  .uk-container-item-padding-remove-left {
    margin-left: -30px;
  }

  .uk-container-item-padding-remove-right {
    margin-right: -30px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
    width: calc(100% + 40px);
  }

  .uk-container-item-padding-remove-left {
    margin-left: -40px;
  }

  .uk-container-item-padding-remove-right {
    margin-right: -40px;
  }
}
/* ========================================================================
   Component: Tile
 ========================================================================== */
.uk-tile {
  display: flow-root;
  position: relative;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;
  padding-bottom: 40px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-tile {
    padding-left: 30px;
    padding-right: 30px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-tile {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-tile > :last-child {
  margin-bottom: 0;
}

/* Size modifiers
 ========================================================================== */
/*
 * XSmall
 */
.uk-tile-xsmall {
  padding-top: 20px;
  padding-bottom: 20px;
}

/*
 * Small
 */
.uk-tile-small {
  padding-top: 40px;
  padding-bottom: 40px;
}

/*
 * Large
 */
.uk-tile-large {
  padding-top: 70px;
  padding-bottom: 70px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-tile-large {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}
/*
 * XLarge
 */
.uk-tile-xlarge {
  padding-top: 140px;
  padding-bottom: 140px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-tile-xlarge {
    padding-top: 210px;
    padding-bottom: 210px;
  }
}
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-tile-default {
  background-color: #fff;
}

/*
 * Muted
 */
.uk-tile-muted {
  background-color: #f8f8f8;
}

/*
 * Primary
 */
.uk-tile-primary {
  background-color: #325685;
}

/*
 * Secondary
 */
.uk-tile-secondary {
  background-color: #92b3d7;
}

/* ========================================================================
   Component: Card
 ========================================================================== */
.uk-card {
  position: relative;
  box-sizing: border-box;
}

/* Sections
 ========================================================================== */
.uk-card-body {
  display: flow-root;
  padding: 30px 30px;
}

.uk-card-header {
  display: flow-root;
  padding: 15px 30px;
}

.uk-card-footer {
  display: flow-root;
  padding: 15px 30px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-card-body {
    padding: 40px 40px;
  }

  .uk-card-header {
    padding: 20px 40px;
  }

  .uk-card-footer {
    padding: 20px 40px;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-card-body > :last-child,
.uk-card-header > :last-child,
.uk-card-footer > :last-child {
  margin-bottom: 0;
}

/* Media
 ========================================================================== */
/*
 * Reserved alignment modifier to style the media element, e.g. with `border-radius`
 * Implemented by the theme
 */
/* Title
 ========================================================================== */
.uk-card-title {
  font-size: 1.5rem;
  line-height: 1.4;
}

/* Badge
 ========================================================================== */
/*
 * 1. Position
 * 2. Size
 * 3. Style
 * 4. Center child vertically
 */
.uk-card-badge {
  /* 1 */
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  /* 2 */
  height: 30px;
  padding: 0 10px;
  /* 3 */
  background: #325685;
  color: #ffffff;
  font-size: 0.875rem;
  /* 4 */
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
}

/*
 * Remove margin from adjacent element
 */
.uk-card-badge:first-child + * {
  margin-top: 0;
}

/* Hover modifier
 ========================================================================== */
.uk-card-hover:not(.uk-card-default):not(.uk-card-primary):not(.uk-card-secondary):hover {
  background-color: #f8f8f8;
}

/* Style modifiers
 ========================================================================== */
/*
 * Default
 * Note: Header and Footer are only implemented for the default style
 */
.uk-card-default {
  background-color: #f8f8f8;
  color: #325685;
}

.uk-card-default .uk-card-title {
  color: #333;
}

.uk-card-default.uk-card-hover:hover {
  background-color: #ebebeb;
}

/*
 * Primary
 */
.uk-card-primary {
  background-color: #325685;
  color: #ffffff;
}

.uk-card-primary .uk-card-title {
  color: #ffffff;
}

.uk-card-primary.uk-card-hover:hover {
  background-color: #2b4a72;
}

/*
 * Secondary
 */
.uk-card-secondary {
  background-color: #92b3d7;
  color: #ffffff;
}

.uk-card-secondary .uk-card-title {
  color: #ffffff;
}

.uk-card-secondary.uk-card-hover:hover {
  background-color: #7fa6d0;
}

/* Size modifier
 ========================================================================== */
/*
 * Small
 */
.uk-card-small.uk-card-body,
.uk-card-small .uk-card-body {
  padding: 20px 20px;
}

.uk-card-small .uk-card-header {
  padding: 13px 20px;
}

.uk-card-small .uk-card-footer {
  padding: 13px 20px;
}

/*
 * Large
 */
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-card-large.uk-card-body,
.uk-card-large .uk-card-body {
    padding: 70px 70px;
  }

  .uk-card-large .uk-card-header {
    padding: 35px 70px;
  }

  .uk-card-large .uk-card-footer {
    padding: 35px 70px;
  }
}
/* ========================================================================
   Component: Close
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-close {
  color: #999;
}

/* Hover */
.uk-close:hover {
  color: #325685;
}

/* ========================================================================
   Component: Spinner
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
/* SVG
 ========================================================================== */
.uk-spinner > * {
  animation: uk-spinner-rotate 1.4s linear infinite;
}

@keyframes uk-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
/*
 * Circle
 */
.uk-spinner > * > * {
  stroke-dasharray: 88px;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: uk-spinner-dash 1.4s ease-in-out infinite;
  stroke-width: 1;
  stroke-linecap: round;
}

@keyframes uk-spinner-dash {
  0% {
    stroke-dashoffset: 88px;
  }
  50% {
    stroke-dashoffset: 22px;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 88px;
    transform: rotate(450deg);
  }
}
/* ========================================================================
   Component: Totop
 ========================================================================== */
/*
 * Addopts `uk-icon`
 */
.uk-totop {
  padding: 5px;
  color: #999;
}

/* Hover */
.uk-totop:hover {
  color: #325685;
}

/* OnClick */
.uk-totop:active {
  color: #333;
}

/* ========================================================================
   Component: Marker
 ========================================================================== */
/*
 * Addopts `uk-icon`
 */
.uk-marker {
  padding: 5px;
  background: #92b3d7;
  color: #ffffff;
}

/* Hover */
.uk-marker:hover {
  color: #ffffff;
}

/* ========================================================================
   Component: Alert
 ========================================================================== */
.uk-alert {
  position: relative;
  margin-bottom: 20px;
  padding: 15px 29px 15px 15px;
  background: #f8f8f8;
  color: #325685;
}

/* Add margin if adjacent element */
* + .uk-alert {
  margin-top: 20px;
}

/*
 * Remove margin from the last-child
 */
.uk-alert > :last-child {
  margin-bottom: 0;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-alert-close {
  position: absolute;
  top: 20px;
  right: 15px;
}

/*
 * Remove margin from adjacent element
 */
.uk-alert-close:first-child + * {
  margin-top: 0;
}

/*
 * Hover
 */
/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-alert-primary {
  background: #c4cfdc;
  color: #325685;
}

/*
 * Success
 */
.uk-alert-success {
  background: #edfbf6;
  color: #32d296;
}

/*
 * Warning
 */
.uk-alert-warning {
  background: #fef5ee;
  color: #faa05a;
}

/*
 * Danger
 */
.uk-alert-danger {
  background: #fef4f6;
  color: #f0506e;
}

/* ========================================================================
   Component: Placeholder
 ========================================================================== */
.uk-placeholder {
  margin-bottom: 20px;
  padding: 30px 30px;
  background: #f8f8f8;
}

/* Add margin if adjacent element */
* + .uk-placeholder {
  margin-top: 20px;
}

/*
 * Remove margin from the last-child
 */
.uk-placeholder > :last-child {
  margin-bottom: 0;
}

/* ========================================================================
   Component: Badge
 ========================================================================== */
/*
 * 1. Style
 * 2. Center child vertically and horizontally
 */
.uk-badge {
  box-sizing: border-box;
  min-width: 22px;
  height: 22px;
  padding: 0 5px;
  border-radius: 500px;
  vertical-align: middle;
  /* 1 */
  background: #325685;
  color: #ffffff !important;
  font-size: 0.875rem;
  /* 2 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
}

/*
 * Required for `a`
 */
.uk-badge:hover {
  text-decoration: none;
}

/* ========================================================================
   Component: Label
 ========================================================================== */
.uk-label {
  display: inline-block;
  padding: 0 10px;
  background: #325685;
  line-height: 1.5;
  font-size: 0.875rem;
  color: #ffffff;
  vertical-align: middle;
  white-space: nowrap;
}

/* Color modifiers
 ========================================================================== */
/*
 * Success
 */
.uk-label-success {
  background-color: #32d296;
  color: #ffffff;
}

/*
 * Warning
 */
.uk-label-warning {
  background-color: #faa05a;
  color: #ffffff;
}

/*
 * Danger
 */
.uk-label-danger {
  background-color: #f0506e;
  color: #ffffff;
}

/* ========================================================================
   Component: Overlay
 ========================================================================== */
.uk-overlay {
  padding: 30px 30px;
}

/*
 * Remove margin from the last-child
 */
.uk-overlay > :last-child {
  margin-bottom: 0;
}

/* Icon
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-overlay-default {
  background: rgba(255, 255, 255, 0.8);
}

/*
 * Primary
 */
.uk-overlay-primary {
  background: rgba(146, 179, 215, 0.8);
}

/* ========================================================================
   Component: Article
 ========================================================================== */
.uk-article {
  display: flow-root;
}

/*
 * Remove margin from the last-child
 */
.uk-article > :last-child {
  margin-bottom: 0;
}

/* Adjacent sibling
 ========================================================================== */
.uk-article + .uk-article {
  margin-top: 70px;
}

/* Title
 ========================================================================== */
.uk-article-title {
  font-size: 2.23125rem;
  line-height: 1.2;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-article-title {
    font-size: 2.625rem;
  }
}
/* Meta
 ========================================================================== */
.uk-article-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999;
}

/* ========================================================================
   Component: Comment
 ========================================================================== */
/* Sections
 ========================================================================== */
.uk-comment-body {
  display: flow-root;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.uk-comment-header {
  display: flow-root;
  margin-bottom: 20px;
}

/*
 * Remove margin from the last-child
 */
.uk-comment-body > :last-child,
.uk-comment-header > :last-child {
  margin-bottom: 0;
}

/* Title
 ========================================================================== */
.uk-comment-title {
  font-size: 1.25rem;
  line-height: 1.4;
}

/* Meta
 ========================================================================== */
.uk-comment-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999;
}

/* Avatar
 ========================================================================== */
/* List
 ========================================================================== */
.uk-comment-list {
  padding: 0;
  list-style: none;
}

/* Adjacent siblings */
.uk-comment-list > :nth-child(n+2) {
  margin-top: 70px;
}

/*
 * Sublists
 * Note: General sibling selector allows reply block between comment and sublist
 */
.uk-comment-list .uk-comment ~ ul {
  margin: 70px 0 0 0;
  padding-left: 30px;
  list-style: none;
}

/* Tablet and bigger */
@media (min-width: 960px) {
  .uk-comment-list .uk-comment ~ ul {
    padding-left: 100px;
  }
}
/* Adjacent siblings */
.uk-comment-list .uk-comment ~ ul > :nth-child(n+2) {
  margin-top: 70px;
}

/* Style modifier
 ========================================================================== */
/* ========================================================================
   Component: Search
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Reset `form`
 */
.uk-search {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  margin: 0;
}

/* Input
 ========================================================================== */
/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and Safari on OS X.
 */
.uk-search-input::-webkit-search-cancel-button,
.uk-search-input::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
 * Removes placeholder transparency in Firefox.
 */
.uk-search-input::-moz-placeholder {
  opacity: 1;
}

/*
 * 1. Define consistent box sizing.
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers
 * 5. Show the overflow in Edge.
 * 6. Remove default style in iOS.
 * 7. Vertical alignment
 * 8. Take the full container width
 * 9. Style
 */
.uk-search-input {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
  /* 5 */
  overflow: visible;
  /* 6 */
  -webkit-appearance: none;
  /* 7 */
  vertical-align: middle;
  /* 8 */
  width: 100%;
  /* 9 */
  border: none;
  color: #325685;
}

.uk-search-input:focus {
  outline: none;
}

/* Placeholder */
.uk-search-input:-ms-input-placeholder {
  color: #999 !important;
}

.uk-search-input::placeholder {
  color: #999;
}

/* Icon (Adopts `uk-icon`)
 ========================================================================== */
/*
 * Position above input
 * 1. Set position
 * 2. Center icon vertically and horizontally
 * 3. Style
 */
.uk-search .uk-search-icon {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: #999;
}

/*
 * Required for `a`.
 */
.uk-search .uk-search-icon:hover {
  color: #999;
}

/*
 * Make `input` element clickable through icon, e.g. if it's a `span`
 */
.uk-search .uk-search-icon:not(a):not(button):not(input) {
  pointer-events: none;
}

/*
 * Position modifier
 */
.uk-search .uk-search-icon-flip {
  right: 0;
  left: auto;
}

/* Default modifier
 ========================================================================== */
.uk-search-default {
  width: 180px;
}

/*
 * Input
 */
.uk-search-default .uk-search-input {
  height: 40px;
  padding-left: 6px;
  padding-right: 6px;
  background: #f8f8f8;
}

/* Focus */
.uk-search-default .uk-search-input:focus {
  background-color: #f8f8f8;
}

/*
 * Icon
 */
.uk-search-default .uk-search-icon {
  width: 40px;
}

.uk-search-default .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 40px;
}

.uk-search-default .uk-search-icon-flip ~ .uk-search-input {
  padding-right: 40px;
}

/* Navbar modifier
 ========================================================================== */
.uk-search-navbar {
  width: 400px;
}

/*
 * Input
 */
.uk-search-navbar .uk-search-input {
  height: 40px;
  background: transparent;
  font-size: 1.5rem;
}

/* Focus */
/*
 * Icon
 */
.uk-search-navbar .uk-search-icon {
  width: 40px;
}

.uk-search-navbar .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 40px;
}

.uk-search-navbar .uk-search-icon-flip ~ .uk-search-input {
  padding-right: 40px;
}

/* Large modifier
 ========================================================================== */
.uk-search-large {
  width: 500px;
}

/*
 * Input
 */
.uk-search-large .uk-search-input {
  height: 80px;
  background: transparent;
  font-size: 2.625rem;
}

/* Focus */
/*
 * Icon
 */
.uk-search-large .uk-search-icon {
  width: 80px;
}

.uk-search-large .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 80px;
}

.uk-search-large .uk-search-icon-flip ~ .uk-search-input {
  padding-right: 80px;
}

/* Toggle
 ========================================================================== */
.uk-search-toggle {
  color: #999;
}

/* Hover */
.uk-search-toggle:hover {
  color: #325685;
}

/* ========================================================================
   Component: Accordion
 ========================================================================== */
.uk-accordion {
  padding: 0;
  list-style: none;
}

/* Item
 ========================================================================== */
.uk-accordion > :nth-child(n+2) {
  margin-top: 20px;
}

/* Title
 ========================================================================== */
.uk-accordion-title {
  display: block;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #333;
}

/* Hover */
.uk-accordion-title:hover {
  color: #325685;
  text-decoration: none;
}

/* Content
 ========================================================================== */
.uk-accordion-content {
  display: flow-root;
  margin-top: 20px;
}

/*
 * Remove margin from the last-child
 */
.uk-accordion-content > :last-child {
  margin-bottom: 0;
}

/* ========================================================================
   Component: Drop
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 */
.uk-drop {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  /* 3 */
  box-sizing: border-box;
  width: 300px;
}

/* Show */
.uk-drop.uk-open {
  display: block;
}

/* Direction / Alignment modifiers
 ========================================================================== */
/* Direction */
[class*=uk-drop-top] {
  margin-top: -20px;
}

[class*=uk-drop-bottom] {
  margin-top: 20px;
}

[class*=uk-drop-left] {
  margin-left: -20px;
}

[class*=uk-drop-right] {
  margin-left: 20px;
}

/* Grid modifiers
 ========================================================================== */
.uk-drop-stack .uk-drop-grid > * {
  width: 100% !important;
}

/* ========================================================================
   Component: Dropdown
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 * 4. Style
 */
.uk-dropdown {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  /* 3 */
  box-sizing: border-box;
  min-width: 200px;
  /* 4 */
  padding: 15px;
  background: #f8f8f8;
  color: #325685;
}

/* Show */
.uk-dropdown.uk-open {
  display: block;
}

/* Nav
 * Adopts `uk-nav`
 ========================================================================== */
.uk-dropdown-nav {
  white-space: nowrap;
}

/*
 * Items
 */
.uk-dropdown-nav > li > a {
  color: #999;
}

/* Hover + Active */
.uk-dropdown-nav > li > a:hover,
.uk-dropdown-nav > li.uk-active > a {
  color: #325685;
}

/*
 * Header
 */
.uk-dropdown-nav .uk-nav-header {
  color: #333;
}

/*
 * Divider
 */
.uk-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #e5e5e5;
}

/*
 * Sublists
 */
.uk-dropdown-nav .uk-nav-sub a {
  color: #999;
}

.uk-dropdown-nav .uk-nav-sub a:hover,
.uk-dropdown-nav .uk-nav-sub li.uk-active > a {
  color: #325685;
}

/* Direction / Alignment modifiers
 ========================================================================== */
/* Direction */
[class*=uk-dropdown-top] {
  margin-top: -10px;
}

[class*=uk-dropdown-bottom] {
  margin-top: 10px;
}

[class*=uk-dropdown-left] {
  margin-left: -10px;
}

[class*=uk-dropdown-right] {
  margin-left: 10px;
}

/* Grid modifiers
 ========================================================================== */
.uk-dropdown-stack .uk-dropdown-grid > * {
  width: 100% !important;
}

/* ========================================================================
   Component: Modal
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 */
.uk-modal {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* 4 */
  padding: 15px 15px;
  /* 5 */
  background: rgba(0, 0, 0, 0.6);
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal {
    padding: 50px 30px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-modal {
    padding-left: 40px;
    padding-right: 40px;
  }
}
/*
 * Open
 */
.uk-modal.uk-open {
  opacity: 1;
}

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-modal-page {
  overflow: hidden;
}

/* Dialog
 ========================================================================== */
/*
 * 1. Create position context for spinner and close button
 * 2. Dimensions
 * 3. Fix `max-width: 100%` not working in combination with flex and responsive images in IE11
 *    `!important` needed to overwrite `uk-width-auto`. See `#modal-media-image` in tests
 * 4. Style
 * 5. Slide-in transition
 */
.uk-modal-dialog {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box;
  margin: 0 auto;
  width: 600px;
  /* 3 */
  max-width: calc(100% - 0.01px) !important;
  /* 4 */
  background: #fff;
  /* 5 */
  opacity: 0;
  transform: translateY(-100px);
  transition: 0.3s linear;
  transition-property: opacity, transform;
}

/*
 * Open
 */
.uk-open > .uk-modal-dialog {
  opacity: 1;
  transform: translateY(0);
}

/* Size modifier
 ========================================================================== */
/*
 * Container size
 * Take the same size as the Container component
 */
.uk-modal-container .uk-modal-dialog {
  width: 1200px;
}

/*
 * Full size
 * 1. Remove padding and background from modal
 * 2. Reset all default declarations from modal dialog
 */
/* 1 */
.uk-modal-full {
  padding: 0;
  background: none;
}

/* 2 */
.uk-modal-full .uk-modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  transform: translateY(0);
}

/* Sections
 ========================================================================== */
.uk-modal-body {
  display: flow-root;
  padding: 30px 30px;
}

.uk-modal-header {
  display: flow-root;
  padding: 15px 30px;
  background: #f8f8f8;
}

.uk-modal-footer {
  display: flow-root;
  padding: 15px 30px;
  background: #f8f8f8;
}

/*
 * Remove margin from the last-child
 */
.uk-modal-body > :last-child,
.uk-modal-header > :last-child,
.uk-modal-footer > :last-child {
  margin-bottom: 0;
}

/* Title
 ========================================================================== */
.uk-modal-title {
  font-size: 2rem;
  line-height: 1.3;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
[class*=uk-modal-close-] {
  position: absolute;
  z-index: 1010;
  top: 10px;
  right: 10px;
  padding: 5px;
}

/*
 * Remove margin from adjacent element
 */
[class*=uk-modal-close-]:first-child + * {
  margin-top: 0;
}

/*
 * Hover
 */
/*
 * Default
 */
/*
 * Outside
 * 1. Prevent scrollbar on small devices
 */
.uk-modal-close-outside {
  top: 0;
  /* 1 */
  right: -5px;
  transform: translate(0, -100%);
  color: white;
}

.uk-modal-close-outside:hover {
  color: #ffffff;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* 1 */
  .uk-modal-close-outside {
    right: 0;
    transform: translate(100%, -100%);
  }
}
/*
 * Full
 */
/* ========================================================================
   Component: Sticky
 ========================================================================== */
/*
 * Create position context so it's t the same like when fixed.
 */
.uk-sticky {
  position: relative;
}

/*
 * 1. Force new layer to resolve frame rate issues on devices with lower frame rates
 */
.uk-sticky-fixed {
  z-index: 980;
  box-sizing: border-box;
  margin: 0 !important;
  /* 1 */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/*
 * Faster animations
 */
.uk-sticky[class*=uk-animation-] {
  animation-duration: 0.2s;
}

.uk-sticky.uk-animation-reverse {
  animation-duration: 0.2s;
}

/* ========================================================================
   Component: Off-canvas
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 */
.uk-offcanvas {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas {
  right: 0;
  left: auto;
}

/* Bar
 ========================================================================== */
/*
 * 1. Set position
 * 2. Size and style
 * 3. Allow scrolling
 */
.uk-offcanvas-bar {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: -270px;
  /* 2 */
  box-sizing: border-box;
  width: 270px;
  padding: 3rem 20px;
  background: #ffffff;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-offcanvas-bar {
    left: -350px;
    width: 350px;
    padding: 40px 40px;
  }
}
/* Flip modifier */
.uk-offcanvas-flip .uk-offcanvas-bar {
  left: auto;
  right: -270px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-offcanvas-flip .uk-offcanvas-bar {
    right: -350px;
  }
}
/*
 * Open
 */
.uk-open > .uk-offcanvas-bar {
  left: 0;
}

.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar {
  left: auto;
  right: 0;
}

/*
 * Slide Animation (Used in slide and push mode)
 */
.uk-offcanvas-bar-animation {
  transition: left 0.3s ease-out;
}

.uk-offcanvas-flip .uk-offcanvas-bar-animation {
  transition-property: right;
}

/*
 * Reveal Animation
 * 1. Set position
 * 2. Clip the bar
 * 3. Animation
 * 4. Reset position
 */
.uk-offcanvas-reveal {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 0;
  overflow: hidden;
  /* 3 */
  transition: width 0.3s ease-out;
}

.uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: 0;
}

.uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: auto;
  right: 0;
}

.uk-open > .uk-offcanvas-reveal {
  width: 270px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-open > .uk-offcanvas-reveal {
    width: 350px;
  }
}
/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas-reveal {
  right: 0;
  left: auto;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-offcanvas-close {
  position: absolute;
  z-index: 1000;
  top: 20px;
  right: 20px;
  padding: 5px;
}

/* Overlay
 ========================================================================== */
/*
 * Overlay the whole page. Needed for the `::before`
 * 1. Using `100vw` so no modification is needed when off-canvas is flipped
 * 2. Allow for closing with swipe gesture on devices with pointer events.
 */
.uk-offcanvas-overlay {
  /* 1 */
  width: 100vw;
  /* 2 */
  touch-action: none;
}

/*
 * 1. Mask the whole page
 * 2. Fade-in transition
 */
.uk-offcanvas-overlay::before {
  /* 1 */
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.33);
  /* 2 */
  opacity: 0;
  transition: opacity 0.15s linear;
}

.uk-offcanvas-overlay.uk-open::before {
  opacity: 1;
}

/* Prevent scrolling
 ========================================================================== */
/*
 * Prevent horizontal scrollbar when the content is slide-out
 * Has to be on the `html` element too to make it work on the `body`
 */
.uk-offcanvas-page,
.uk-offcanvas-container {
  overflow-x: hidden;
}

/* Container
 ========================================================================== */
/*
 * Prepare slide-out animation (Used in reveal and push mode)
 * Using `position: left` instead of `transform` because position `fixed` elements like sticky navbars
 * lose their fixed state and behaves like `absolute` within a transformed container
 * 1. Provide a fixed width and prevent shrinking
 */
.uk-offcanvas-container {
  position: relative;
  left: 0;
  transition: left 0.3s ease-out;
  /* 1 */
  box-sizing: border-box;
  width: 100%;
}

/*
 * Activate slide-out animation
 */
:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
  left: 270px;
}

.uk-offcanvas-flip.uk-offcanvas-container-animation {
  left: -270px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  :not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
    left: 350px;
  }

  .uk-offcanvas-flip.uk-offcanvas-container-animation {
    left: -350px;
  }
}
/* ========================================================================
   Component: Grid
 ========================================================================== */
/*
 * 1. Allow cells to wrap into the next line
 * 2. Reset list
 */
.uk-grid {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
}

/*
 * Grid cell
 * Note: Space is allocated solely based on content dimensions, but shrinks: 0 1 auto
 * Reset margin for e.g. paragraphs
 */
.uk-grid > * {
  margin: 0;
}

/*
 * Remove margin from the last-child
 */
.uk-grid > * > :last-child {
  margin-bottom: 0;
}

/* Gutter
 ========================================================================== */
/*
 * Default
 */
/* Horizontal */
.uk-grid {
  margin-left: -30px;
}

.uk-grid > * {
  padding-left: 30px;
}

/* Vertical */
.uk-grid + .uk-grid,
.uk-grid > .uk-grid-margin,
* + .uk-grid-margin {
  margin-top: 30px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid {
    margin-left: -40px;
  }

  .uk-grid > * {
    padding-left: 40px;
  }

  /* Vertical */
  .uk-grid + .uk-grid,
.uk-grid > .uk-grid-margin,
* + .uk-grid-margin {
    margin-top: 40px;
  }
}
/*
 * Small
 */
/* Horizontal */
.uk-grid-small,
.uk-grid-column-small {
  margin-left: -15px;
}

.uk-grid-small > *,
.uk-grid-column-small > * {
  padding-left: 15px;
}

/* Vertical */
.uk-grid + .uk-grid-small,
.uk-grid + .uk-grid-row-small,
.uk-grid-small > .uk-grid-margin,
.uk-grid-row-small > .uk-grid-margin,
* + .uk-grid-margin-small {
  margin-top: 15px;
}

/*
 * Medium
 */
/* Horizontal */
.uk-grid-medium,
.uk-grid-column-medium {
  margin-left: -30px;
}

.uk-grid-medium > *,
.uk-grid-column-medium > * {
  padding-left: 30px;
}

/* Vertical */
.uk-grid + .uk-grid-medium,
.uk-grid + .uk-grid-row-medium,
.uk-grid-medium > .uk-grid-margin,
.uk-grid-row-medium > .uk-grid-margin,
* + .uk-grid-margin-medium {
  margin-top: 30px;
}

/*
 * Large
 */
/* Horizontal */
.uk-grid-large,
.uk-grid-column-large {
  margin-left: -40px;
}

.uk-grid-large > *,
.uk-grid-column-large > * {
  padding-left: 40px;
}

/* Vertical */
.uk-grid + .uk-grid-large,
.uk-grid + .uk-grid-row-large,
.uk-grid-large > .uk-grid-margin,
.uk-grid-row-large > .uk-grid-margin,
* + .uk-grid-margin-large {
  margin-top: 40px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-large,
.uk-grid-column-large {
    margin-left: -70px;
  }

  .uk-grid-large > *,
.uk-grid-column-large > * {
    padding-left: 70px;
  }

  /* Vertical */
  .uk-grid + .uk-grid-large,
.uk-grid + .uk-grid-row-large,
.uk-grid-large > .uk-grid-margin,
.uk-grid-row-large > .uk-grid-margin,
* + .uk-grid-margin-large {
    margin-top: 70px;
  }
}
/*
 * Collapse
 */
/* Horizontal */
.uk-grid-collapse,
.uk-grid-column-collapse {
  margin-left: 0;
}

.uk-grid-collapse > *,
.uk-grid-column-collapse > * {
  padding-left: 0;
}

/* Vertical */
.uk-grid + .uk-grid-collapse,
.uk-grid + .uk-grid-row-collapse,
.uk-grid-collapse > .uk-grid-margin,
.uk-grid-row-collapse > .uk-grid-margin {
  margin-top: 0;
}

/* Divider
 ========================================================================== */
.uk-grid-divider > * {
  position: relative;
}

.uk-grid-divider > :not(.uk-first-column)::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 1px solid #e5e5e5;
}

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border-top: 1px solid #e5e5e5;
}

/*
 * Default
 */
/* Horizontal */
.uk-grid-divider {
  margin-left: -60px;
}

.uk-grid-divider > * {
  padding-left: 60px;
}

.uk-grid-divider > :not(.uk-first-column)::before {
  left: 30px;
}

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px;
}

.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider {
    margin-left: -80px;
  }

  .uk-grid-divider > * {
    padding-left: 80px;
  }

  .uk-grid-divider > :not(.uk-first-column)::before {
    left: 40px;
  }

  /* Vertical */
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin {
    margin-top: 80px;
  }

  .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
    top: -40px;
    left: 80px;
  }
}
/*
 * Small
 */
/* Horizontal */
.uk-grid-divider.uk-grid-small,
.uk-grid-divider.uk-grid-column-small {
  margin-left: -30px;
}

.uk-grid-divider.uk-grid-small > *,
.uk-grid-divider.uk-grid-column-small > * {
  padding-left: 30px;
}

.uk-grid-divider.uk-grid-small > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-small > :not(.uk-first-column)::before {
  left: 15px;
}

/* Vertical */
.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin {
  margin-top: 30px;
}

.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin::before {
  top: -15px;
  left: 30px;
}

.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin::before {
  top: -15px;
}

.uk-grid-divider.uk-grid-column-small.uk-grid-stack > .uk-grid-margin::before {
  left: 30px;
}

/*
 * Medium
 */
/* Horizontal */
.uk-grid-divider.uk-grid-medium,
.uk-grid-divider.uk-grid-column-medium {
  margin-left: -60px;
}

.uk-grid-divider.uk-grid-medium > *,
.uk-grid-divider.uk-grid-column-medium > * {
  padding-left: 60px;
}

.uk-grid-divider.uk-grid-medium > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-medium > :not(.uk-first-column)::before {
  left: 30px;
}

/* Vertical */
.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px;
}

.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px;
}

.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
}

.uk-grid-divider.uk-grid-column-medium.uk-grid-stack > .uk-grid-margin::before {
  left: 60px;
}

/*
 * Large
 */
/* Horizontal */
.uk-grid-divider.uk-grid-large,
.uk-grid-divider.uk-grid-column-large {
  margin-left: -80px;
}

.uk-grid-divider.uk-grid-large > *,
.uk-grid-divider.uk-grid-column-large > * {
  padding-left: 80px;
}

.uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
  left: 40px;
}

/* Vertical */
.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
  margin-top: 80px;
}

.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
  top: -40px;
  left: 80px;
}

.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
  top: -40px;
}

.uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
  left: 80px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider.uk-grid-large,
.uk-grid-divider.uk-grid-column-large {
    margin-left: -140px;
  }

  .uk-grid-divider.uk-grid-large > *,
.uk-grid-divider.uk-grid-column-large > * {
    padding-left: 140px;
  }

  .uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
    left: 70px;
  }

  /* Vertical */
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
    margin-top: 140px;
  }

  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
    top: -70px;
    left: 140px;
  }

  .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
    top: -70px;
  }

  .uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
    left: 140px;
  }
}
/* Match child of a grid cell
 ========================================================================== */
/*
 * Behave like a block element
 * 1. Wrap into the next line
 * 2. Take the full width, at least 100%. Only if no class from the Width component is set.
 * 3. Expand width even if larger than 100%, e.g. because of negative margin (Needed for nested grids)
 */
.uk-grid-match > *,
.uk-grid-item-match {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
}

.uk-grid-match > * > :not([class*=uk-width]),
.uk-grid-item-match > :not([class*=uk-width]) {
  /* 2 */
  box-sizing: border-box;
  width: 100%;
  /* 3 */
  flex: auto;
}

/* ========================================================================
   Component: Nav
 ========================================================================== */
/*
 * Reset
 */
.uk-nav,
.uk-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*
* 1. Center content vertically, e.g. an icon
* 2. Imitate white space gap when using flexbox
* 3. Reset link
* 4. Space is allocated solely based on content dimensions: 0 0 auto
 */
.uk-nav li > a {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3*/
  text-decoration: none;
}

/* 4 */
.uk-nav li > a > * {
  flex: none;
}

/*
 * Items
 * Must target `a` elements to exclude other elements (e.g. lists)
 */
.uk-nav > li > a {
  padding: 5px 0;
}

/* Sublists
 ========================================================================== */
/*
 * Level 2
 * `ul` needed for higher specificity to override padding
 */
ul.uk-nav-sub {
  padding: 5px 0 5px 15px;
}

/*
 * Level 3 and deeper
 */
.uk-nav-sub ul {
  padding-left: 15px;
}

/*
 * Items
 */
.uk-nav-sub a {
  padding: 2px 0;
}

/* Parent icon modifier
 ========================================================================== */
.uk-nav-parent-icon > .uk-parent > a::after {
  content: "";
  width: 1.5em;
  height: 1.5em;
  margin-left: auto;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23325685%22%20stroke-width%3D%221.1%22%20points%3D%2210%201%204%207%2010%2013%22%20%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.uk-nav-parent-icon > .uk-parent.uk-open > a::after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23325685%22%20stroke-width%3D%221.1%22%20points%3D%221%204%207%2010%2013%204%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

/* Header
 ========================================================================== */
.uk-nav-header {
  padding: 5px 0;
  text-transform: uppercase;
  font-size: 0.875rem;
}

.uk-nav-header:not(:first-child) {
  margin-top: 20px;
}

/* Divider
 ========================================================================== */
.uk-nav > .uk-nav-divider {
  margin: 5px 0;
}

/* Default modifier
 ========================================================================== */
/*
 * Items
 */
.uk-nav-default > li > a {
  color: #999;
}

/* Hover */
.uk-nav-default > li > a:hover {
  color: #325685;
}

/* Active */
.uk-nav-default > li.uk-active > a {
  color: #333;
}

/*
 * Header
 */
.uk-nav-default .uk-nav-header {
  color: #333;
}

/*
 * Divider
 */
.uk-nav-default .uk-nav-divider {
  border-top: 1px solid #e5e5e5;
}

/*
 * Sublists
 */
.uk-nav-default .uk-nav-sub a {
  color: #999;
}

.uk-nav-default .uk-nav-sub a:hover {
  color: #325685;
}

.uk-nav-default .uk-nav-sub li.uk-active > a {
  color: #333;
}

/* Primary modifier
 ========================================================================== */
/*
 * Items
 */
.uk-nav-primary > li > a {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #999;
}

/* Hover */
.uk-nav-primary > li > a:hover {
  color: #325685;
}

/* Active */
.uk-nav-primary > li.uk-active > a {
  color: #333;
}

/*
 * Header
 */
.uk-nav-primary .uk-nav-header {
  color: #333;
}

/*
 * Divider
 */
.uk-nav-primary .uk-nav-divider {
  border-top: 1px solid #e5e5e5;
}

/*
 * Sublists
 */
.uk-nav-primary .uk-nav-sub a {
  color: #999;
}

.uk-nav-primary .uk-nav-sub a:hover {
  color: #325685;
}

.uk-nav-primary .uk-nav-sub li.uk-active > a {
  color: #333;
}

/* Alignment modifier
 ========================================================================== */
/*
 * 1. Center header
 * 2. Center items
 */
/* 1 */
.uk-nav-center {
  text-align: center;
}

/* 2 */
.uk-nav-center li > a {
  justify-content: center;
}

/* Sublists */
.uk-nav-center .uk-nav-sub,
.uk-nav-center .uk-nav-sub ul {
  padding-left: 0;
}

/* Parent icon modifier  */
.uk-nav-center.uk-nav-parent-icon > .uk-parent > a::after {
  margin-left: 0;
}

/* Style modifier
 ========================================================================== */
.uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider) {
  margin-top: 0;
  padding-top: 0;
  border-top: 1px solid #e5e5e5;
}

/* ========================================================================
   Component: Navbar
 ========================================================================== */
/*
 * 1. Create position context to center navbar group
 */
.uk-navbar {
  display: flex;
  /* 1 */
  position: relative;
}

/* Container
 ========================================================================== */
.uk-navbar-container:not(.uk-navbar-transparent) {
  background: #f8f8f8;
}

/*
 * Remove pseudo elements created by micro clearfix as precaution (if Container component is used)
 */
.uk-navbar-container > ::before,
.uk-navbar-container > ::after {
  display: none !important;
}

/* Groups
 ========================================================================== */
/*
 * 1. Align navs and items vertically if they have a different height
 * 2. Note: IE 11 requires an extra `div` which affects the center selector
 */
.uk-navbar-left,
.uk-navbar-right,
.uk-navbar-center,
.uk-navbar-center-left > *,
.uk-navbar-center-right > * {
  display: flex;
  /* 1 */
  align-items: center;
}

/*
 * Horizontal alignment
 * 1. Create position context for centered navbar with sub groups (left/right)
 * 2. Fix text wrapping if content is larger than 50% of the container.
 * 3. Needed for dropdowns because a new position context is created
 *    `z-index` must be smaller than off-canvas
 * 4. Align sub groups for centered navbar
 */
.uk-navbar-right {
  margin-left: auto;
}

.uk-navbar-center:only-child {
  margin-left: auto;
  margin-right: auto;
  /* 1 */
  position: relative;
}

.uk-navbar-center:not(:only-child) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 2 */
  width: max-content;
  box-sizing: border-box;
  /* 3 */
  z-index: 990;
}

/* 4 */
.uk-navbar-center-left,
.uk-navbar-center-right {
  position: absolute;
  top: 0;
}

.uk-navbar-center-left {
  right: 100%;
}

.uk-navbar-center-right {
  left: 100%;
}

[class*=uk-navbar-center-] {
  width: max-content;
  box-sizing: border-box;
}

/* Nav
 ========================================================================== */
/*
 * 1. Reset list
 */
.uk-navbar-nav {
  display: flex;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none;
}

/*
 * Allow items to wrap into the next line
 * Only not `absolute` positioned groups
 */
.uk-navbar-left,
.uk-navbar-right,
.uk-navbar-center:only-child {
  flex-wrap: wrap;
}

/*
 * Items
 * 1. Center content vertically and horizontally
 * 2. Imitate white space gap when using flexbox
 * 3. Dimensions
 * 4. Style
 * 5. Required for `a`
 */
.uk-navbar-nav > li > a,
.uk-navbar-item,
.uk-navbar-toggle {
  /* 1 */
  display: flex;
  justify-content: center;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  box-sizing: border-box;
  min-height: 40px;
  padding: 0 15px;
  /* 4 */
  font-size: 16px;
  font-family: "canada-type-gibson", serif;
  /* 5 */
  text-decoration: none;
}

/*
 * Nav items
 */
.uk-navbar-nav > li > a {
  color: #999;
}

/*
 * Hover
 * Apply hover style also if dropdown is opened
 */
.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a[aria-expanded=true] {
  color: #325685;
}

/* OnClick */
.uk-navbar-nav > li > a:active {
  color: #333;
}

/* Active */
.uk-navbar-nav > li.uk-active > a {
  color: #333;
}

/* Item
 ========================================================================== */
.uk-navbar-item {
  color: #325685;
}

/*
 * Remove margin from the last-child
 */
.uk-navbar-item > :last-child {
  margin-bottom: 0;
}

/* Toggle
 ========================================================================== */
.uk-navbar-toggle {
  color: #999;
}

.uk-navbar-toggle:hover,
.uk-navbar-toggle[aria-expanded=true] {
  color: #325685;
  text-decoration: none;
}

/*
 * Icon
 * Adopts `uk-icon`
 */
/* Hover */
/* Subtitle
 ========================================================================== */
.uk-navbar-subtitle {
  font-size: 0.875rem;
}

/* Style modifiers
 ========================================================================== */
/* Dropdown
 ========================================================================== */
/*
 * Adopts `uk-dropdown`
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 * 4. Style
 */
.uk-navbar-dropdown {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  /* 3 */
  box-sizing: border-box;
  width: 200px;
  /* 4 */
  padding: 15px;
  background: #f8f8f8;
  color: #325685;
}

/* Show */
.uk-navbar-dropdown.uk-open {
  display: block;
}

/*
 * Direction / Alignment modifiers
 */
/* Direction */
[class*=uk-navbar-dropdown-top] {
  margin-top: 0;
}

[class*=uk-navbar-dropdown-bottom] {
  margin-top: 0;
}

[class*=uk-navbar-dropdown-left] {
  margin-left: 0;
}

[class*=uk-navbar-dropdown-right] {
  margin-left: 0;
}

/*
 * Grid
 * Adopts `uk-grid`
 */
/* Gutter Horizontal */
.uk-navbar-dropdown-grid {
  margin-left: -30px;
}

.uk-navbar-dropdown-grid > * {
  padding-left: 30px;
}

/* Gutter Vertical */
.uk-navbar-dropdown-grid > .uk-grid-margin {
  margin-top: 30px;
}

/* Stack */
.uk-navbar-dropdown-stack .uk-navbar-dropdown-grid > * {
  width: 100% !important;
}

/*
 * Width modifier
 */
.uk-navbar-dropdown-width-2:not(.uk-navbar-dropdown-stack) {
  width: 400px;
}

.uk-navbar-dropdown-width-3:not(.uk-navbar-dropdown-stack) {
  width: 600px;
}

.uk-navbar-dropdown-width-4:not(.uk-navbar-dropdown-stack) {
  width: 800px;
}

.uk-navbar-dropdown-width-5:not(.uk-navbar-dropdown-stack) {
  width: 1000px;
}

/*
 * Dropbar modifier
 */
.uk-navbar-dropdown-dropbar {
  margin-top: 0;
  margin-bottom: 0;
}

/* Dropdown Nav
 * Adopts `uk-nav`
 ========================================================================== */
/*
 * Items
 */
.uk-navbar-dropdown-nav > li > a {
  color: #999;
}

/* Hover */
.uk-navbar-dropdown-nav > li > a:hover {
  color: #325685;
}

/* Active */
.uk-navbar-dropdown-nav > li.uk-active > a {
  color: #333;
}

/*
 * Header
 */
.uk-navbar-dropdown-nav .uk-nav-header {
  color: #333;
}

/*
 * Divider
 */
.uk-navbar-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #e5e5e5;
}

/*
 * Sublists
 */
.uk-navbar-dropdown-nav .uk-nav-sub a {
  color: #999;
}

.uk-navbar-dropdown-nav .uk-nav-sub a:hover {
  color: #325685;
}

.uk-navbar-dropdown-nav .uk-nav-sub li.uk-active > a {
  color: #333;
}

/* Dropbar
 ========================================================================== */
.uk-navbar-dropbar {
  background: #f8f8f8;
}

/*
 * Slide modifier
 */
.uk-navbar-dropbar-slide {
  position: absolute;
  z-index: 980;
  left: 0;
  right: 0;
}

/* ========================================================================
   Component: Subnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Center items vertically if they have a different height
 * 3. Gutter
 * 4. Reset list
 */
.uk-subnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  align-items: center;
  /* 3 */
  margin-left: -20px;
  /* 4 */
  padding: 0;
  list-style: none;
}

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-subnav > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  position: relative;
}

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * Using `:first-child` instead of `a` to support `span` elements for text
 * 1. Center content vertically, e.g. an icon
 * 2. Imitate white space gap when using flexbox
 * 3. Style
 */
.uk-subnav > * > :first-child {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  color: #999;
}

/* Hover */
.uk-subnav > * > a:hover {
  color: #325685;
  text-decoration: none;
}

/* Active */
.uk-subnav > .uk-active > a {
  color: #333;
}

/* Divider modifier
 ========================================================================== */
/*
 * Set gutter
 */
.uk-subnav-divider {
  margin-left: -41px;
}

/*
 * Align items and divider vertically
 */
.uk-subnav-divider > * {
  display: flex;
  align-items: center;
}

/*
 * Divider
 * 1. `nth-child` makes it also work without JS if it's only one row
 */
.uk-subnav-divider > ::before {
  content: "";
  height: 1.5em;
  margin-left: 0px;
  margin-right: 20px;
  border-left: 1px solid transparent;
}

/* 1 */
.uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before {
  border-left-color: #e5e5e5;
}

/* Pill modifier
 ========================================================================== */
.uk-subnav-pill > * > :first-child {
  padding: 5px 10px;
  background: transparent;
  color: #999;
}

/* Hover */
.uk-subnav-pill > * > a:hover {
  background-color: #f8f8f8;
  color: #325685;
}

/* OnClick */
.uk-subnav-pill > * > a:active {
  background-color: #f8f8f8;
  color: #325685;
}

/* Active */
.uk-subnav-pill > .uk-active > a {
  background-color: #325685;
  color: #ffffff;
}

/* Disabled
 * The same for all style modifiers
 ========================================================================== */
.uk-subnav > .uk-disabled > a {
  color: #999;
}

/* ========================================================================
   Component: Breadcrumb
 ========================================================================== */
/*
 * Reset list
 */
.uk-breadcrumb {
  padding: 0;
  list-style: none;
}

/*
 * 1. Doesn't generate any box and replaced by child boxes
 */
.uk-breadcrumb > * {
  display: contents;
}

/* Items
 ========================================================================== */
.uk-breadcrumb > * > * {
  font-size: 0.875rem;
  color: #999;
}

/* Hover */
.uk-breadcrumb > * > :hover {
  color: #325685;
  text-decoration: none;
}

/* Disabled */
/* Active */
.uk-breadcrumb > :last-child > span,
.uk-breadcrumb > :last-child > a:not([href]) {
  color: #325685;
}

/*
 * Divider
 * `nth-child` makes it also work without JS if it's only one row
 * 1. Remove space between inline block elements.
 * 2. Style
 */
.uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
  content: "/";
  display: inline-block;
  /* 1 */
  margin: 0 20px 0 calc(20px - 4px);
  /* 2 */
  font-size: 0.875rem;
  color: #999;
}

/* ========================================================================
   Component: Pagination
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Gutter
 * 3. Reset list
 */
.uk-pagination {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin-left: 0;
  /* 3 */
  padding: 0;
  list-style: none;
}

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-pagination > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 0;
  /* 3 */
  position: relative;
}

/* Items
 ========================================================================== */
/*
 * 1. Prevent gap if child element is `inline-block`, e.g. an icon
 * 2. Style
 */
.uk-pagination > * > * {
  /* 1 */
  display: block;
  /* 2 */
  padding: 5px 10px;
  color: #999;
}

/* Hover */
.uk-pagination > * > :hover {
  color: #325685;
  text-decoration: none;
}

/* Active */
.uk-pagination > .uk-active > * {
  color: #325685;
}

/* Disabled */
.uk-pagination > .uk-disabled > * {
  color: #999;
}

/* ========================================================================
   Component: Tab
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Gutter
 * 3. Reset list
 */
.uk-tab {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin-left: -20px;
  /* 3 */
  padding: 0;
  list-style: none;
}

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-tab > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  position: relative;
}

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * 1. Center content vertically, e.g. an icon
 * 2. Imitate white space gap when using flexbox
 * 3. Center content if a width is set
 * 4. Style
 */
.uk-tab > * > a {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  justify-content: center;
  /* 4 */
  padding: 5px 10px;
  color: #999;
}

/* Hover */
.uk-tab > * > a:hover {
  color: #325685;
  text-decoration: none;
}

/* Active */
.uk-tab > .uk-active > a {
  color: #333;
}

/* Disabled */
.uk-tab > .uk-disabled > a {
  color: #999;
}

/* Position modifier
 ========================================================================== */
/*
 * Bottom
 */
/*
 * Left + Right
 * 1. Reset Gutter
 */
.uk-tab-left,
.uk-tab-right {
  flex-direction: column;
  /* 1 */
  margin-left: 0;
}

/* 1 */
.uk-tab-left > *,
.uk-tab-right > * {
  padding-left: 0;
}

.uk-tab-left > * > a {
  justify-content: left;
}

.uk-tab-right > * > a {
  justify-content: left;
}

/* ========================================================================
   Component: Lightbox
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 * 7. Prevent cancellation of pointer events while dragging
 */
.uk-lightbox {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 5 */
  background: #000;
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
  /* 7 */
  touch-action: pinch-zoom;
}

/*
 * Open
 * 1. Center child
 * 2. Fade-in
 */
.uk-lightbox.uk-open {
  display: block;
  /* 2 */
  opacity: 1;
}

/*
 * Focus
 */
.uk-lightbox :focus {
  outline-color: rgba(255, 255, 255, 0.7);
}

.uk-lightbox :focus-visible {
  outline-color: rgba(255, 255, 255, 0.7);
}

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-lightbox-page {
  overflow: hidden;
}

/* Item
 ========================================================================== */
/*
 * 1. Center child within the viewport
 * 2. Not visible by default
 * 3. Color needed for spinner icon
 * 4. Optimize animation
 * 5. Responsiveness
 *    Using `vh` for `max-height` to fix image proportions after resize in Safari and Opera
 *    Using `vh` and `vw` to make responsive image work in IE11
 */
.uk-lightbox-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: none;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: rgba(255, 255, 255, 0.7);
  /* 4 */
  will-change: transform, opacity;
}

/* 5 */
.uk-lightbox-items > * > * {
  max-width: 100vw;
  max-height: 100vh;
}

.uk-lightbox-items > * > :not(iframe) {
  width: auto;
  height: auto;
}

.uk-lightbox-items > .uk-active {
  display: flex;
}

/* Toolbar
 ========================================================================== */
.uk-lightbox-toolbar {
  padding: 10px 10px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
}

.uk-lightbox-toolbar > * {
  color: rgba(255, 255, 255, 0.7);
}

/* Toolbar Icon (Close)
 ========================================================================== */
.uk-lightbox-toolbar-icon {
  padding: 5px;
  color: rgba(255, 255, 255, 0.7);
}

/*
 * Hover
 */
.uk-lightbox-toolbar-icon:hover {
  color: #fff;
}

/* Button (Slidenav)
 ========================================================================== */
/*
 * 1. Center icon vertically and horizontally
 */
.uk-lightbox-button {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* Hover */
.uk-lightbox-button:hover {
  color: #fff;
}

/* OnClick */
/* Caption
 ========================================================================== */
.uk-lightbox-caption:empty {
  display: none;
}

/* Iframe
 ========================================================================== */
.uk-lightbox-iframe {
  width: 80%;
  height: 80%;
}

/* ========================================================================
   Component: Animation
 ========================================================================== */
[class*=uk-animation-] {
  animation: 0.5s ease-out both;
}

/* Animations
 ========================================================================== */
/*
 * Fade
 */
.uk-animation-fade {
  animation-name: uk-fade;
  animation-duration: 0.8s;
  animation-timing-function: linear;
}

/*
 * Scale
 */
.uk-animation-scale-up {
  animation-name: uk-fade, uk-scale-up;
}

.uk-animation-scale-down {
  animation-name: uk-fade, uk-scale-down;
}

/*
 * Slide
 */
.uk-animation-slide-top {
  animation-name: uk-fade, uk-slide-top;
}

.uk-animation-slide-bottom {
  animation-name: uk-fade, uk-slide-bottom;
}

.uk-animation-slide-left {
  animation-name: uk-fade, uk-slide-left;
}

.uk-animation-slide-right {
  animation-name: uk-fade, uk-slide-right;
}

/*
 * Slide Small
 */
.uk-animation-slide-top-small {
  animation-name: uk-fade, uk-slide-top-small;
}

.uk-animation-slide-bottom-small {
  animation-name: uk-fade, uk-slide-bottom-small;
}

.uk-animation-slide-left-small {
  animation-name: uk-fade, uk-slide-left-small;
}

.uk-animation-slide-right-small {
  animation-name: uk-fade, uk-slide-right-small;
}

/*
 * Slide Medium
 */
.uk-animation-slide-top-medium {
  animation-name: uk-fade, uk-slide-top-medium;
}

.uk-animation-slide-bottom-medium {
  animation-name: uk-fade, uk-slide-bottom-medium;
}

.uk-animation-slide-left-medium {
  animation-name: uk-fade, uk-slide-left-medium;
}

.uk-animation-slide-right-medium {
  animation-name: uk-fade, uk-slide-right-medium;
}

/*
 * Kenburns
 */
.uk-animation-kenburns {
  animation-name: uk-kenburns;
  animation-duration: 15s;
}

/*
 * Shake
 */
.uk-animation-shake {
  animation-name: uk-shake;
}

/*
 * SVG Stroke
 * The `--uk-animation-stroke` custom property contains the longest path length.
 * Set it manually or use `uk-svg="stroke-animation: true"` to set it automatically.
 * All strokes are animated by the same pace and doesn't end simultaneously.
 * To end simultaneously, `pathLength="1"` could be used, but it's not working in Safari yet.
 */
.uk-animation-stroke {
  animation-name: uk-stroke;
  animation-duration: 2s;
  stroke-dasharray: var(--uk-animation-stroke);
}

/* Direction modifier
 ========================================================================== */
.uk-animation-reverse {
  animation-direction: reverse;
  animation-timing-function: ease-in;
}

/* Duration modifier
 ========================================================================== */
.uk-animation-fast {
  animation-duration: 0.1s;
}

/* Toggle animation based on the State of the Parent Element
 ========================================================================== */
.uk-animation-toggle:not(:hover):not(:focus) [class*=uk-animation-] {
  animation-name: none;
}

/* Keyframes used by animation classes
 ========================================================================== */
/*
 * Fade
 */
@keyframes uk-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*
 * Scale
 */
@keyframes uk-scale-up {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes uk-scale-down {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
/*
 * Slide
 */
@keyframes uk-slide-top {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-bottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes uk-slide-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
/*
 * Slide Small
 */
@keyframes uk-slide-top-small {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-bottom-small {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-left-small {
  0% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes uk-slide-right-small {
  0% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
/*
 * Slide Medium
 */
@keyframes uk-slide-top-medium {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-bottom-medium {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-left-medium {
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes uk-slide-right-medium {
  0% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }
}
/*
 * Kenburns
 */
@keyframes uk-kenburns {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
/*
 * Shake
 */
@keyframes uk-shake {
  0%, 100% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-9px);
  }
  20% {
    transform: translateX(8px);
  }
  30% {
    transform: translateX(-7px);
  }
  40% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(4px);
  }
  70% {
    transform: translateX(-3px);
  }
  80% {
    transform: translateX(2px);
  }
  90% {
    transform: translateX(-1px);
  }
}
/*
 * Stroke
 */
@keyframes uk-stroke {
  0% {
    stroke-dashoffset: var(--uk-animation-stroke);
  }
  100% {
    stroke-dashoffset: 0;
  }
}
/* ========================================================================
   Component: Width
 ========================================================================== */
/* Equal child widths
 ========================================================================== */
[class*=uk-child-width] > * {
  box-sizing: border-box;
  width: 100%;
}

.uk-child-width-1-2 > * {
  width: 50%;
}

.uk-child-width-1-3 > * {
  width: calc(100% * 1 / 3.001);
}

.uk-child-width-1-4 > * {
  width: 25%;
}

.uk-child-width-1-5 > * {
  width: 20%;
}

.uk-child-width-1-6 > * {
  width: calc(100% * 1 / 6.001);
}

.uk-child-width-auto > * {
  width: auto;
}

/*
 * 1. Reset the `min-width`, which is set to auto by default, because
 *    flex items won't shrink below their minimum intrinsic content size.
 *    Using `1px` instead of `0`, so items still wrap into the next line,
 *    if they have zero width and padding and the predecessor is 100% wide.
 */
.uk-child-width-expand > :not([class*=uk-width]) {
  flex: 1;
  /* 1 */
  min-width: 1px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-child-width-1-1\@s > * {
    width: 100%;
  }

  .uk-child-width-1-2\@s > * {
    width: 50%;
  }

  .uk-child-width-1-3\@s > * {
    width: calc(100% * 1 / 3.001);
  }

  .uk-child-width-1-4\@s > * {
    width: 25%;
  }

  .uk-child-width-1-5\@s > * {
    width: 20%;
  }

  .uk-child-width-1-6\@s > * {
    width: calc(100% * 1 / 6.001);
  }

  .uk-child-width-auto\@s > * {
    width: auto;
  }

  .uk-child-width-expand\@s > :not([class*=uk-width]) {
    flex: 1;
    min-width: 1px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-child-width-1-1\@m > * {
    width: 100%;
  }

  .uk-child-width-1-2\@m > * {
    width: 50%;
  }

  .uk-child-width-1-3\@m > * {
    width: calc(100% * 1 / 3.001);
  }

  .uk-child-width-1-4\@m > * {
    width: 25%;
  }

  .uk-child-width-1-5\@m > * {
    width: 20%;
  }

  .uk-child-width-1-6\@m > * {
    width: calc(100% * 1 / 6.001);
  }

  .uk-child-width-auto\@m > * {
    width: auto;
  }

  .uk-child-width-expand\@m > :not([class*=uk-width]) {
    flex: 1;
    min-width: 1px;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-child-width-1-1\@l > * {
    width: 100%;
  }

  .uk-child-width-1-2\@l > * {
    width: 50%;
  }

  .uk-child-width-1-3\@l > * {
    width: calc(100% * 1 / 3.001);
  }

  .uk-child-width-1-4\@l > * {
    width: 25%;
  }

  .uk-child-width-1-5\@l > * {
    width: 20%;
  }

  .uk-child-width-1-6\@l > * {
    width: calc(100% * 1 / 6.001);
  }

  .uk-child-width-auto\@l > * {
    width: auto;
  }

  .uk-child-width-expand\@l > :not([class*=uk-width]) {
    flex: 1;
    min-width: 1px;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-child-width-1-1\@xl > * {
    width: 100%;
  }

  .uk-child-width-1-2\@xl > * {
    width: 50%;
  }

  .uk-child-width-1-3\@xl > * {
    width: calc(100% * 1 / 3.001);
  }

  .uk-child-width-1-4\@xl > * {
    width: 25%;
  }

  .uk-child-width-1-5\@xl > * {
    width: 20%;
  }

  .uk-child-width-1-6\@xl > * {
    width: calc(100% * 1 / 6.001);
  }

  .uk-child-width-auto\@xl > * {
    width: auto;
  }

  .uk-child-width-expand\@xl > :not([class*=uk-width]) {
    flex: 1;
    min-width: 1px;
  }
}
/* Single Widths
 ========================================================================== */
/*
 * 1. `max-width` is needed for the pixel-based classes
 */
[class*=uk-width] {
  box-sizing: border-box;
  width: 100%;
  /* 1 */
  max-width: 100%;
}

/* Halves */
.uk-width-1-2 {
  width: 50%;
}

/* Thirds */
.uk-width-1-3 {
  width: calc(100% * 1 / 3.001);
}

.uk-width-2-3 {
  width: calc(100% * 2 / 3.001);
}

/* Quarters */
.uk-width-1-4 {
  width: 25%;
}

.uk-width-3-4 {
  width: 75%;
}

/* Fifths */
.uk-width-1-5 {
  width: 20%;
}

.uk-width-2-5 {
  width: 40%;
}

.uk-width-3-5 {
  width: 60%;
}

.uk-width-4-5 {
  width: 80%;
}

/* Sixths */
.uk-width-1-6 {
  width: calc(100% * 1 / 6.001);
}

.uk-width-5-6 {
  width: calc(100% * 5 / 6.001);
}

/* Pixel */
.uk-width-small {
  width: 150px;
}

.uk-width-medium {
  width: 300px;
}

.uk-width-large {
  width: 450px;
}

.uk-width-xlarge {
  width: 600px;
}

.uk-width-2xlarge {
  width: 750px;
}

/* Auto */
.uk-width-auto {
  width: auto;
}

/* Expand */
.uk-width-expand {
  flex: 1;
  min-width: 1px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  /* Whole */
  .uk-width-1-1\@s {
    width: 100%;
  }

  /* Halves */
  .uk-width-1-2\@s {
    width: 50%;
  }

  /* Thirds */
  .uk-width-1-3\@s {
    width: calc(100% * 1 / 3.001);
  }

  .uk-width-2-3\@s {
    width: calc(100% * 2 / 3.001);
  }

  /* Quarters */
  .uk-width-1-4\@s {
    width: 25%;
  }

  .uk-width-3-4\@s {
    width: 75%;
  }

  /* Fifths */
  .uk-width-1-5\@s {
    width: 20%;
  }

  .uk-width-2-5\@s {
    width: 40%;
  }

  .uk-width-3-5\@s {
    width: 60%;
  }

  .uk-width-4-5\@s {
    width: 80%;
  }

  /* Sixths */
  .uk-width-1-6\@s {
    width: calc(100% * 1 / 6.001);
  }

  .uk-width-5-6\@s {
    width: calc(100% * 5 / 6.001);
  }

  /* Pixel */
  .uk-width-small\@s {
    width: 150px;
  }

  .uk-width-medium\@s {
    width: 300px;
  }

  .uk-width-large\@s {
    width: 450px;
  }

  .uk-width-xlarge\@s {
    width: 600px;
  }

  .uk-width-2xlarge\@s {
    width: 750px;
  }

  /* Auto */
  .uk-width-auto\@s {
    width: auto;
  }

  /* Expand */
  .uk-width-expand\@s {
    flex: 1;
    min-width: 1px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* Whole */
  .uk-width-1-1\@m {
    width: 100%;
  }

  /* Halves */
  .uk-width-1-2\@m {
    width: 50%;
  }

  /* Thirds */
  .uk-width-1-3\@m {
    width: calc(100% * 1 / 3.001);
  }

  .uk-width-2-3\@m {
    width: calc(100% * 2 / 3.001);
  }

  /* Quarters */
  .uk-width-1-4\@m {
    width: 25%;
  }

  .uk-width-3-4\@m {
    width: 75%;
  }

  /* Fifths */
  .uk-width-1-5\@m {
    width: 20%;
  }

  .uk-width-2-5\@m {
    width: 40%;
  }

  .uk-width-3-5\@m {
    width: 60%;
  }

  .uk-width-4-5\@m {
    width: 80%;
  }

  /* Sixths */
  .uk-width-1-6\@m {
    width: calc(100% * 1 / 6.001);
  }

  .uk-width-5-6\@m {
    width: calc(100% * 5 / 6.001);
  }

  /* Pixel */
  .uk-width-small\@m {
    width: 150px;
  }

  .uk-width-medium\@m {
    width: 300px;
  }

  .uk-width-large\@m {
    width: 450px;
  }

  .uk-width-xlarge\@m {
    width: 600px;
  }

  .uk-width-2xlarge\@m {
    width: 750px;
  }

  /* Auto */
  .uk-width-auto\@m {
    width: auto;
  }

  /* Expand */
  .uk-width-expand\@m {
    flex: 1;
    min-width: 1px;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Whole */
  .uk-width-1-1\@l {
    width: 100%;
  }

  /* Halves */
  .uk-width-1-2\@l {
    width: 50%;
  }

  /* Thirds */
  .uk-width-1-3\@l {
    width: calc(100% * 1 / 3.001);
  }

  .uk-width-2-3\@l {
    width: calc(100% * 2 / 3.001);
  }

  /* Quarters */
  .uk-width-1-4\@l {
    width: 25%;
  }

  .uk-width-3-4\@l {
    width: 75%;
  }

  /* Fifths */
  .uk-width-1-5\@l {
    width: 20%;
  }

  .uk-width-2-5\@l {
    width: 40%;
  }

  .uk-width-3-5\@l {
    width: 60%;
  }

  .uk-width-4-5\@l {
    width: 80%;
  }

  /* Sixths */
  .uk-width-1-6\@l {
    width: calc(100% * 1 / 6.001);
  }

  .uk-width-5-6\@l {
    width: calc(100% * 5 / 6.001);
  }

  /* Pixel */
  .uk-width-small\@l {
    width: 150px;
  }

  .uk-width-medium\@l {
    width: 300px;
  }

  .uk-width-large\@l {
    width: 450px;
  }

  .uk-width-xlarge\@l {
    width: 600px;
  }

  .uk-width-2xlarge\@l {
    width: 750px;
  }

  /* Auto */
  .uk-width-auto\@l {
    width: auto;
  }

  /* Expand */
  .uk-width-expand\@l {
    flex: 1;
    min-width: 1px;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  /* Whole */
  .uk-width-1-1\@xl {
    width: 100%;
  }

  /* Halves */
  .uk-width-1-2\@xl {
    width: 50%;
  }

  /* Thirds */
  .uk-width-1-3\@xl {
    width: calc(100% * 1 / 3.001);
  }

  .uk-width-2-3\@xl {
    width: calc(100% * 2 / 3.001);
  }

  /* Quarters */
  .uk-width-1-4\@xl {
    width: 25%;
  }

  .uk-width-3-4\@xl {
    width: 75%;
  }

  /* Fifths */
  .uk-width-1-5\@xl {
    width: 20%;
  }

  .uk-width-2-5\@xl {
    width: 40%;
  }

  .uk-width-3-5\@xl {
    width: 60%;
  }

  .uk-width-4-5\@xl {
    width: 80%;
  }

  /* Sixths */
  .uk-width-1-6\@xl {
    width: calc(100% * 1 / 6.001);
  }

  .uk-width-5-6\@xl {
    width: calc(100% * 5 / 6.001);
  }

  /* Pixel */
  .uk-width-small\@xl {
    width: 150px;
  }

  .uk-width-medium\@xl {
    width: 300px;
  }

  .uk-width-large\@xl {
    width: 450px;
  }

  .uk-width-xlarge\@xl {
    width: 600px;
  }

  .uk-width-2xlarge\@xl {
    width: 750px;
  }

  /* Auto */
  .uk-width-auto\@xl {
    width: auto;
  }

  /* Expand */
  .uk-width-expand\@xl {
    flex: 1;
    min-width: 1px;
  }
}
/* Intrinsic Widths
 ========================================================================== */
.uk-width-max-content {
  width: max-content;
}

.uk-width-min-content {
  width: min-content;
}

/* ========================================================================
   Component: Height
 ========================================================================== */
[class*=uk-height] {
  box-sizing: border-box;
}

/*
 * Only works if parent element has a height set
 */
.uk-height-1-1 {
  height: 100%;
}

/*
 * Useful to create image teasers
 */
.uk-height-viewport {
  min-height: 100vh;
}

/*
 * Pixel
 * Useful for `overflow: auto`
 */
.uk-height-small {
  height: 150px;
}

.uk-height-medium {
  height: 300px;
}

.uk-height-large {
  height: 450px;
}

.uk-height-max-small {
  max-height: 150px;
}

.uk-height-max-medium {
  max-height: 300px;
}

.uk-height-max-large {
  max-height: 450px;
}

/* ========================================================================
   Component: Text
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
.uk-text-lead {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #333;
}

.uk-text-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999;
}

/* Size modifiers
 ========================================================================== */
.uk-text-small {
  font-size: 0.875rem;
  line-height: 1.5;
}

.uk-text-large {
  font-size: 1.5rem;
  line-height: 1.5;
}

.uk-text-default {
  font-size: 16px;
  line-height: 1.5;
}

/* Weight modifier
 ========================================================================== */
.uk-text-light {
  font-weight: 300;
}

.uk-text-normal {
  font-weight: 400;
}

.uk-text-bold {
  font-weight: 700;
}

.uk-text-lighter {
  font-weight: lighter;
}

.uk-text-bolder {
  font-weight: bolder;
}

/* Style modifier
 ========================================================================== */
.uk-text-italic {
  font-style: italic;
}

/* Transform modifier
 ========================================================================== */
.uk-text-capitalize {
  text-transform: capitalize !important;
}

.uk-text-uppercase {
  text-transform: uppercase !important;
}

.uk-text-lowercase {
  text-transform: lowercase !important;
}

/* Decoration modifier
 ========================================================================== */
.uk-text-decoration-none {
  text-decoration: none !important;
}

/* Color modifiers
 ========================================================================== */
.uk-text-muted {
  color: #999 !important;
}

.uk-text-emphasis {
  color: #333 !important;
}

.uk-text-primary {
  color: #325685 !important;
}

.uk-text-secondary {
  color: #92b3d7 !important;
}

.uk-text-success {
  color: #32d296 !important;
}

.uk-text-warning {
  color: #faa05a !important;
}

.uk-text-danger {
  color: #f0506e !important;
}

/* Background modifier
 ========================================================================== */
/*
 * 1. The background clips to the foreground text. Works in Chrome, Firefox, Safari, Edge and Opera
 *    Default color is set to transparent
 * 2. Container fits the text
 * 3. Fallback color for IE11
 */
.uk-text-background {
  /* 1 */
  -webkit-background-clip: text;
  /* 2 */
  display: inline-block;
  /* 3 */
  color: #325685 !important;
}

@supports (-webkit-background-clip: text) {
  .uk-text-background {
    background-color: #325685;
    color: transparent !important;
  }
}
/* Alignment modifiers
 ========================================================================== */
.uk-text-left {
  text-align: left !important;
}

.uk-text-right {
  text-align: right !important;
}

.uk-text-center {
  text-align: center !important;
}

.uk-text-justify {
  text-align: justify !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-text-left\@s {
    text-align: left !important;
  }

  .uk-text-right\@s {
    text-align: right !important;
  }

  .uk-text-center\@s {
    text-align: center !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-text-left\@m {
    text-align: left !important;
  }

  .uk-text-right\@m {
    text-align: right !important;
  }

  .uk-text-center\@m {
    text-align: center !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-text-left\@l {
    text-align: left !important;
  }

  .uk-text-right\@l {
    text-align: right !important;
  }

  .uk-text-center\@l {
    text-align: center !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-text-left\@xl {
    text-align: left !important;
  }

  .uk-text-right\@xl {
    text-align: right !important;
  }

  .uk-text-center\@xl {
    text-align: center !important;
  }
}
/*
 * Vertical
 */
.uk-text-top {
  vertical-align: top !important;
}

.uk-text-middle {
  vertical-align: middle !important;
}

.uk-text-bottom {
  vertical-align: bottom !important;
}

.uk-text-baseline {
  vertical-align: baseline !important;
}

/* Wrap modifiers
 ========================================================================== */
/*
 * Prevent text from wrapping onto multiple lines
 */
.uk-text-nowrap {
  white-space: nowrap;
}

/*
 * 1. Make sure a max-width is set after which truncation can occur
 * 2. Prevent text from wrapping onto multiple lines, and truncate with an ellipsis
 * 3. Fix for table cells
 */
.uk-text-truncate {
  /* 1 */
  max-width: 100%;
  /* 2 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 2 */
th.uk-text-truncate,
td.uk-text-truncate {
  max-width: 0;
}

/*
 * 1. Wrap long words onto the next line and break them if they are too long to fit
 * 2. Legacy `word-wrap` as fallback for `overflow-wrap`
 * 3. Fix `overflow-wrap` which doesn't work with table cells in Chrome, Opera, IE11 and Edge
 *    Must use `break-all` to support IE11 and Edge
 * Note: Not using `hyphens: auto;` because it hyphenates text even if not needed
 */
.uk-text-break {
  /* 1 */
  overflow-wrap: break-word;
  /* 2 */
  word-wrap: break-word;
}

/* 3 */
th.uk-text-break,
td.uk-text-break {
  word-break: break-all;
}

/* ========================================================================
   Component: Column
 ========================================================================== */
[class*=uk-column-] {
  column-gap: 30px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  [class*=uk-column-] {
    column-gap: 40px;
  }
}
/*
 * Fix image 1px line wrapping into the next column in Chrome
 */
[class*=uk-column-] img {
  transform: translate3d(0, 0, 0);
}

/* Divider
 ========================================================================== */
/*
 * 1. Double the column gap
 */
.uk-column-divider {
  column-rule: 1px solid #e5e5e5;
  /* 1 */
  column-gap: 60px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-column-divider {
    column-gap: 80px;
  }
}
/* Width modifiers
 ========================================================================== */
.uk-column-1-2 {
  column-count: 2;
}

.uk-column-1-3 {
  column-count: 3;
}

.uk-column-1-4 {
  column-count: 4;
}

.uk-column-1-5 {
  column-count: 5;
}

.uk-column-1-6 {
  column-count: 6;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-column-1-2\@s {
    column-count: 2;
  }

  .uk-column-1-3\@s {
    column-count: 3;
  }

  .uk-column-1-4\@s {
    column-count: 4;
  }

  .uk-column-1-5\@s {
    column-count: 5;
  }

  .uk-column-1-6\@s {
    column-count: 6;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-column-1-2\@m {
    column-count: 2;
  }

  .uk-column-1-3\@m {
    column-count: 3;
  }

  .uk-column-1-4\@m {
    column-count: 4;
  }

  .uk-column-1-5\@m {
    column-count: 5;
  }

  .uk-column-1-6\@m {
    column-count: 6;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-column-1-2\@l {
    column-count: 2;
  }

  .uk-column-1-3\@l {
    column-count: 3;
  }

  .uk-column-1-4\@l {
    column-count: 4;
  }

  .uk-column-1-5\@l {
    column-count: 5;
  }

  .uk-column-1-6\@l {
    column-count: 6;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-column-1-2\@xl {
    column-count: 2;
  }

  .uk-column-1-3\@xl {
    column-count: 3;
  }

  .uk-column-1-4\@xl {
    column-count: 4;
  }

  .uk-column-1-5\@xl {
    column-count: 5;
  }

  .uk-column-1-6\@xl {
    column-count: 6;
  }
}
/* Make element span across all columns
 * Does not work in Firefox yet
 ========================================================================== */
.uk-column-span {
  column-span: all;
}

/* ========================================================================
   Component: Cover
 ========================================================================== */
/*
 * Works with iframes and embedded content
 * 1. Use attribute to apply transform instantly. Needed if transform is transitioned.
 * 2. Reset responsiveness for embedded content
 * 3. Center object
 * Note: Percent values on the `top` property only works if this element
 *       is absolute positioned or if the container has a height
 */
/* 1 */
[uk-cover],
[data-uk-cover] {
  /* 2 */
  max-width: none;
  /* 3 */
  position: absolute;
  left: 50%;
  top: 50%;
  --uk-position-translate-x: -50%;
  --uk-position-translate-y: -50%;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y));
}

iframe[uk-cover],
iframe[data-uk-cover] {
  pointer-events: none;
}

/* Container
 ========================================================================== */
/*
 * 1. Parent container which clips resized object
 * 2. Needed if the child is positioned absolute. See note above
 */
.uk-cover-container {
  /* 1 */
  overflow: hidden;
  /* 2 */
  position: relative;
}

/* ========================================================================
   Component: Background
 ========================================================================== */
/* Color
 ========================================================================== */
.uk-background-default {
  background-color: #fff;
}

.uk-background-muted {
  background-color: #f8f8f8;
}

.uk-background-primary {
  background-color: #325685;
}

.uk-background-secondary {
  background-color: #92b3d7;
}

/* Size
 ========================================================================== */
.uk-background-cover,
.uk-background-contain,
.uk-background-width-1-1,
.uk-background-height-1-1 {
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.uk-background-cover {
  background-size: cover;
}

.uk-background-contain {
  background-size: contain;
}

.uk-background-width-1-1 {
  background-size: 100%;
}

.uk-background-height-1-1 {
  background-size: auto 100%;
}

/* Position
 ========================================================================== */
.uk-background-top-left {
  background-position: 0 0;
}

.uk-background-top-center {
  background-position: 50% 0;
}

.uk-background-top-right {
  background-position: 100% 0;
}

.uk-background-center-left {
  background-position: 0 50%;
}

.uk-background-center-center {
  background-position: 50% 50%;
}

.uk-background-center-right {
  background-position: 100% 50%;
}

.uk-background-bottom-left {
  background-position: 0 100%;
}

.uk-background-bottom-center {
  background-position: 50% 100%;
}

.uk-background-bottom-right {
  background-position: 100% 100%;
}

/* Repeat
 ========================================================================== */
.uk-background-norepeat {
  background-repeat: no-repeat;
}

/* Attachment
 ========================================================================== */
/*
 * 1. Fix bug introduced in Chrome 67: the background image is not visible if any element on the page uses `translate3d`
 */
.uk-background-fixed {
  background-attachment: fixed;
  /* 1 */
  backface-visibility: hidden;
}

/*
 * Exclude touch devices because `fixed` doesn't work on iOS and Android
 */
@media (pointer: coarse) {
  .uk-background-fixed {
    background-attachment: scroll;
  }
}
/* Image
 ========================================================================== */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-background-image\@s {
    background-image: none !important;
  }
}
/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-background-image\@m {
    background-image: none !important;
  }
}
/* Tablet landscape and smaller */
@media (max-width: 1199px) {
  .uk-background-image\@l {
    background-image: none !important;
  }
}
/* Desktop and smaller */
@media (max-width: 1599px) {
  .uk-background-image\@xl {
    background-image: none !important;
  }
}
/* Blend modes
 ========================================================================== */
.uk-background-blend-multiply {
  background-blend-mode: multiply;
}

.uk-background-blend-screen {
  background-blend-mode: screen;
}

.uk-background-blend-overlay {
  background-blend-mode: overlay;
}

.uk-background-blend-darken {
  background-blend-mode: darken;
}

.uk-background-blend-lighten {
  background-blend-mode: lighten;
}

.uk-background-blend-color-dodge {
  background-blend-mode: color-dodge;
}

.uk-background-blend-color-burn {
  background-blend-mode: color-burn;
}

.uk-background-blend-hard-light {
  background-blend-mode: hard-light;
}

.uk-background-blend-soft-light {
  background-blend-mode: soft-light;
}

.uk-background-blend-difference {
  background-blend-mode: difference;
}

.uk-background-blend-exclusion {
  background-blend-mode: exclusion;
}

.uk-background-blend-hue {
  background-blend-mode: hue;
}

.uk-background-blend-saturation {
  background-blend-mode: saturation;
}

.uk-background-blend-color {
  background-blend-mode: color;
}

.uk-background-blend-luminosity {
  background-blend-mode: luminosity;
}

/* ========================================================================
   Component: Align
 ========================================================================== */
/*
 * Default
 */
[class*=uk-align] {
  display: block;
  margin-bottom: 30px;
}

* + [class*=uk-align] {
  margin-top: 30px;
}

/*
 * Center
 */
.uk-align-center {
  margin-left: auto;
  margin-right: auto;
}

/*
 * Left/Right
 */
.uk-align-left {
  margin-top: 0;
  margin-right: 30px;
  float: left;
}

.uk-align-right {
  margin-top: 0;
  margin-left: 30px;
  float: right;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-align-left\@s {
    margin-top: 0;
    margin-right: 30px;
    float: left;
  }

  .uk-align-right\@s {
    margin-top: 0;
    margin-left: 30px;
    float: right;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-align-left\@m {
    margin-top: 0;
    margin-right: 30px;
    float: left;
  }

  .uk-align-right\@m {
    margin-top: 0;
    margin-left: 30px;
    float: right;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-align-left\@l {
    margin-top: 0;
    float: left;
  }

  .uk-align-right\@l {
    margin-top: 0;
    float: right;
  }

  .uk-align-left,
.uk-align-left\@s,
.uk-align-left\@m,
.uk-align-left\@l {
    margin-right: 40px;
  }

  .uk-align-right,
.uk-align-right\@s,
.uk-align-right\@m,
.uk-align-right\@l {
    margin-left: 40px;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-align-left\@xl {
    margin-top: 0;
    margin-right: 40px;
    float: left;
  }

  .uk-align-right\@xl {
    margin-top: 0;
    margin-left: 40px;
    float: right;
  }
}
/* ========================================================================
   Component: SVG
 ========================================================================== */
/*
 * 1. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 2. Set the fill and stroke color of all SVG elements to the current text color
 */
/* 1 */
.uk-svg,
.uk-svg:not(.uk-preserve) [fill*="#"]:not(.uk-preserve) {
  fill: currentcolor;
}

.uk-svg:not(.uk-preserve) [stroke*="#"]:not(.uk-preserve) {
  stroke: currentcolor;
}

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-svg {
  transform: translate(0, 0);
}

/* ========================================================================
   Component: Utility
 ========================================================================== */
/* Panel
 ========================================================================== */
.uk-panel {
  display: flow-root;
  position: relative;
  box-sizing: border-box;
}

/*
 * Remove margin from the last-child
 */
.uk-panel > :last-child {
  margin-bottom: 0;
}

/*
 * Scrollable
 */
.uk-panel-scrollable {
  height: 170px;
  padding: 10px;
  border: 1px solid #e5e5e5;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  resize: both;
}

/* Clearfix
 ========================================================================== */
/*
 * 1. `table-cell` is used with `::before` because `table` creates a 1px gap when it becomes a flex item, only in Webkit
 * 2. `table` is used again with `::after` because `clear` only works with block elements.
 * Note: `display: block` with `overflow: hidden` is currently not working in the latest Safari
 */
/* 1 */
.uk-clearfix::before {
  content: "";
  display: table-cell;
}

/* 2 */
.uk-clearfix::after {
  content: "";
  display: table;
  clear: both;
}

/* Float
 ========================================================================== */
/*
 * 1. Prevent content overflow
 */
.uk-float-left {
  float: left;
}

.uk-float-right {
  float: right;
}

/* 1 */
[class*=uk-float-] {
  max-width: 100%;
}

/* Overfow
 ========================================================================== */
.uk-overflow-hidden {
  overflow: hidden;
}

/*
 * Enable scrollbars if content is clipped
 * Note: Firefox ignores `padding-bottom` for the scrollable overflow https://bugzilla.mozilla.org/show_bug.cgi?id=748518
 */
.uk-overflow-auto {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.uk-overflow-auto > :last-child {
  margin-bottom: 0;
}

/* Resize
 ========================================================================== */
.uk-resize {
  resize: both;
}

.uk-resize-vertical {
  resize: vertical;
}

/* Display
 ========================================================================== */
.uk-display-block {
  display: block !important;
}

.uk-display-inline {
  display: inline !important;
}

.uk-display-inline-block {
  display: inline-block !important;
}

/* Inline
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Behave like most inline-block elements
 * 5. Force new layer without creating a new stacking context
 *    to fix 1px glitch when combined with overlays and transitions in Webkit
 * 6. Clip child elements
 */
[class*=uk-inline] {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
  /* 5 */
  -webkit-backface-visibility: hidden;
}

.uk-inline-clip {
  /* 6 */
  overflow: hidden;
}

/* Responsive objects
 ========================================================================== */
/*
 * Preserve original dimensions
 * Because `img, `video`, `canvas` and  `audio` are already responsive by default, see Base component
 */
.uk-preserve-width,
.uk-preserve-width canvas,
.uk-preserve-width img,
.uk-preserve-width svg,
.uk-preserve-width video {
  max-width: none;
}

/*
 * Responsiveness
 * Corrects `max-width` and `max-height` behavior if padding and border are used
 */
.uk-responsive-width,
.uk-responsive-height {
  box-sizing: border-box;
}

/*
 * 1. Set a maximum width. `important` needed to override `uk-preserve-width img`
 * 2. Auto scale the height. Only needed if `height` attribute is present
 */
.uk-responsive-width {
  /* 1 */
  max-width: 100% !important;
  /* 2 */
  height: auto;
}

/*
 * 1. Set a maximum height. Only works if the parent element has a fixed height
 * 2. Auto scale the width. Only needed if `width` attribute is present
 * 3. Reset max-width, which `img, `video`, `canvas` and  `audio` already have by default
 */
.uk-responsive-height {
  /* 1 */
  max-height: 100%;
  /* 2 */
  width: auto;
  /* 3 */
  max-width: none;
}

/*
 * Fix initial iframe width. Without the viewport is expanded on iOS devices
 */
[uk-responsive],
[data-uk-responsive] {
  max-width: 100%;
}

/* Border
 ========================================================================== */
.uk-border-circle {
  border-radius: 50%;
}

.uk-border-pill {
  border-radius: 500px;
}

.uk-border-rounded {
  border-radius: 5px;
}

/*
 * Fix `overflow: hidden` to be ignored with border-radius and CSS transforms in Webkit
 */
.uk-inline-clip[class*=uk-border-] {
  -webkit-transform: translateZ(0);
}

/* Box-shadow
 ========================================================================== */
.uk-box-shadow-small {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-medium {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-large {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

.uk-box-shadow-xlarge {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);
}

/*
 * Hover
 */
[class*=uk-box-shadow-hover] {
  transition: box-shadow 0.1s ease-in-out;
}

.uk-box-shadow-hover-small:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-hover-medium:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-hover-large:hover {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

.uk-box-shadow-hover-xlarge:hover {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);
}

/* Box-shadow bottom
 ========================================================================== */
/*
 * 1. Set position.
 * 2. Set style
 * 3. Fix shadow being clipped in Safari if container is animated
 */
@supports (filter: blur(0)) {
  .uk-box-shadow-bottom {
    display: inline-block;
    position: relative;
    z-index: 0;
    max-width: 100%;
    vertical-align: middle;
  }

  .uk-box-shadow-bottom::after {
    content: "";
    /* 1 */
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    z-index: -1;
    /* 2 */
    height: 30px;
    border-radius: 100%;
    background: #444;
    filter: blur(20px);
    /* 3 */
    will-change: filter;
  }
}
/* Drop cap
 ========================================================================== */
/*
 * 1. Firefox doesn't apply `::first-letter` if the first letter is inside child elements
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=214004
 * 2. In Firefox, a floating `::first-letter` doesn't have a line box and there for no `line-height`
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=317933
 * 3. Caused by 1.: Edge creates two nested `::first-letter` containers, one for each selector
 *    This doubles the `font-size` exponential when using the `em` unit.
 */
.uk-dropcap::first-letter,
.uk-dropcap > p:first-of-type::first-letter {
  display: block;
  margin-right: 10px;
  float: left;
  font-size: 4.5em;
  line-height: 1;
}

/* 2 */
@-moz-document url-prefix() {
  .uk-dropcap::first-letter,
.uk-dropcap > p:first-of-type::first-letter {
    margin-top: 1.1%;
  }
}
/* 3 */
@supports (-ms-ime-align: auto) {
  .uk-dropcap > p:first-of-type::first-letter {
    font-size: 1em;
  }
}
/* Logo
 ========================================================================== */
/*
 * 1. Required for `a`
 */
.uk-logo {
  font-size: 1.5rem;
  font-family: "canada-type-gibson", serif;
  color: #325685;
  /* 1 */
  text-decoration: none;
}

/* Hover */
.uk-logo:hover {
  color: #325685;
  /* 1 */
  text-decoration: none;
}

.uk-logo > :where(img, svg, video) {
  display: block;
}

.uk-logo-inverse {
  display: none;
}

/* Disabled State
 ========================================================================== */
.uk-disabled {
  pointer-events: none;
}

/* Drag State
 ========================================================================== */
/*
 * 1. Needed if moving over elements with have their own cursor on hover, e.g. links or buttons
 * 2. Fix dragging over iframes
 */
.uk-drag,
.uk-drag * {
  cursor: move;
}

/* 2 */
.uk-drag iframe {
  pointer-events: none;
}

/* Dragover State
 ========================================================================== */
/*
 * Create a box-shadow when dragging a file over the upload area
 */
.uk-dragover {
  box-shadow: 0 0 20px rgba(100, 100, 100, 0.3);
}

/* Blend modes
 ========================================================================== */
.uk-blend-multiply {
  mix-blend-mode: multiply;
}

.uk-blend-screen {
  mix-blend-mode: screen;
}

.uk-blend-overlay {
  mix-blend-mode: overlay;
}

.uk-blend-darken {
  mix-blend-mode: darken;
}

.uk-blend-lighten {
  mix-blend-mode: lighten;
}

.uk-blend-color-dodge {
  mix-blend-mode: color-dodge;
}

.uk-blend-color-burn {
  mix-blend-mode: color-burn;
}

.uk-blend-hard-light {
  mix-blend-mode: hard-light;
}

.uk-blend-soft-light {
  mix-blend-mode: soft-light;
}

.uk-blend-difference {
  mix-blend-mode: difference;
}

.uk-blend-exclusion {
  mix-blend-mode: exclusion;
}

.uk-blend-hue {
  mix-blend-mode: hue;
}

.uk-blend-saturation {
  mix-blend-mode: saturation;
}

.uk-blend-color {
  mix-blend-mode: color;
}

.uk-blend-luminosity {
  mix-blend-mode: luminosity;
}

/* Transform
========================================================================== */
.uk-transform-center {
  transform: translate(-50%, -50%);
}

/* Transform Origin
========================================================================== */
.uk-transform-origin-top-left {
  transform-origin: 0 0;
}

.uk-transform-origin-top-center {
  transform-origin: 50% 0;
}

.uk-transform-origin-top-right {
  transform-origin: 100% 0;
}

.uk-transform-origin-center-left {
  transform-origin: 0 50%;
}

.uk-transform-origin-center-right {
  transform-origin: 100% 50%;
}

.uk-transform-origin-bottom-left {
  transform-origin: 0 100%;
}

.uk-transform-origin-bottom-center {
  transform-origin: 50% 100%;
}

.uk-transform-origin-bottom-right {
  transform-origin: 100% 100%;
}

/* ========================================================================
   Component: Flex
 ========================================================================== */
.uk-flex {
  display: flex;
}

.uk-flex-inline {
  display: inline-flex;
}

/*
 * Remove pseudo elements created by micro clearfix as precaution
 */
.uk-flex::before,
.uk-flex::after,
.uk-flex-inline::before,
.uk-flex-inline::after {
  display: none;
}

/* Alignment
 ========================================================================== */
/*
 * Align items along the main axis of the current line of the flex container
 * Row: Horizontal
 */
.uk-flex-left {
  justify-content: flex-start;
}

.uk-flex-center {
  justify-content: center;
}

.uk-flex-right {
  justify-content: flex-end;
}

.uk-flex-between {
  justify-content: space-between;
}

.uk-flex-around {
  justify-content: space-around;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-left\@s {
    justify-content: flex-start;
  }

  .uk-flex-center\@s {
    justify-content: center;
  }

  .uk-flex-right\@s {
    justify-content: flex-end;
  }

  .uk-flex-between\@s {
    justify-content: space-between;
  }

  .uk-flex-around\@s {
    justify-content: space-around;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-left\@m {
    justify-content: flex-start;
  }

  .uk-flex-center\@m {
    justify-content: center;
  }

  .uk-flex-right\@m {
    justify-content: flex-end;
  }

  .uk-flex-between\@m {
    justify-content: space-between;
  }

  .uk-flex-around\@m {
    justify-content: space-around;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-left\@l {
    justify-content: flex-start;
  }

  .uk-flex-center\@l {
    justify-content: center;
  }

  .uk-flex-right\@l {
    justify-content: flex-end;
  }

  .uk-flex-between\@l {
    justify-content: space-between;
  }

  .uk-flex-around\@l {
    justify-content: space-around;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-left\@xl {
    justify-content: flex-start;
  }

  .uk-flex-center\@xl {
    justify-content: center;
  }

  .uk-flex-right\@xl {
    justify-content: flex-end;
  }

  .uk-flex-between\@xl {
    justify-content: space-between;
  }

  .uk-flex-around\@xl {
    justify-content: space-around;
  }
}
/*
 * Align items in the cross axis of the current line of the flex container
 * Row: Vertical
 */
.uk-flex-stretch {
  align-items: stretch;
}

.uk-flex-top {
  align-items: flex-start;
}

.uk-flex-middle {
  align-items: center;
}

.uk-flex-bottom {
  align-items: flex-end;
}

/* Direction
 ========================================================================== */
.uk-flex-row {
  flex-direction: row;
}

.uk-flex-row-reverse {
  flex-direction: row-reverse;
}

.uk-flex-column {
  flex-direction: column;
}

.uk-flex-column-reverse {
  flex-direction: column-reverse;
}

/* Wrap
 ========================================================================== */
.uk-flex-nowrap {
  flex-wrap: nowrap;
}

.uk-flex-wrap {
  flex-wrap: wrap;
}

.uk-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

/*
 * Aligns items within the flex container when there is extra space in the cross-axis
 * Only works if there is more than one line of flex items
 */
.uk-flex-wrap-stretch {
  align-content: stretch;
}

.uk-flex-wrap-top {
  align-content: flex-start;
}

.uk-flex-wrap-middle {
  align-content: center;
}

.uk-flex-wrap-bottom {
  align-content: flex-end;
}

.uk-flex-wrap-between {
  align-content: space-between;
}

.uk-flex-wrap-around {
  align-content: space-around;
}

/* Item ordering
 ========================================================================== */
/*
 * Default is 0
 */
.uk-flex-first {
  order: -1;
}

.uk-flex-last {
  order: 99;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-first\@s {
    order: -1;
  }

  .uk-flex-last\@s {
    order: 99;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-first\@m {
    order: -1;
  }

  .uk-flex-last\@m {
    order: 99;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-first\@l {
    order: -1;
  }

  .uk-flex-last\@l {
    order: 99;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-first\@xl {
    order: -1;
  }

  .uk-flex-last\@xl {
    order: 99;
  }
}
/* Item dimensions
 ========================================================================== */
/*
 * Initial: 0 1 auto
 * Content dimensions, but shrinks
 */
/*
 * No Flex: 0 0 auto
 * Content dimensions
 */
.uk-flex-none {
  flex: none;
}

/*
 * Relative Flex: 1 1 auto
 * Space is allocated considering content
 */
.uk-flex-auto {
  flex: auto;
}

/*
 * Absolute Flex: 1 1 0%
 * Space is allocated solely based on flex
 */
.uk-flex-1 {
  flex: 1;
}

/* ========================================================================
   Component: Margin
 ========================================================================== */
/*
 * Default
 */
.uk-margin {
  margin-bottom: 20px;
}

* + .uk-margin {
  margin-top: 20px !important;
}

.uk-margin-top {
  margin-top: 20px !important;
}

.uk-margin-bottom {
  margin-bottom: 20px !important;
}

.uk-margin-left {
  margin-left: 20px !important;
}

.uk-margin-right {
  margin-right: 20px !important;
}

/* Small
 ========================================================================== */
.uk-margin-small {
  margin-bottom: 10px;
}

* + .uk-margin-small {
  margin-top: 10px !important;
}

.uk-margin-small-top {
  margin-top: 10px !important;
}

.uk-margin-small-bottom {
  margin-bottom: 10px !important;
}

.uk-margin-small-left {
  margin-left: 10px !important;
}

.uk-margin-small-right {
  margin-right: 10px !important;
}

/* Medium
 ========================================================================== */
.uk-margin-medium {
  margin-bottom: 40px;
}

* + .uk-margin-medium {
  margin-top: 40px !important;
}

.uk-margin-medium-top {
  margin-top: 40px !important;
}

.uk-margin-medium-bottom {
  margin-bottom: 40px !important;
}

.uk-margin-medium-left {
  margin-left: 40px !important;
}

.uk-margin-medium-right {
  margin-right: 40px !important;
}

/* Large
 ========================================================================== */
.uk-margin-large {
  margin-bottom: 40px;
}

* + .uk-margin-large {
  margin-top: 40px !important;
}

.uk-margin-large-top {
  margin-top: 40px !important;
}

.uk-margin-large-bottom {
  margin-bottom: 40px !important;
}

.uk-margin-large-left {
  margin-left: 40px !important;
}

.uk-margin-large-right {
  margin-right: 40px !important;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-large {
    margin-bottom: 70px;
  }

  * + .uk-margin-large {
    margin-top: 70px !important;
  }

  .uk-margin-large-top {
    margin-top: 70px !important;
  }

  .uk-margin-large-bottom {
    margin-bottom: 70px !important;
  }

  .uk-margin-large-left {
    margin-left: 70px !important;
  }

  .uk-margin-large-right {
    margin-right: 70px !important;
  }
}
/* XLarge
 ========================================================================== */
.uk-margin-xlarge {
  margin-bottom: 70px;
}

* + .uk-margin-xlarge {
  margin-top: 70px !important;
}

.uk-margin-xlarge-top {
  margin-top: 70px !important;
}

.uk-margin-xlarge-bottom {
  margin-bottom: 70px !important;
}

.uk-margin-xlarge-left {
  margin-left: 70px !important;
}

.uk-margin-xlarge-right {
  margin-right: 70px !important;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-xlarge {
    margin-bottom: 140px;
  }

  * + .uk-margin-xlarge {
    margin-top: 140px !important;
  }

  .uk-margin-xlarge-top {
    margin-top: 140px !important;
  }

  .uk-margin-xlarge-bottom {
    margin-bottom: 140px !important;
  }

  .uk-margin-xlarge-left {
    margin-left: 140px !important;
  }

  .uk-margin-xlarge-right {
    margin-right: 140px !important;
  }
}
/* Auto
 ========================================================================== */
.uk-margin-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.uk-margin-auto-top {
  margin-top: auto !important;
}

.uk-margin-auto-bottom {
  margin-bottom: auto !important;
}

.uk-margin-auto-left {
  margin-left: auto !important;
}

.uk-margin-auto-right {
  margin-right: auto !important;
}

.uk-margin-auto-vertical {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-auto\@s {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-auto-left\@s {
    margin-left: auto !important;
  }

  .uk-margin-auto-right\@s {
    margin-right: auto !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-margin-auto\@m {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-auto-left\@m {
    margin-left: auto !important;
  }

  .uk-margin-auto-right\@m {
    margin-right: auto !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-auto\@l {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-auto-left\@l {
    margin-left: auto !important;
  }

  .uk-margin-auto-right\@l {
    margin-right: auto !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-margin-auto\@xl {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-auto-left\@xl {
    margin-left: auto !important;
  }

  .uk-margin-auto-right\@xl {
    margin-right: auto !important;
  }
}
/* Remove
 ========================================================================== */
.uk-margin-remove {
  margin: 0 !important;
}

.uk-margin-remove-top {
  margin-top: 0 !important;
}

.uk-margin-remove-bottom {
  margin-bottom: 0 !important;
}

.uk-margin-remove-left {
  margin-left: 0 !important;
}

.uk-margin-remove-right {
  margin-right: 0 !important;
}

.uk-margin-remove-vertical {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.uk-margin-remove-adjacent + *,
.uk-margin-remove-first-child > :first-child {
  margin-top: 0 !important;
}

.uk-margin-remove-last-child > :last-child {
  margin-bottom: 0 !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-remove-left\@s {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@s {
    margin-right: 0 !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-margin-remove-left\@m {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@m {
    margin-right: 0 !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-remove-left\@l {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@l {
    margin-right: 0 !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-margin-remove-left\@xl {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@xl {
    margin-right: 0 !important;
  }
}
/* ========================================================================
   Component: Padding
 ========================================================================== */
.uk-padding {
  padding: 30px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-padding {
    padding: 40px;
  }
}
/* Small
 ========================================================================== */
.uk-padding-small {
  padding: 15px;
}

/* Large
 ========================================================================== */
.uk-padding-large {
  padding: 30px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-padding-large {
    padding: 70px;
  }
}
/* Remove
 ========================================================================== */
.uk-padding-remove {
  padding: 0 !important;
}

.uk-padding-remove-top {
  padding-top: 0 !important;
}

.uk-padding-remove-bottom {
  padding-bottom: 0 !important;
}

.uk-padding-remove-left {
  padding-left: 0 !important;
}

.uk-padding-remove-right {
  padding-right: 0 !important;
}

.uk-padding-remove-vertical {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.uk-padding-remove-horizontal {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* ========================================================================
   Component: Position
 ========================================================================== */
:root {
  --uk-position-margin-offset: 0px;
}

/* Directions
 ========================================================================== */
/*
 * 1. Prevent content overflow.
 */
[class*=uk-position-top],
[class*=uk-position-bottom],
[class*=uk-position-left],
[class*=uk-position-right],
[class*=uk-position-center] {
  position: absolute !important;
  /* 1 */
  max-width: calc(100% - (var(--uk-position-margin-offset) * 2));
  box-sizing: border-box;
}

/*
 * Edges
 * Don't use `width: 100%` because it's wrong if the parent has padding.
 */
.uk-position-top {
  top: 0;
  left: 0;
  right: 0;
}

.uk-position-bottom {
  bottom: 0;
  left: 0;
  right: 0;
}

.uk-position-left {
  top: 0;
  bottom: 0;
  left: 0;
}

.uk-position-right {
  top: 0;
  bottom: 0;
  right: 0;
}

/*
 * Corners
 */
.uk-position-top-left {
  top: 0;
  left: 0;
}

.uk-position-top-right {
  top: 0;
  right: 0;
}

.uk-position-bottom-left {
  bottom: 0;
  left: 0;
}

.uk-position-bottom-right {
  bottom: 0;
  right: 0;
}

/*
 * Center
 * 1. Fix text wrapping if content is larger than 50% of the container.
 *    Using `max-content` requires `max-width` of 100% which is set generally.
 */
.uk-position-center {
  top: calc(50% - var(--uk-position-margin-offset));
  left: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-x: -50%;
  --uk-position-translate-y: -50%;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y));
  /* 1 */
  width: max-content;
}

/* Vertical */
[class*=uk-position-center-left],
[class*=uk-position-center-right] {
  top: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-y: -50%;
  transform: translate(0, var(--uk-position-translate-y));
}

.uk-position-center-left {
  left: 0;
}

.uk-position-center-right {
  right: 0;
}

.uk-position-center-left-out {
  right: 100%;
  width: max-content;
}

.uk-position-center-right-out {
  left: 100%;
  width: max-content;
}

/* Horizontal */
.uk-position-top-center,
.uk-position-bottom-center {
  left: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-x: -50%;
  transform: translate(var(--uk-position-translate-x), 0);
  /* 1 */
  width: max-content;
}

.uk-position-top-center {
  top: 0;
}

.uk-position-bottom-center {
  bottom: 0;
}

/*
 * Cover
 */
.uk-position-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Margin
 ========================================================================== */
.uk-position-small {
  margin: 15px;
  --uk-position-margin-offset: 15px;
}

.uk-position-medium {
  margin: 30px;
  --uk-position-margin-offset: 30px;
}

.uk-position-large {
  margin: 30px;
  --uk-position-margin-offset: 30px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-position-large {
    margin: 50px;
    --uk-position-margin-offset: 50px;
  }
}
/* Schemes
 ========================================================================== */
.uk-position-relative {
  position: relative !important;
}

.uk-position-absolute {
  position: absolute !important;
}

.uk-position-fixed {
  position: fixed !important;
}

.uk-position-sticky {
  position: sticky !important;
}

/* Layer
 ========================================================================== */
.uk-position-z-index {
  z-index: 1;
}

.uk-position-z-index-negative {
  z-index: -1;
}

/* ========================================================================
   Component: Transition
 ========================================================================== */
/* Transitions
 ========================================================================== */
/*
 * The toggle is triggered on touch devices by two methods:
 * 1. Using `:focus` and tabindex
 * 2. Using `:hover` and a `touchstart` event listener registered on the document
 *    (Doesn't work on Surface touch devices)
 */
:where(.uk-transition-fade),
:where([class*="uk-transition-scale"]),
:where([class*="uk-transition-slide"]) {
  --uk-position-translate-x: 0;
  --uk-position-translate-y: 0;
}

.uk-transition-fade,
[class*=uk-transition-scale],
[class*=uk-transition-slide] {
  --uk-translate-x: 0;
  --uk-translate-y: 0;
  --uk-scale-x: 1;
  --uk-scale-y: 1;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y)) translate(var(--uk-translate-x), var(--uk-translate-y)) scale(var(--uk-scale-x), var(--uk-scale-y));
  transition: 0.3s ease-out;
  transition-property: opacity, transform, filter;
  opacity: 0;
}

/*
 * Fade
 */
.uk-transition-toggle:hover .uk-transition-fade,
.uk-transition-toggle:focus .uk-transition-fade,
.uk-transition-toggle .uk-transition-fade:focus-within,
.uk-transition-active.uk-active .uk-transition-fade {
  opacity: 1;
}

/*
 * Scale
 * 1. Make image rendering the same during the transition as before and after. Prefixed because of Safari.
 */
/* 1 */
[class*=uk-transition-scale] {
  -webkit-backface-visibility: hidden;
}

.uk-transition-scale-up {
  --uk-scale-x: 1;
  --uk-scale-y: 1;
}

.uk-transition-scale-down {
  --uk-scale-x: 1.03;
  --uk-scale-y: 1.03;
}

/* Show */
.uk-transition-toggle:hover .uk-transition-scale-up,
.uk-transition-toggle:focus .uk-transition-scale-up,
.uk-transition-toggle .uk-transition-scale-up:focus-within,
.uk-transition-active.uk-active .uk-transition-scale-up {
  --uk-scale-x: 1.03;
  --uk-scale-y: 1.03;
  opacity: 1;
}

.uk-transition-toggle:hover .uk-transition-scale-down,
.uk-transition-toggle:focus .uk-transition-scale-down,
.uk-transition-toggle .uk-transition-scale-down:focus-within,
.uk-transition-active.uk-active .uk-transition-scale-down {
  --uk-scale-x: 1;
  --uk-scale-y: 1;
  opacity: 1;
}

/*
 * Slide
 */
.uk-transition-slide-top {
  --uk-translate-y: -100%;
}

.uk-transition-slide-bottom {
  --uk-translate-y: 100%;
}

.uk-transition-slide-left {
  --uk-translate-x: -100%;
}

.uk-transition-slide-right {
  --uk-translate-x: 100%;
}

.uk-transition-slide-top-small {
  --uk-translate-y: calc(-1 * 10px);
}

.uk-transition-slide-bottom-small {
  --uk-translate-y: 10px;
}

.uk-transition-slide-left-small {
  --uk-translate-x: calc(-1 * 10px);
}

.uk-transition-slide-right-small {
  --uk-translate-x: 10px;
}

.uk-transition-slide-top-medium {
  --uk-translate-y: calc(-1 * 50px);
}

.uk-transition-slide-bottom-medium {
  --uk-translate-y: 50px;
}

.uk-transition-slide-left-medium {
  --uk-translate-x: calc(-1 * 50px);
}

.uk-transition-slide-right-medium {
  --uk-translate-x: 50px;
}

/* Show */
.uk-transition-toggle:hover [class*=uk-transition-slide],
.uk-transition-toggle:focus [class*=uk-transition-slide],
.uk-transition-toggle [class*=uk-transition-slide]:focus-within,
.uk-transition-active.uk-active [class*=uk-transition-slide] {
  --uk-translate-x: 0;
  --uk-translate-y: 0;
  opacity: 1;
}

/* Opacity modifier
 ========================================================================== */
.uk-transition-opaque {
  opacity: 1;
}

/* Duration modifiers
 ========================================================================== */
.uk-transition-slow {
  transition-duration: 0.7s;
}

/* ========================================================================
   Component: Visibility
 ========================================================================== */
/*
 * Hidden
 * `hidden` attribute also set here to make it stronger
 */
[hidden],
.uk-hidden {
  display: none !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-hidden\@s {
    display: none !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-hidden\@m {
    display: none !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-hidden\@l {
    display: none !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-hidden\@xl {
    display: none !important;
  }
}
/*
 * Visible
 */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-visible\@s {
    display: none !important;
  }
}
/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-visible\@m {
    display: none !important;
  }
}
/* Tablet landscape and smaller */
@media (max-width: 1199px) {
  .uk-visible\@l {
    display: none !important;
  }
}
/* Desktop and smaller */
@media (max-width: 1599px) {
  .uk-visible\@xl {
    display: none !important;
  }
}
/* Visibility
 ========================================================================== */
.uk-invisible {
  visibility: hidden !important;
}

/* Based on the State of the Parent Element
 ========================================================================== */
/*
 * Can't use `display: none` nor `visibility: hidden` because both are not focusable.
 * The target stays visible if any element within receives focus through keyboard.
 */
/*
 * Discard space when hidden.
 */
.uk-visible-toggle:not(:hover):not(:focus) .uk-hidden-hover:not(:focus-within) {
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
}

/*
 * Keep space when hidden.
 */
.uk-visible-toggle:not(:hover):not(:focus) .uk-invisible-hover:not(:focus-within) {
  opacity: 0 !important;
}

/* Based on Hover Capability of the Pointing Device
 ========================================================================== */
/*
 * Hover
 */
/* Hide if primary pointing device doesn't support hover, e.g. touch screens. */
@media (hover: none) {
  .uk-hidden-touch {
    display: none !important;
  }
}
/* Hide if primary pointing device supports hover, e.g. mice. */
@media (hover) {
  .uk-hidden-notouch {
    display: none !important;
  }
}
/* ========================================================================
   Component: Inverse
 ========================================================================== */
/*
 * Implemented class depends on the general theme color
 * `uk-light` is for light colors on dark backgrounds
 * `uk-dark` is or dark colors on light backgrounds
 */
/* ========================================================================
   Component: Print
 ========================================================================== */
@media print {
  *,
*::before,
*::after {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
a:visited {
    text-decoration: underline;
  }

  pre,
blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }
}
/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * brandon-grotesque:
 *   - http://typekit.com/eulas/0000000000000000000132df
 *   - http://typekit.com/eulas/000000000000000077359df2
 * canada-type-gibson:
 *   - http://typekit.com/eulas/00000000000000007735a54a
 *
 * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2022-03-18 00:40:00 UTC"}*/
/* prettier-ignore */
@font-face {
  font-family: "brandon-grotesque";
  src: url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}
/* prettier-ignore */
@font-face {
  font-family: "brandon-grotesque";
  src: url("https://use.typekit.net/af/d03e48/000000000000000077359df2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/d03e48/000000000000000077359df2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/d03e48/000000000000000077359df2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}
/* prettier-ignore */
@font-face {
  font-family: "canada-type-gibson";
  src: url("https://use.typekit.net/af/be28cc/00000000000000007735a54a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/be28cc/00000000000000007735a54a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/be28cc/00000000000000007735a54a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}
.tk-brandon-grotesque {
  font-family: "brandon-grotesque", sans-serif;
}

.tk-canada-type-gibson {
  font-family: "canada-type-gibson", sans-serif;
}

:root {
  --body-font-family: "brandon-grotesque", serif;
  --heading-font-family: "canada-type-gibson", serif;
  --colour-black: #333333;
  --colour-blue: #325685;
  --colour-blue-dark: #203c5b;
  --colour-blue-light: #92b3d7;
  --colour-white: #ffffff;
  --colour-grey: #a1a3a5;
}

body {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4em;
}

.button,
input[type=submit] {
  font-family: "canada-type-gibson", serif;
  font-size: rem-calc(16px);
  font-weight: bold;
  padding: 1em 2em;
  text-align: center;
  text-transform: uppercase;
}
.button.button-grey,
input[type=submit].button-grey {
  background-color: grey;
  color: #ffffff;
}
.button.button-grey:hover,
input[type=submit].button-grey:hover {
  background: #9a9a9a;
  color: #ffffff;
}
.button.hollow,
input[type=submit].hollow {
  border-width: 1px;
}
.button.hollow:hover,
input[type=submit].hollow:hover {
  background: #325685;
  border-color: #325685;
  color: #ffffff;
}
.button.hollow.white,
input[type=submit].hollow.white {
  border-color: #ffffff;
  color: #ffffff;
}
.button.hollow.white:hover,
input[type=submit].hollow.white:hover {
  background: rgba(255, 255, 255, 0.4);
  border-color: #ffffff;
  color: #ffffff;
}

input[type=text],
input[type=password],
input[type=email],
input[type=tel],
input[type=url],
textarea,
form.pmpro_form .input,
form.pmpro_form textarea,
form.pmpro_form select {
  padding: 1em;
  width: 100%;
  max-width: none;
  line-height: 1em;
  margin: 0.5em 0;
  height: auto;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

#wpadminbar {
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
  background-color: grey;
}
#wpadminbar #wp-admin-bar-wp-logo .ab-item .ab-icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
#wpadminbar #wp-admin-bar-wp-logo .ab-item .ab-icon::before {
  content: none;
}
#wpadminbar.active {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

#wpadminbar > #wp-toolbar > #wp-admin-bar-root-default #wp-admin-bar-wp-logo .ab-icon {
  padding-top: 7px;
  width: 20px;
  margin-right: 0;
  background-image: url("https://cdn.wearecoast.com.au/coast-icon_colour.png") !important;
}

html,
p {
  font-family: var(--body-font-family);
}

b,
strong {
  font-weight: 500;
}

/* prettier-ignore */
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: var(--heading-font-family);
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
  font-family: var(--heading-font-family);
  text-decoration: none;
}

/*
h1,	.h1 {}
h2, .h2 {}
h3, .h3 {}
h4, .h4 {}
h5, .h5 {}
*/
a,
a:visited {
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
a:link,
a:visited:link {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
}

/************************************************
POSTS & CONTENT STYLES & DEFAULT TEMPLATE STYLES
************************************************/
#content .inner-content,
#content #inner-content {
  padding-top: 1em;
  padding-bottom: 1em;
}

.default_template {
  padding-bottom: 2rem;
}
.default_template .nothing {
  padding-bottom: 1rem;
}

.byline {
  color: grey;
}

.post-cat {
  margin-bottom: 5px;
  color: var(--colour-blue-light);
}

.entry-content img {
  max-width: 100%;
  height: auto;
}
.entry-content video,
.entry-content object {
  max-width: 100%;
  height: auto;
}
.entry-content pre {
  background: grey;
  border: 1px solid grey;
  padding: 10px;
}

.wp-caption {
  max-width: 100%;
  background: grey;
  padding: 5px;
}
.wp-caption img {
  max-width: 100%;
  margin-bottom: 0;
  width: 100%;
}
.wp-caption p.wp-caption-text {
  font-size: 0.85em;
  margin: 4px 0 7px;
  text-align: center;
}

.wp-block-image .alignleft,
.wp-block-image img.alignleft {
  text-align: left;
}
.wp-block-image .alignright,
.wp-block-image img.alignright {
  text-align: right;
}
.wp-block-image .aligncenter,
.wp-block-image img.aligncenter {
  text-align: center;
}
.wp-block-image figcaption {
  font-size: 12px;
  text-align: center;
}

.page-navigation {
  margin-top: 1rem;
}

.widget ul {
  margin: 0;
}
.widget ul li {
  list-style: none;
}

/*********************
SEARCH
*********************/
.search_results_page {
  margin-bottom: 40px;
}
.search_results_page .underline_heading {
  margin-bottom: 60px;
}
.search_results_page .search-form button.button {
  display: flex;
}
.search_results_page .search-form button.button i {
  margin-right: 0.75em;
}

.search_result {
  margin-top: 30px;
  padding-top: 18px;
  border-top: 1px solid #325685;
}
.search_result .callout {
  margin-top: 35px;
}
.search_result .search_result_item {
  padding: 30px 0 35px;
  border-bottom: 1px solid grey;
}
.search_result .search_result_item:last-child {
  border-bottom: 0;
}
.search_result .search_result_item h3 {
  margin-bottom: 16px;
  font-weight: 300;
  text-transform: uppercase;
  font-family: "canada-type-gibson", serif;
  letter-spacing: 2px;
}
.search_result .search_result_item .button {
  margin-top: 10px;
  margin-bottom: 0;
}

.menu_container {
  position: relative;
}

.js_searchbox {
  position: absolute;
  right: 0;
  top: 66px;
  width: 500px;
  display: none;
}
.js_searchbox .search_style {
  padding: 25px;
}
.js_searchbox .search_style i {
  display: none;
}
.js_searchbox .search_style .button {
  margin-bottom: 0;
}

.search-form {
  display: flex;
  padding: 1.5em;
  background-color: grey;
}
.search-form input {
  margin-bottom: 0;
  height: 100%;
  border-right: none;
  border-color: transparent;
  padding: 0.75em 1em;
}
.search-form input:focus, .search-form input:active {
  border-right: none;
  border-color: #325685;
}
.search-form button.button {
  margin-bottom: 0;
  color: #ffffff;
  padding: 0 1em;
}
.search-form button.button:hover {
  color: #ffffff;
}

.cs__image-wrapper {
  padding-top: 56.25%;
  overflow: hidden;
  position: relative;
  background-color: grey;
}
.cs__image-wrapper .cs__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gform_body ul {
  list-style: none outside none;
  margin: 0;
}

/*----------  Instagram Feed Styles  ----------*/
.instagram_feed {
  padding: 3rem 0;
  text-align: center;
}
.instagram_feed h2 {
  margin-bottom: 3rem;
}

.uk-button {
  border-radius: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  font-family: var(--heading-font-family);
  text-transform: uppercase;
}

.uk-button-primary:hover {
  background-color: var(--colour-blue-light);
}

.uk-card-title {
  font-size: 2.5rem;
}

.gform_wrapper.gravity-theme .gfield_label {
  font-weight: 500;
}

.gform_wrapper .button {
  padding: 0;
}

.weekly-event h2 {
  border-right: 1px solid var(--colour-blue-light);
}

.mt-0 {
  margin-top: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.pt-0 {
  padding-top: 0;
}

.pb-140 {
  padding-bottom: 140px;
}

.h-100 {
  height: 100%;
}

.h-small {
  height: 100px;
}

.h-med {
  height: 350px;
}

.h-large {
  height: 600px;
}

.min-h-small {
  min-height: 100px;
}

.z2 {
  z-index: 2;
  position: relative;
}

.z-2 {
  z-index: -2;
  position: relative;
}

.color-white {
  color: var(--colour-white);
}

.color-black {
  color: var(--colour-black);
}

.ab100 {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
}

.font-body {
  font-family: var(--body-font-family);
}

.font-header {
  font-family: var(--heading-font-family);
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-items-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.drop-shadow-lg {
  filter: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
}

.gallery div {
  display: inline-block;
}
.gallery img {
  border-radius: 20px 20px 0 0;
}
.gallery p {
  margin-top: 0;
  padding: 20px;
  padding-left: 20px;
  background-color: var(--colour-blue);
  color: var(--colour-white);
  border-radius: 0 0 20px 20px;
}

header.uk-sticky {
  box-shadow: 0 0 30px rgba(51, 51, 51, 0);
}
header.uk-sticky.uk-sticky-below {
  box-shadow: 0 0 30px rgba(51, 51, 51, 0.2);
}
header.uk-sticky.uk-sticky-below .uk-logo img,
header.uk-sticky.uk-sticky-below .uk-logo svg {
  height: 60px;
}
header.uk-sticky .uk-logo {
  padding: 1rem 0;
}
header.uk-sticky .uk-logo img,
header.uk-sticky .uk-logo svg {
  height: 80px;
  width: auto;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
header.uk-sticky.header__transparent + .uk-sticky-placeholder {
  display: none !important;
}

.uk-navbar-nav li a {
  font-family: var(--heading-font-family);
  color: var(--colour-black);
  font-size: 16px;
}
.uk-navbar-nav li a:hover {
  color: var(--colour-blue-light);
}

@media screen and (max-width: 960px) {
  .uk-navbar .uk-navbar-center {
    right: 0px;
    left: auto;
  }
}

.uk-navbar-dropdown {
  display: none;
  position: absolute;
  z-index: 1020;
  box-sizing: border-box;
  width: 280px;
  padding: 44px;
  background: #f8f8f8;
  color: #325685;
  border-radius: 20px;
  left: -30px !important;
}

.no-link {
  position: relative;
}

#menu-header-cta li a {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--colour-white);
  letter-spacing: 1px;
}
#menu-header-cta li.btn {
  border-radius: 20px;
}
#menu-header-cta li.btn:hover {
  opacity: 0.8;
}
#menu-header-cta li.btn.member {
  background-color: var(--colour-blue-dark);
  margin-right: 10px;
}
#menu-header-cta li.btn.tickets {
  background-color: var(--colour-blue-light);
}
#menu-header-cta li.btn.phone {
  background-color: var(--colour-grey);
  margin-left: 10px;
  padding: 10px;
  content: url(./images/phone-white.svg);
  width: 20px;
  height: 20px;
}

/*===============================================
=            OLD Header Layouts & Styles        =
===============================================*/
.sticky-container.transparent {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.sticky-container.transparent .nothing {
  position: relative;
  background-color: grey;
}

.header ul.off-canvas-list li {
  list-style: none;
}

.header_skeleton {
  position: relative;
  z-index: 99;
  background-color: grey;
}
.header_skeleton.sticky.is-anchored {
  bottom: auto !important;
}
.header_skeleton.is_admin_bar.sticky .nothing {
  margin-top: 0 !important;
}
.header_skeleton a.logo,
.header_skeleton span.logo {
  background: resolve("coast_logo.svg") no-repeat left center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 53px;
  max-width: 200px;
  width: 100%;
  display: block;
  text-indent: -9999px;
}
.header_skeleton a.logo svg,
.header_skeleton span.logo svg {
  height: auto;
  width: 100%;
}
.header_skeleton a.logo svg .st0,
.header_skeleton span.logo svg .st0 {
  transition: fill 1.1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.header_skeleton.default .cell-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.header_skeleton.default.transparent {
  background-color: transparent;
  transition: background-color 0.8s cubic-bezier(0, 0.19, 0.57, 1) 0s;
}
.header_skeleton.default.transparent.is-anchored {
  position: absolute;
}
.header_skeleton.default.transparent ul li a {
  color: #ffffff;
}
.header_skeleton.default.transparent ul li a:hover {
  color: #325685;
}
.header_skeleton.default.transparent.is-stuck {
  background-color: rgba(0, 0, 0, 0.8);
}
.header_skeleton.default.transparent.is-stuck ul li a {
  color: #ffffff;
}
.header_skeleton.default.transparent.is-stuck ul li a:hover {
  color: #325685;
}
.header_skeleton .menu_container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}
.header_skeleton ul.menu {
  background-color: transparent;
  margin-top: 0;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-content: stretch;
  align-items: stretch;
}
.header_skeleton ul.menu li {
  display: block;
  background-color: transparent;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.header_skeleton ul.menu li > a {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 14px;
  display: table;
  height: 100%;
  background-color: transparent;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.header_skeleton ul.menu li > a:hover {
  color: #325685;
}
.header_skeleton ul.menu li > a span {
  display: table-cell;
  vertical-align: middle;
}
.header_skeleton ul.menu li.is-dropdown-submenu-parent > a {
  padding-right: 1rem !important;
}
.header_skeleton ul.menu li.is-dropdown-submenu-parent > a::after {
  display: none !important;
}
.header_skeleton ul.menu li:last-child a {
  padding-right: 0;
}
.header_skeleton ul.menu li:hover ul {
  display: block;
}
.header_skeleton ul.menu li ul {
  height: auto;
  display: none;
  margin-top: 0;
  background: #325685;
  text-align: left;
  border: 0;
}
.header_skeleton ul.menu li ul li:hover {
  background: #16263b;
}
.header_skeleton ul.menu li ul li:hover a {
  color: #ffffff;
}
.header_skeleton ul.menu li ul li::before {
  content: "";
  display: none;
}
.header_skeleton ul.menu li ul li a {
  padding-top: 13px;
  padding-bottom: 13px;
}
.header_skeleton ul.menu li ul li:last-child {
  border: 0;
}
.header_skeleton .nothing ul.menu {
  margin-top: 21px;
}
.header_skeleton .nothing ul.menu li::before {
  margin: 0;
}
.header_skeleton .nothing ul.menu li a {
  padding: 0 5px;
}
.header_skeleton .nothing ul.menu li.is-dropdown-submenu-parent > a {
  padding-right: 0 !important;
}

.off-canvas-wrapper.is_admin_bar {
  margin-top: -32px;
}
.off-canvas-wrapper.is_admin_bar .nothing {
  margin-top: 0;
}

.off-canvas-list ul {
  margin-left: 0;
}
.off-canvas-list ul li a {
  border-bottom: 0px;
}
.off-canvas-list ul .dropdown {
  margin-left: 20px;
}

.off-canvas {
  background: transparent;
}

.js-off-canvas-exit {
  z-index: 999;
  background: rgba(51, 51, 51, 0.7);
}

/*=================================================
=            Custom Animated Menu Icon            =
=================================================*/
.menu-icon-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 1em;
}

.menu-icon {
  height: 14px;
  width: 25px;
  outline: none;
  z-index: 9999;
}
.menu-icon .nothing {
  display: none;
}
.menu-icon.hide_icon {
  opacity: 0;
  visibility: hidden;
}
.menu-icon.transparent::before {
  color: #ffffff !important;
}
.menu-icon.transparent .menu_line_con .menu_line {
  background-color: #ffffff !important;
}
.menu-icon::before {
  content: "Menu";
  display: block;
  float: left;
  color: #325685;
  margin-left: -65px;
  font-size: rem-calc(14px);
  transition: all 1.1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.menu-icon::before .nothing {
  display: none;
}
.menu-icon::after {
  display: none;
  height: 3px;
  box-shadow: 0 8px 0 #ffffff, 0 16px 0 #ffffff;
}
.menu-icon .menu_line_con {
  position: relative;
  height: 100%;
  display: block;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.menu-icon .menu_line_con .menu_line {
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #325685;
  display: block;
  left: 0;
  right: 0;
  transform: translateZ(0);
  transition-delay: 0s, 0s;
  transition-property: top, transform;
  transition: background-color 1.1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.menu-icon .menu_line_con .menu_line.one {
  top: 0;
  transition-duration: 0.3s, 0.2s;
}
.menu-icon .menu_line_con .menu_line.two {
  top: 6px;
}
.menu-icon .menu_line_con .menu_line.three {
  top: 12px;
  transition-duration: 0.2s, 0.3s;
}
.menu-icon:hover .menu_line_con .menu_line.one {
  top: -4px;
}
.menu-icon:hover .menu_line_con .menu_line.three {
  top: 16px;
}
.menu-icon.active, .menu-icon[aria-expanded=true] {
  width: 28px;
}
.menu-icon.active::before, .menu-icon[aria-expanded=true]::before {
  color: #ffffff;
  animation-name: overview_menu_trigger_txt;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.635, 0.005, 0.35, 1);
}
.menu-icon.active .menu_line_con .menu_line, .menu-icon[aria-expanded=true] .menu_line_con .menu_line {
  background-color: #ffffff;
}
.menu-icon.active .menu_line_con .menu_line.one, .menu-icon[aria-expanded=true] .menu_line_con .menu_line.one {
  transform: rotate(45deg);
  top: 6px;
}
.menu-icon.active .menu_line_con .menu_line.two, .menu-icon[aria-expanded=true] .menu_line_con .menu_line.two {
  opacity: 0;
}
.menu-icon.active .menu_line_con .menu_line.three, .menu-icon[aria-expanded=true] .menu_line_con .menu_line.three {
  top: 6px;
  transform: rotate(-45deg);
}
.menu-icon.active:hover .menu_line_con .menu_line, .menu-icon[aria-expanded=true]:hover .menu_line_con .menu_line {
  background-color: #92b3d7 !important;
}
.menu-icon.active:hover .menu_line_con .menu_line.one, .menu-icon[aria-expanded=true]:hover .menu_line_con .menu_line.one {
  transform: rotate(135deg);
}
.menu-icon.active:hover .menu_line_con .menu_line.three, .menu-icon[aria-expanded=true]:hover .menu_line_con .menu_line.three {
  transform: rotate(45deg);
}
.menu-icon.inactive::before, .menu-icon[aria-expanded=false]::before {
  animation-name: overview_menu_trigger_txt_reverse;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.635, 0.005, 0.35, 1);
}

/*====================================================
=            CUstom Overview Menu - James            =
====================================================*/
[data-js-overview-menu] {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 100%;
  overflow: auto;
  z-index: 9999;
}
[data-js-overview-menu] .overlay_slide {
  overflow: hidden;
}
[data-js-overview-menu] .menu_container_overview {
  opacity: 0;
  margin-top: -100px;
  transition-delay: 0.8s;
  transition-duration: 0.8s;
  padding-top: 25%;
}
[data-js-overview-menu] .menu_container_overview ul.menu {
  margin-top: 0;
  text-align: center;
}
[data-js-overview-menu] .menu_container_overview ul.menu li {
  width: 100%;
  display: block;
  text-align: center;
}
[data-js-overview-menu] .menu_container_overview ul.menu li a {
  text-align: center;
  color: #ffffff;
  background: none;
  font-size: rem-calc(22px);
  display: inline-block;
}
[data-js-overview-menu] .menu_container_overview ul.menu li a:hover {
  color: #325685;
}
[data-js-overview-menu] .menu_container_overview ul.menu li.menu-item-has-children > a {
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
[data-js-overview-menu] .menu_container_overview ul.menu li.menu-item-has-children > a::after {
  content: "+";
  border: none !important;
  display: block;
  margin-right: -20px;
  margin-left: 0.5rem;
  margin-top: 0;
  transform-origin: 50% 50%;
  transform: scaleY(1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
[data-js-overview-menu] .menu_container_overview ul.menu li.menu-item-has-children > a:hover {
  color: #92b3d7;
}
[data-js-overview-menu] .menu_container_overview ul.menu li.menu-item-has-children > a:hover::after {
  color: #92b3d7;
}
[data-js-overview-menu] .menu_container_overview ul.menu li.menu-item-has-children ul {
  opacity: 0.8;
  padding-bottom: 10px;
  margin-top: 5px;
}
[data-js-overview-menu] .menu_container_overview ul.menu li.menu-item-has-children ul li a {
  font-size: rem-calc(16px);
  text-transform: none;
}
[data-js-overview-menu] .menu_container_overview ul.menu li ul.menu {
  display: none;
}
[data-js-overview-menu] .menu-social-container {
  opacity: 0;
  transition-delay: 0.8s;
  transition-duration: 0.8s;
}
[data-js-overview-menu] .menu-social-container ul {
  margin-left: 0;
  margin-bottom: 0;
  justify-content: center;
}
[data-js-overview-menu] .menu-social-container ul li {
  display: inline-block !important;
}
[data-js-overview-menu] .menu-social-container ul li a {
  margin-left: 5px;
  margin-right: 5px;
}
[data-js-overview-menu] .menu-social-container ul li a::before {
  font-size: 20px;
  background-color: transparent;
}
[data-js-overview-menu].active {
  position: fixed;
  bottom: 0;
}
[data-js-overview-menu].active .overlay_slide {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  animation-name: overview_menu_animate;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.635, 0.005, 0.35, 1);
}
[data-js-overview-menu].active .menu_container_overview {
  opacity: 1;
  margin-top: 0;
}
[data-js-overview-menu].active .menu_container_overview li a {
  animation-name: overview_menu_item_animate;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.635, 0.005, 0.35, 1);
}
[data-js-overview-menu].active .menu-social-container {
  opacity: 1;
  margin-top: 50px;
}
[data-js-overview-menu].inactive {
  bottom: 0;
  animation-name: overview_menu_animate;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
  animation-direction: reverse;
  animation-timing-function: cubic-bezier(0.635, 0.005, 0.35, 1);
}

@keyframes overview_menu_animate {
  0% {
    background-color: rgba(255, 255, 255, 0);
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
    background-color: rgba(50, 86, 133, 0.95);
  }
}
@keyframes overview_menu_item_animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes overview_menu_trigger_txt {
  0% {
    content: "menu";
    opacity: 1;
  }
  50% {
    margin-left: -40%;
    opacity: 0;
  }
  51% {
    opacity: 0;
  }
  54% {
    opacity: 0;
    content: "Close";
  }
  55% {
    opacity: 0;
    content: "Close";
  }
  100% {
    content: "Close";
    opacity: 1;
  }
}
@keyframes overview_menu_trigger_txt_reverse {
  0% {
    content: "Close";
    opacity: 1;
  }
  50% {
    margin-left: -40%;
    opacity: 0;
  }
  51% {
    opacity: 0;
  }
  54% {
    opacity: 0;
    content: "Menu";
  }
  55% {
    opacity: 0;
    content: "Menu";
  }
  100% {
    content: "Menu";
    opacity: 1;
  }
}
/*********************
FOOTER STYLES
*********************/
.footer {
  position: relative;
  padding-top: 120px;
}
.footer .curved-lid {
  z-index: -1;
}
.footer .behind-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--colour-white);
  z-index: -2;
}
.footer .footer--top {
  position: relative;
}
.footer .footer--top .uk-grid {
  position: relative;
}
.footer .footer--top .foot-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (max-width: 960px) {
  .footer .footer--top .foot-flex {
    align-items: center;
  }
}
.footer .footer--top .footer-logo {
  height: 200px;
}
.footer .footer--top .footer-logo img {
  width: 190px;
}
@media screen and (max-width: 960px) {
  .footer .footer--top .footer-logo {
    height: 130px;
  }
}
.footer .footer--top a,
.footer .footer--top li,
.footer .footer--top footer.uk-light h5,
.footer .footer--top footer.uk-section-primary:not(.uk-preserve-color) h5,
.footer .footer--top footer.uk-section-secondary:not(.uk-preserve-color) h5,
.footer .footer--top footer.uk-tile-primary:not(.uk-preserve-color) h5,
.footer .footer--top footer.uk-tile-secondary:not(.uk-preserve-color) h5,
.footer .footer--top footer.uk-card-primary.uk-card-body h5,
.footer .footer--top .uk-card-primary > footer:not([class*=uk-card-media]) h5,
.footer .footer--top footer.uk-card-secondary.uk-card-body h5,
.footer .footer--top .uk-card-secondary > footer:not([class*=uk-card-media]) h5,
.footer .footer--top footer.uk-overlay-primary h5,
.footer .footer--top a.uk-icon {
  color: var(--colour-black);
  font-family: var(--body-font-family);
  font-size: 14px;
}
.footer .footer--top .uk-h5 {
  font-family: var(--header-font-family);
  color: var(--colour-black);
  font-weight: 500;
}
.footer .footer--top .menu-item {
  margin-top: 4px;
}
.footer .footer--top .socials {
  padding-left: 30px;
}
@media screen and (max-width: 960px) {
  .footer .footer--top .socials {
    padding: 0;
  }
}
.footer .footer--top .socials a.uk-icon {
  position: relative;
  z-index: 2;
  color: var(--colour-white);
  background-color: var(--colour-blue);
  border-radius: 25px;
  padding: 12px;
}
.footer .footer--top .socials a.uk-icon:hover {
  background-color: var(--colour-blue-light);
}
.footer .footer--top .socials.mobile {
  display: none;
}
.footer .footer--top .socials li {
  display: inline-block;
  padding: 0 10px;
}
@media screen and (max-width: var(--mobile)) {
  .footer .footer--top .socials.desktop {
    display: none;
  }
  .footer .footer--top .socials.mobile {
    display: inline-block;
    position: relative;
  }
}
@media screen and (max-width: 959px) {
  .footer .footer--top .footer-menus {
    text-align: center;
  }
}
.footer .footer--bottom {
  padding: 50px 0 1rem 0;
  font-family: var(--body-font-family);
}
.footer .footer--bottom::before {
  content: "";
  width: 100%;
  background-color: currentColor;
  height: 0.5px;
  display: block;
  margin-bottom: 15px;
}
.footer .footer--bottom .footer--acknowledgement {
  margin: 2rem 0;
}
.footer .footer--bottom .copyright {
  padding: 1rem 0;
}
@media screen and (max-width: 959px) {
  .footer .footer--bottom .copyright {
    text-align: center;
    padding: 0;
  }
}
@media screen and (max-width: 959px) {
  .footer .footer--bottom .white-papers {
    margin-top: 5px;
  }
}
.footer .footer--bottom .copy-links {
  display: inline-block;
}
.footer .footer--bottom .copy-links a {
  color: var(--colour-black);
}
.footer .footer--bottom .uk-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
}
@media screen and (max-width: 959px) {
  .footer .footer--bottom .uk-list {
    justify-content: center;
    margin-bottom: 1rem;
  }
}
.footer .footer--bottom .uk-list li {
  margin: 0;
}
.footer .footer--bottom .uk-list li::after {
  content: "|";
  margin: 0 4px;
}
.footer .footer--bottom .uk-list :last-child::after {
  content: unset;
}
.footer .footer--bottom .uk-list.uk-list-horizontal li {
  margin-top: 0;
}