.uk-button {
    border-radius: 20px;
    font-size: 12px;
    letter-spacing: 1px;
    font-family: var(--heading-font-family);
    text-transform: uppercase;
}
.uk-button-primary:hover {
    background-color: var(--colour-blue-light);
}
.uk-card-title {
    font-size: 2.5rem;
}

.gform_wrapper.gravity-theme .gfield_label {
    font-weight: 500;
}
.gform_wrapper .button {
    padding: 0;
}
.weekly-event h2 {
    border-right: 1px solid var(--colour-blue-light);
}
// Margin
.mt-0 {
    margin-top: 0;
}
.mt-10 {
    margin-top: 10px;
}
.mt-20 {
    margin-top: 20px;
}
.mb-0 {
    margin-bottom: 0;
}
.mb-5 {
    margin-bottom: 5px;
}
.mb-10 {
    margin-bottom: 10px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-50 {
    margin-bottom: 50px;
}
.mr-10 {
    margin-right: 10px;
}
.mr-20 {
    margin-right: 20px;
}
.pt-0 {
    padding-top: 0;
}
.pb-140 {
    padding-bottom: 140px;
}
// Heigh
.h-100 {
    height: 100%;
}
.h-small {
    height: 100px;
}
.h-med {
    height: 350px;
}
.h-large {
    height: 600px;
}
.min-h-small {
    min-height: 100px;
}

// Z Index
.z2 {
    z-index: 2;
    position: relative;
}
.z-2 {
    z-index: -2;
    position: relative;
}
// Color
.color-white {
    color: var(--colour-white);
}
.color-black {
    color: var(--colour-black);
}
// Position Absolute
.ab100 {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
}
// Fonts
.font-body {
    font-family: var(--body-font-family);
}
.font-header {
    font-family: var(--heading-font-family);
}
// Flex
.flex {
    display: flex;
}
.flex-col {
    flex-direction: column;
}
.justify-items-center {
    justify-content: center;
}
.align-items-center {
    align-items: center;
}

// Drop Shadow
.drop-shadow-lg {
    filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
        drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}
