@import "./src/js/front/theme/settings.scss";
//UI KIT HEADER

header.uk-sticky {
    box-shadow: 0 0 30px rgba($black, 0);

    &.uk-sticky-below {
        box-shadow: 0 0 30px rgba($black, 0.2);

        .uk-logo img,
        .uk-logo svg {
            height: 60px;
        }
    }

    .uk-logo {
        padding: 1rem 0;

        img,
        svg {
            height: 80px;
            width: auto;

            @include transition(all);
        }
    }

    &.header__transparent + .uk-sticky-placeholder {
        display: none !important;
    }
}

.uk-navbar-nav li a {
    font-family: var(--heading-font-family);
    color: var(--colour-black);
    font-size: 16px;
    &:hover {
        color: var(--colour-blue-light);
    }
}
.uk-navbar .uk-navbar-center {
    @media screen and (max-width: $breakpoint-medium) {
        right: 0px;
        left: auto;
    }
}
.uk-navbar-dropdown {
    display: none;
    position: absolute;
    z-index: 1020;
    box-sizing: border-box;
    width: 280px;
    padding: 44px;
    background: #f8f8f8;
    color: #325685;
    border-radius: 20px;
    left: -30px !important;
}
.no-link {
    position: relative;
}
#menu-header-cta li {
    a {
        font-size: 12px;
        text-transform: uppercase;
        color: var(--colour-white);
        letter-spacing: 1px;
    }

    &.btn {
        border-radius: 20px;
        &:hover {
            opacity: 0.8;
        }
        &.member {
            background-color: var(--colour-blue-dark);
            margin-right: 10px;
        }
        &.tickets {
            background-color: var(--colour-blue-light);
        }
        &.phone {
            background-color: var(--colour-grey);
            margin-left: 10px;
            padding: 10px;
            content: url(./images/phone-white.svg);
            width: 20px;
            height: 20px;
        }
    }
}
/*===============================================
=            OLD Header Layouts & Styles        =
===============================================*/

// Sticky Container Overides
.sticky-container {
    &.transparent {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;

        @include breakpoint(medium down) {
            position: relative;
            background-color: $coast-gray;
        }
    }
}

// General header Styles - Gobal
.header {
    ul.off-canvas-list li {
        list-style: none;
    }
}

.header_skeleton {
    position: relative;
    z-index: 99;
    //padding: #{map-get($grid-column-gutter, medium) / 2} 0;
    background-color: $coast-gray;

    &.sticky.is-anchored {
        bottom: auto !important;
    }

    // if Admin
    &.is_admin_bar.sticky {
        @include breakpoint(medium down) {
            margin-top: 0 !important;
        }
    }

    // General Logo Styling - Global
    a.logo,
    span.logo {
        background: resolve("coast_logo.svg") no-repeat left center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 53px;
        max-width: 200px;
        width: 100%;
        display: block;
        text-indent: -9999px;

        svg {
            height: auto;
            width: 100%;

            .st0 {
                @include transition(fill, 1.1s);
            }
        }
    }

    &.default {
        .cell-logo {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        &.transparent {
            background-color: transparent;

            @include transition(
                background-color,
                0.8s,
                cubic-bezier(0, 0.19, 0.57, 1)
            );

            &.is-anchored {
                position: absolute;
            }

            ul li a {
                color: $white;

                &:hover {
                    color: $primary-color;
                }
            }

            &.is-stuck {
                background-color: rgba(black, 0.8);

                ul li a {
                    color: $white;

                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }
    }

    // Main Navigation Styles -  Desktop Navigation
    // Also includes main nav drop downs
    .menu_container {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: flex-end;
    }

    ul.menu {
        background-color: transparent;
        margin-top: 0;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-content: stretch;
        align-items: stretch;

        li {
            display: block;
            background-color: transparent;

            @include transition(all);

            > a {
                text-transform: uppercase;
                color: $white;
                font-size: 14px;
                display: table;
                height: 100%;
                background-color: transparent;

                @include transition(all);

                &:hover {
                    color: $primary-color;
                }

                span {
                    display: table-cell;
                    vertical-align: middle;
                }
            }

            &.is-dropdown-submenu-parent > a {
                padding-right: 1rem !important;

                &::after {
                    display: none !important;
                }
            }

            &:last-child a {
                padding-right: 0;
            }

            &:hover {
                ul {
                    display: block;
                }
            }

            ul {
                height: auto;
                display: none;
                margin-top: 0;
                background: $primary-color;
                text-align: left;
                border: 0;

                li {
                    &:hover {
                        background: darken($primary-color, 20);

                        a {
                            color: $white;
                        }
                    }

                    &::before {
                        content: "";
                        display: none;
                    }

                    a {
                        padding-top: 13px;
                        padding-bottom: 13px;
                    }

                    &:last-child {
                        border: 0;
                    }
                }
            }
        }
    }

    .js_searchbox {
        //top: calc(100% + #{map-get($grid-column-gutter, medium) / 2});
    }

    // Mobile Menu Styling
    @include breakpoint(small down) {
        ul.menu {
            margin-top: 21px;

            li {
                &::before {
                    margin: 0;
                }

                a {
                    padding: 0 5px;
                }

                &.is-dropdown-submenu-parent > a {
                    padding-right: 0 !important;
                }
            }
        }
    }
}

// Off Canvas Menu For Foundations Styles
.off-canvas-wrapper {
    &.is_admin_bar {
        margin-top: -32px;

        @include breakpoint(medium down) {
            margin-top: 0;
        }
    }
}

.off-canvas-list {
    ul {
        margin-left: 0;

        li {
            a {
                border-bottom: 0px;
            }
        }

        .dropdown {
            margin-left: 20px;
        }
    }
}

.off-canvas {
    background: transparent;
}

.js-off-canvas-exit {
    z-index: 999;
    background: rgba($black, 0.7);
}

/*=================================================
=            Custom Animated Menu Icon            =
=================================================*/
.menu-icon-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-right: 1em;
}

.menu-icon {
    height: 14px;
    width: 25px;
    outline: none;
    z-index: 9999;

    @include breakpoint(large up) {
        display: none;
    }

    @include breakpoint(small only) {
        //margin-top: -13.5px;
    }

    @include breakpoint(medium only) {
        //margin-left: 90px;
    }

    &.hide_icon {
        opacity: 0;
        visibility: hidden;
    }

    // Coloring the menu icon & text for a transparent header style

    &.transparent {
        &::before {
            color: $white !important;
        }

        .menu_line_con {
            .menu_line {
                background-color: $white !important;
            }
        }
    }

    // Various Styling

    &::before {
        content: "Menu";
        display: block;
        float: left;
        color: $primary-color;
        margin-left: -65px;
        font-size: rem-calc(14px);

        @include transition(all, 1.1s);

        @include breakpoint(small down) {
            display: none;
        }
    }

    &::after {
        display: none;
        height: 3px;
        box-shadow: 0 8px 0 $white, 0 16px 0 $white;
    }

    .menu_line_con {
        position: relative;
        height: 100%;
        display: block;

        @include transition(all);

        // The Menu Lines Container and Lines - Hamburger

        .menu_line {
            position: absolute;
            height: 2px;
            width: 100%;
            background-color: $primary-color;
            display: block;
            left: 0;
            right: 0;
            transform: translateZ(0);
            transition-delay: 0s, 0s;
            transition-property: top, transform;

            @include transition(background-color, 1.1s);

            &.one {
                top: 0;
                transition-duration: 0.3s, 0.2s;
            }

            &.two {
                top: 6px;
            }

            &.three {
                top: 12px;
                transition-duration: 0.2s, 0.3s;
            }
        }
    }

    // Hover - Menu Lines & Container
    &:hover {
        .menu_line_con {
            .menu_line {
                &.one {
                    top: -4px;
                }

                &.three {
                    top: 16px;
                }
            }
        }
    }

    // Active - Menu Lines & Container
    &.active,
    &[aria-expanded="true"] {
        width: 28px;

        &::before {
            color: $white;
            animation-name: overview_menu_trigger_txt;
            animation-duration: 0.7s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.635, 0.005, 0.35, 1);
        }

        .menu_line_con {
            .menu_line {
                background-color: $white;

                &.one {
                    transform: rotate(45deg);
                    top: 6px;
                }

                &.two {
                    opacity: 0;
                }

                &.three {
                    top: 6px;
                    transform: rotate(-45deg);
                }
            }
        }

        &:hover {
            .menu_line_con {
                .menu_line {
                    background-color: $secondary-color !important;

                    &.one {
                        transform: rotate(135deg);
                    }

                    &.three {
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }

    // Returning to previous state without menu visible.

    &.inactive,
    &[aria-expanded="false"] {
        &::before {
            animation-name: overview_menu_trigger_txt_reverse;
            animation-duration: 0.7s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.635, 0.005, 0.35, 1);
        }
    }
}

/*====================================================
=            CUstom Overview Menu - James            =
====================================================*/

// The Data attribute used to style and fire the overview menu
[data-js-overview-menu] {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 100%;
    overflow: auto;
    z-index: 9999;

    .overlay_slide {
        overflow: hidden;
    }

    .menu_container_overview {
        opacity: 0;
        margin-top: -100px;
        transition-delay: 0.8s;
        transition-duration: 0.8s;
        padding-top: 25%;

        // The menu styles inside the overview slide
        ul.menu {
            margin-top: 0;
            text-align: center;

            li {
                width: 100%;
                display: block;
                text-align: center;

                a {
                    text-align: center;
                    color: $white;
                    background: none;
                    font-size: rem-calc(22px);
                    display: inline-block;

                    &:hover {
                        color: $primary-color;
                    }
                }

                &.menu-item-has-children {
                    > a {
                        margin-left: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &::after {
                            content: "+";
                            border: none !important;
                            display: block;
                            margin-right: -20px;
                            margin-left: 0.5rem;
                            margin-top: 0;
                            transform-origin: 50% 50%;
                            transform: scaleY(1);

                            @include transition(all);
                        }

                        &:hover {
                            color: $secondary-color;

                            &::after {
                                color: $secondary-color;
                            }
                        }
                    }

                    ul {
                        opacity: 0.8;
                        padding-bottom: 10px;
                        margin-top: 5px;

                        li {
                            a {
                                font-size: rem-calc(16px);
                                text-transform: none;
                            }
                        }
                    }
                }

                ul.menu {
                    display: none;
                }
            }
        }
    }

    // Social Icons inside menu
    .menu-social-container {
        opacity: 0;
        transition-delay: 0.8s;
        transition-duration: 0.8s;

        ul {
            margin-left: 0;
            margin-bottom: 0;
            justify-content: center;

            li {
                display: inline-block !important;

                a {
                    margin-left: 5px;
                    margin-right: 5px;

                    &::before {
                        font-size: 20px;
                        background-color: transparent;
                    }
                }
            }
        }
    }

    // When the overview is active and visible to the user

    &.active {
        position: fixed;
        bottom: 0;

        .overlay_slide {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            animation-name: overview_menu_animate;
            animation-duration: 0.7s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.635, 0.005, 0.35, 1);
        }

        .menu_container_overview {
            opacity: 1;
            margin-top: 0;

            li {
                a {
                    animation-name: overview_menu_item_animate;
                    animation-duration: 0.7s;
                    animation-fill-mode: forwards;
                    animation-timing-function: cubic-bezier(
                        0.635,
                        0.005,
                        0.35,
                        1
                    );
                }
            }
        }

        .menu-social-container {
            opacity: 1;
            margin-top: 50px;
        }
    }

    // Used to animate back to the original state of inactive

    &.inactive {
        bottom: 0;
        animation-name: overview_menu_animate;
        animation-duration: 0.7s;
        animation-fill-mode: forwards;
        animation-direction: reverse;
        animation-timing-function: cubic-bezier(0.635, 0.005, 0.35, 1);
    }
}

// Animation properties
@keyframes overview_menu_animate {
    0% {
        background-color: rgba($white, 0);
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0%);
        background-color: rgba($primary-color, 0.95);
    }
}

@keyframes overview_menu_item_animate {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes overview_menu_trigger_txt {
    0% {
        content: "menu";
        opacity: 1;
    }

    50% {
        margin-left: -40%;
        opacity: 0;
    }

    51% {
        opacity: 0;
    }

    54% {
        opacity: 0;
        content: "Close";
    }

    55% {
        opacity: 0;
        content: "Close";
    }

    100% {
        content: "Close";
        opacity: 1;
    }
}

@keyframes overview_menu_trigger_txt_reverse {
    0% {
        content: "Close";
        opacity: 1;
    }

    50% {
        margin-left: -40%;
        opacity: 0;
    }

    51% {
        opacity: 0;
    }

    54% {
        opacity: 0;
        content: "Menu";
    }

    55% {
        opacity: 0;
        content: "Menu";
    }

    100% {
        content: "Menu";
        opacity: 1;
    }
}
