//Stylesheet: Main Stylesheet
* {
    // border: 1px solid red;
}
// Variables
:root {
    --body-font-family: "brandon-grotesque", serif;
    --heading-font-family: "canada-type-gibson", serif;
    --colour-black: #333333;
    --colour-blue: #325685;
    --colour-blue-dark: #203c5b;
    --colour-blue-light: #92b3d7;
    --colour-white: #ffffff;
    --colour-grey: #a1a3a5;
}

body {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4em;
}

//Button Styling
.button,
input[type="submit"] {
    font-family: $body-font-family;
    font-size: rem-calc(16px);
    font-weight: $global-weight-bold;
    padding: 1em 2em;
    text-align: center;
    text-transform: uppercase;

    &.button-grey {
        background-color: $dark-gray;
        color: $white;

        &:hover {
            background: lighten($dark-gray, 10);
            color: $white;
        }
    }

    &.hollow {
        border-width: 1px;

        &:hover {
            background: $primary-color;
            border-color: $primary-color;
            color: $white;
        }
    }

    &.hollow.white {
        border-color: $white;
        color: $white;

        &:hover {
            background: rgba($white, 0.4);
            border-color: $white;
            color: $white;
        }
    }
}

//Input Styling
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
textarea,
form.pmpro_form .input,
form.pmpro_form textarea,
form.pmpro_form select {
    padding: 1em;
    width: 100%;
    max-width: none;
    line-height: 1em;
    margin: 0.5em 0;
    height: auto;

    @include transition(all);
}

#wpadminbar {
    @include transition(all, 0.4s);
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    background-color: $coast-gray;

    #wp-admin-bar-wp-logo .ab-item {
        .ab-icon {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;

            &::before {
                content: none;
            }
        }
    }

    &.active {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
}

#wpadminbar
    > #wp-toolbar
    > #wp-admin-bar-root-default
    #wp-admin-bar-wp-logo
    .ab-icon {
    padding-top: 7px;
    width: 20px;
    margin-right: 0;
    background-image: url("https://cdn.wearecoast.com.au/coast-icon_colour.png") !important;
}

// Fonts
html,
p {
    font-family: var(--body-font-family);
}

b,
strong {
    font-weight: 500;
}

/* prettier-ignore */
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
	font-family: var(--heading-font-family);
	a { 
		font-family: var(--heading-font-family); 
		text-decoration: none; // Removing text decoration from all headline links
	}
  }

/*
h1,	.h1 {}
h2, .h2 {}
h3, .h3 {}
h4, .h4 {}
h5, .h5 {}
*/

//Anchor Styles
a,
a:visited {
    @include transition(all);

    // Mobile tap color
    &:link {
        //Highlight links on iOS, Similar to :hover for mobile devices.
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
    }
}

/************************************************
POSTS & CONTENT STYLES & DEFAULT TEMPLATE STYLES
************************************************/

#content {
    .inner-content,
    #inner-content {
        padding-top: 1em;
        padding-bottom: 1em;
    }
}

.default_template {
    padding-bottom: 2rem;

    @include breakpoint(small only) {
        padding-bottom: 1rem;
    }
}

// Post meta
.byline {
    color: $dark-gray;
}
.post-cat {
    margin-bottom: 5px;
    color: var(--colour-blue-light);
}

// Entry content
.entry-content {
    img {
        max-width: 100%;
        height: auto;
    }

    video,
    object {
        max-width: 100%;
        height: auto;
    }

    pre {
        background: $light-gray;
        border: 1px solid $light-gray;
        padding: 10px;
    }
}

.wp-caption {
    max-width: 100%;
    background: $light-gray;
    padding: 5px;

    // Images inside wp-caption
    img {
        max-width: 100%;
        margin-bottom: 0;
        width: 100%;
    }

    p.wp-caption-text {
        font-size: 0.85em;
        margin: 4px 0 7px;
        text-align: center;
    }
}

.wp-block-image {
    .alignleft,
    img.alignleft {
        text-align: left;
    }

    .alignright,
    img.alignright {
        text-align: right;
    }

    .aligncenter,
    img.aligncenter {
        text-align: center;
    }

    figcaption {
        font-size: 12px;
        text-align: center;
    }
}

//Page Navigation
.page-navigation {
    margin-top: 1rem;
}

//Sidebars
.widget {
    ul {
        margin: 0;

        li {
            list-style: none;
        }
    }
}

/*********************
SEARCH
*********************/

.search_results_page {
    margin-bottom: 40px;

    .underline_heading {
        margin-bottom: 60px;
    }

    .search-form {
        button.button {
            display: flex;

            i {
                margin-right: 0.75em;
            }
        }
    }
}

.search_result {
    margin-top: 30px;
    padding-top: 18px;
    border-top: 1px solid $primary-color;

    .callout {
        margin-top: 35px;
    }

    .search_result_item {
        padding: 30px 0 35px;
        border-bottom: 1px solid $light-gray;

        &:last-child {
            border-bottom: 0;
        }

        h3 {
            margin-bottom: 16px;
            font-weight: 300;
            text-transform: uppercase;
            font-family: $header-font-family;
            letter-spacing: 2px;
        }

        .button {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
}

// Search JS Box from menu
.menu_container {
    position: relative;
}

.js_searchbox {
    position: absolute;
    right: 0;
    top: 66px;
    width: 500px;
    display: none;

    .search_style {
        padding: 25px;

        i {
            display: none;
        }

        .button {
            margin-bottom: 0;
        }
    }
}

.search-form {
    display: flex;
    padding: 1.5em;
    background-color: $light-gray;

    input {
        margin-bottom: 0;
        height: 100%;
        border-right: none;
        border-color: transparent;
        padding: 0.75em 1em;

        &:focus,
        &:active {
            border-right: none;
            border-color: $primary-color;
        }
    }

    button.button {
        margin-bottom: 0;
        color: $white;
        padding: 0 1em;

        &:hover {
            color: $white;
        }
    }
}

.cs__image-wrapper {
    padding-top: 56.25%;
    overflow: hidden;
    position: relative;
    background-color: $light-gray;

    .cs__image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}

//===============\\
// PLUGIN STYLES \\
//===============\\

// Makes Gravity Forms play nice with Foundation Form styles
.gform_body ul {
    list-style: none outside none;
    margin: 0;
}

/*----------  Instagram Feed Styles  ----------*/

.instagram_feed {
    padding: 3rem 0;
    text-align: center;

    h2 {
        margin-bottom: 3rem;
    }
}
