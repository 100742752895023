.gallery {
    div {
        display: inline-block;
    }
    img {
        border-radius: 20px 20px 0 0;
    }
    p {
        margin-top: 0;
        padding: 20px;
        padding-left: 20px;
        background-color: var(--colour-blue);
        color: var(--colour-white);
        border-radius: 0 0 20px 20px;
    }
}
