@import "./src/js/front/theme/settings.scss";
@import "~swiper/swiper.scss";

.banner_container {
    // height: 100vh;
    margin-bottom: 2rem;

    //@include transition(all);

    // @include breakpoint(small only) {
    // 	padding-left: 0;
    // 	padding-right: 0;
    // 	margin-bottom: 1rem;
    // }

    .banner-item {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        // @include breakpoint(small only) {
        // 	padding: 1rem;
        // }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 0;
            background: linear-gradient(
                -180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.7) 100%
            );
        }

        &.banner-item-colour,
        &.banner-item-color {
            background-color: $primary-color;

            h1,
            p {
                color: $white;
            }

            &::before {
                content: initial;
            }
        }

        &.banner-item-image {
            .banner-image-wrapper {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;

                .banner-image {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    z-index: -1;
                }
            }
        }

        &.banner-item-video {
            background-color: $light-gray;
            position: relative;
            overflow: hidden;

            .banner-video-wrapper {
                background: $primary-color;

                video {
                    position: absolute;
                    z-index: 0;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    min-width: 100%;
                    min-height: 100%;
                    width: auto;
                    height: auto;
                    max-width: none;
                }
            }
        }

        .banner-inner-content {
            position: relative;
            z-index: 1;
            width: 100%;
            padding: 2rem;

            &.text-center {
                text-align: center;
            }

            &.text-left {
                text-align: left;

                h1::after {
                    left: 0;
                }
            }

            &.text-right {
                text-align: right;

                h1::after {
                    left: initial;
                    right: 0;
                }
            }

            h1 {
                position: relative;
                margin-bottom: 1.5rem;

                // @include breakpoint(small only) {
                // 	margin-bottom: 1rem;
                // 	padding-bottom: .5rem;
                // }
            }

            p.lead {
                max-width: 60%;
                margin: 0 auto;

                // @include breakpoint(small only) {
                // 	font-size: 12px;
                // 	max-width: 100%;
                // }
            }

            a.button {
                margin-top: 2rem;
            }

            :last-child {
                margin-bottom: 0;
            }
        }
    }

    .banner-slider {
        position: relative;
        height: 100%;

        opacity: 0;
        visibility: hidden;

        @include transition(all);

        &.swiper-container-initialized {
            opacity: 1;
            visibility: visible;
        }

        &.swiper-container-initialized .slick-slide {
            display: flex;
        }

        .swiper-slide {
            display: flex;
            flex-direction: column;
        }

        .slick-arrow {
            position: absolute;
            color: $white;
            z-index: 2;
            cursor: pointer;
            height: 30px;
            line-height: 30px;
            font-size: 32px;
            top: calc(50% - 15px);

            @include transition(all);

            // @include breakpoint(small only) {
            // 	display: none;
            // }

            &.slick-arrow-prev {
                left: 2rem;

                &::before {
                    transform: rotate(180deg);
                }

                // @include breakpoint(small only) {
                // 	left: 0;
                // }
            }

            &.slick-arrow-next {
                right: 2rem;

                // @include breakpoint(small only) {
                // 	right: 0;
                // }
            }

            &:hover {
                opacity: 0.75;
            }
        }

        .slick-slide,
        .slick-list,
        .slick-track,
        .slick-track > div {
            height: 100%;
        }

        ul.slick-dots {
            position: absolute;
            list-style: none;
            margin: 0;
            bottom: 0.5rem;
            left: 0;
            right: 0;
            text-align: center;

            li {
                display: inline-block;
                margin: 0 0.25rem;
                cursor: pointer;

                @include transition(all);

                &::before {
                    content: "";
                    display: block;
                    width: 10px;
                    height: 10px;
                    background: $white;
                    border-radius: 50px;

                    @include transition(all);
                }

                button {
                    display: none;
                }

                &:hover {
                    &::before {
                        background: rgba($primary-color, 0.75);
                    }
                }

                &.slick-active {
                    &::before {
                        background: $primary-color;
                    }
                }
            }
        }
    }
}
